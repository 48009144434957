<style lang="less" scoped>
@import "../../styles/variable.less";
.form {
  padding: 60px 30px;
  /deep/ .ant-card {
    &-body {
      padding: 0;
    }
  }
  &-logo {
    text-align: center;
    margin-bottom: 40px;
  }
  &-title {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 60px;
  }
  &-item {
    &:nth-child(n + 2) {
      margin-top: 20px;
    }
  }
  &-label {
    color: @clear-black06;
    font-size: 12px;
    margin-bottom: 10px;
  }
  &-data {
    min-width: 280px;
  }
  &-action {
    text-align: center;
    margin-top: 60px;
    .ant-btn {
      padding: 10px 20px;
    }
  }
  &-extra {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
<template>
  <a-card class="form" :bordered="false">
    <div class="form-logo">
      <Logo style="width: 100px" />
    </div>
    <!-- <h1 class="form-title">パスワード設定</h1> -->
    <a-form
      layout="horizontal"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      @submit.prevent.stop="handleSubmit"
    >
      <a-alert
        type="error"
        :message="errorMessage"
        banner
        v-if="errorMessage"
      />
      <div class="form-item">
        <div class="form-label">{{ $ta("email") }}</div>
        <div class="form-data">
          <a-input
            placeholder="入力してください"
            v-model:value="input.email"
            :required="true"
          />
        </div>
      </div>

      <div class="form-action">
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          :loading="loading"
          >パスワード変更用コードを送る</a-button
        >
      </div>
      <div class="form-extra">
        <router-link to="/login">戻る</router-link>
      </div>
    </a-form>
  </a-card>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import Logo from "@/shared/components/Logo.vue";
import {
  CreatePasswordInput,
  useCreatePassword,
} from "@/client/modules/password";

export default defineComponent({
  components: {
    Logo,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      input: {
        email: null,
      } as CreatePasswordInput,
      errorMessage: null as string | null,
    });

    const { create, loading } = useCreatePassword();

    const handleSubmit = async () => {
      await create(state.input);
      router.push({ name: "PasswordUpdate" });
    };

    return {
      ...toRefs(state),
      loading,
      handleSubmit,
    };
  },
});
</script>
