<template>
  <span role="img" class="anticon">
    <svg
      width="1em"
      height=".9em"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.5 1C3.67157 1 3 1.67157 3 2.5C3 3.32843 3.67157 4 4.5 4C5.32843 4 6 3.32843 6 2.5C6 1.67157 5.32843 1 4.5 1ZM2 3C2.01671 3 2.03323 2.99918 2.04952 2.99758C2.28022 4.1399 3.28967 5 4.5 5C5.71033 5 6.71978 4.1399 6.95048 2.99758C6.96677 2.99918 6.98329 3 7 3H12.5C12.7761 3 13 2.77614 13 2.5C13 2.22386 12.7761 2 12.5 2H7C6.98329 2 6.96677 2.00082 6.95048 2.00242C6.71978 0.86009 5.71033 0 4.5 0C3.28967 0 2.28022 0.86009 2.04952 2.00242C2.03323 2.00082 2.01671 2 2 2H0.5C0.22386 2 0 2.22386 0 2.5C0 2.77614 0.22386 3 0.5 3H2ZM10.9505 8.9976C10.7198 10.1399 9.7103 11 8.5 11C7.28967 11 6.28022 10.1399 6.04952 8.9976C6.03323 8.9992 6.01671 9 6 9H0.5C0.22386 9 0 8.7761 0 8.5C0 8.2239 0.22386 8 0.5 8H6C6.01671 8 6.03323 8.0008 6.04952 8.0024C6.28022 6.8601 7.28967 6 8.5 6C9.7103 6 10.7198 6.8601 10.9505 8.0024C10.9668 8.0008 10.9833 8 11 8H12.5C12.7761 8 13 8.2239 13 8.5C13 8.7761 12.7761 9 12.5 9H11C10.9833 9 10.9668 8.9992 10.9505 8.9976ZM7 8.5C7 7.67157 7.67157 7 8.5 7C9.3284 7 10 7.67157 10 8.5C10 9.3284 9.3284 10 8.5 10C7.67157 10 7 9.3284 7 8.5Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>
