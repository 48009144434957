
import { defineComponent } from "vue";
import { Content } from "@/generic";
import UserName from "@/shared/components/CurrentUserName.vue";
import ProductCategorySelect from "@/client/components/productCategory/Select.vue";
import ClientUserList from "@/client/components/settings/clientUser/List.vue";

export default defineComponent({
  components: {
    Content,
    ClientUserList,
    UserName,
    ProductCategorySelect,
  },
});
