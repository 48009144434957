
import { defineComponent, reactive, ref, toRefs, unref } from "vue";
import { parseError, Currency, Number } from "@/generic";
// import AddressDisplay from "@/shared/components/AddressDisplay.vue";
import {
  useGetOneOrderQuery,
  useUpdateOneOrderMutation,
} from "@/client/modules/api";
import { useRouter } from "vue-router";
import { useResult } from "@vue/apollo-composable";
import { GetOneOrderQuery, Order } from "@/client/schema";
import { LeftOutlined } from "@ant-design/icons-vue";
import UploadButton from "./UploadButton.vue";
import TypesettingButton from "./TypesettingButton.vue";
import OuterCartonTypesettingButton from "./OuterCartonTypesettingButton.vue";
import InnerCartonParamPreviewButton from "./InnerCartonParamPreviewButton.vue";
import PriceLabelParamPreviewButton from "./PriceLabelParamPreviewButton.vue";
import ImagePreview from "@/shared/components/ImagePreview.vue";
import moment from "moment";
import "moment/locale/ja";
moment.locale("ja");

import { message } from "ant-design-vue";
import { getAccessToken } from "@/shared/providors/session";

export type Props = {
  orderId: string;
};

export default defineComponent({
  components: {
    Currency,
    Number,
    // AddressDisplay,
    LeftOutlined,
    TypesettingButton,
    OuterCartonTypesettingButton,
    InnerCartonParamPreviewButton,
    PriceLabelParamPreviewButton,
    UploadButton,
    ImagePreview,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const router = useRouter();

    const { loading, result, refetch } = useGetOneOrderQuery(
      {
        id: props.orderId,
      },
      {
        fetchPolicy: "network-only",
      }
    );

    const state = reactive({
      errorMessages: [] as string[],
      alertDescription: "" as string,
    });

    const order = useResult<GetOneOrderQuery, "order">(result);

    const { mutate: updateOneOrder, error } = useUpdateOneOrderMutation({});

    const handleOrder = async () => {
      try {
        const orderSafe = unref(order) as Order;
        await updateOneOrder({
          input: {
            id: orderSafe.id,
            draft: false,
          },
        });

        router.push({
          name: "OrderDetail",
          params: {
            id: orderSafe.id,
          },
        });
      } catch (e) {
        if (error.value == null) throw e;
        state.errorMessages = parseError(error.value);
      }
    };

    const handleChange = async () => {
      await refetch();
    };

    const alertVisible = ref(true);
    const handleClose = () => {
      alertVisible.value = false;
    };

    const orderble = (order: Order) => {
      if (order.orderDeliveries?.reduce((sum, orderDelivery) => sum + orderDelivery.quantity, 0) != order.orderProduct.quantity) {
        state.alertDescription = "配送先の数量の合計と商品数量が異なっています。正しい数量を設定するまで、発注することができません。";
        return false;
      }

      switch (order.orderProduct.productType) {
        case "typesetting_tag":
        case "outer_carton_label":
          if (!order.uploaded) {
            state.alertDescription = "このご注文は、組版商品のため、組版を実行しPDFを作成するまで、発注することができません。";
            return false;
          } else return true;
        case "inner_carton_label": {
          const innerCartonParam = order.orderProduct.innerCartonParam;
          if (
            innerCartonParam == null ||
            innerCartonParam.janCode == null ||
            innerCartonParam.productName == null ||
            innerCartonParam.quantityPerInnerCartonText == null ||
            innerCartonParam.taxIncludedPrice == null
          ) {
            state.alertDescription = "このご注文は、情報入力商品のため、ラベル情報をすべて入力するまで、発注することができません。";
            return false;
          } else return true;
        }
        case "price_label": {
          const priceLabelParam = order.orderProduct.priceLabelParam;
          if (
            priceLabelParam == null ||
            priceLabelParam.janCode == null ||
            priceLabelParam.taxIncludedPrice == null
          ) {
            state.alertDescription = "このご注文は、情報入力商品のため、ラベル情報をすべて入力するまで、発注することができません。";
            return false;
          } else return true;
        }
        default:
          return true;
      }
    };

    const orderbleParent = () => {
      const orderSafe = unref(order) as Order;
      if (!orderSafe.children.every(orderble)) {
        state.alertDescription = "このご注文は、子注文が発注できない状態のため、発注することができません。";
        return false;
      } else return true;
    };

    const handleExportQuotation = async () => {
      try {
        const exportEndpoint = `/api/app/reports/quotation/${props.orderId}.pdf`;
        const accessToken = getAccessToken("client");
        await fetch(exportEndpoint, {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const anchor = document.createElement("a");
            anchor.href = window.URL.createObjectURL(blob);
            const orderSafe = unref(order) as Order;
            const orderName = orderSafe?.name;
            const date = new Date();
            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            const hour = ("0" + date.getHours()).slice(-2);
            const minute = ("0" + date.getMinutes()).slice(-2);
            const dateTime = `${year}${month}${day}_${hour}${minute}`;
            const fileName = `見積書_${orderName}_${dateTime}.pdf`;
            anchor.download = fileName;
            anchor.click();
          });
        message.success("ダウンロードしました。");
      } catch (err) {
        console.log("err", err);
        message.error("エラーが発生し、ダウンロードできませんでした。");
        Object.keys(err).forEach((key) => {
          message.error(err[key]);
        });
      }
    };

    return {
      ...toRefs(state),
      order,
      loading,
      handleOrder,
      handleChange,
      moment,
      alertVisible,
      handleClose,
      orderble,
      orderbleParent,
      handleExportQuotation,
    };
  },
});
