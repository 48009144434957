import { RouteRecordRaw } from "vue-router";
import OrderList from "@/client/views/order/List.vue";
import DraftList from "@/client/views/order/DraftList.vue";
import OrderDetail from "@/client/views/order/Detail.vue";
import OrderEdit from "@/client/views/order/Edit.vue";
import OrderEditCallback from "@/client/views/order/EditCallback.vue";
import OrderConfirm from "@/client/views/order/Confirm.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/orders",
    name: "OrderList",
    component: OrderList,
  },
  {
    path: "/orders/:id",
    name: "OrderDetail",
    component: OrderDetail,
  },
  {
    path: "/orders/:id/edit",
    name: "OrderEdit",
    component: OrderEdit,
    props: true,
  },
  {
    path: "/orders/:id/editCallback",
    name: "OrderEditCallback",
    component: OrderEditCallback,
    props: true,
  },
  {
    path: "/orders/:id/confirm",
    name: "OrderConfirm",
    component: OrderConfirm,
    props: true,
  },
  {
    path: "/drafts",
    name: "DraftList",
    component: DraftList,
  },
];

export default routes;
