
import { defineComponent } from "vue";
import { Content } from "@/generic";
import UserName from "@/shared/components/CurrentUserName.vue";

import StorageFileDetail from "@/client/components/settings/storageFile/Detail.vue";

export default defineComponent({
  components: {
    Content,
    StorageFileDetail,
    UserName,
  },
  props: {
    id: {
      type: String,
      requred: true,
    },
  },
});
