
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  PropType,
  SetupContext,
} from "vue";
import EditContact from "./EditContact.vue";
import { Order } from "@/client/schema";
import { useCreateOneOrderContactMutation } from "@/client/modules/api";
import { parseError } from "@/generic/core";
import { userSelectClientContacts } from "./module";

export type Props = {
  order: Order;
};

export default defineComponent({
  components: {
    EditContact,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  emits: ["change"],
  setup(props: Props, context: SetupContext) {
    const { mutate: create, error } = useCreateOneOrderContactMutation({});

    const orderContacts = computed(() => props.order.orderContacts);

    const state = reactive({
      errorMessages: [] as string[],
      creating: false,
    });

    const handleCreate = async () => {
      if (state.creating) return;
      try {
        state.creating = true;
        await create({
          input: {
            orderId: props.order.id,
          },
        });
        context.emit("change");
      } catch (e) {
        if (error.value == null) throw e;
        state.errorMessages = parseError(error);
      } finally {
        state.creating = false;
      }
    };

    const handleChange = async () => {
      context.emit("change");
    };

    return {
      ...toRefs(state),
      ...userSelectClientContacts(props.order.clientUserGroup.id),
      orderContacts,
      handleCreate,
      handleChange,
    };
  },
});
