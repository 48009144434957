
import { defineComponent, ref } from "vue";
import { SideMemuLayout } from "@/generic";
import Nav from "./Nav.vue";

export default defineComponent({
  name: "Home",
  components: {
    SideMemuLayout,
    Nav,
  },
  setup() {
    const collapsed =
      localStorage["toggleCollapsed"] &&
      localStorage["toggleCollapsed"] === "true"
        ? ref(true)
        : ref(false);

    return {
      collapsed,
    };
  },
});
