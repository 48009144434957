
import { defineComponent } from "vue";
import { Attrs, Table } from "@/generic";

export default defineComponent({
  components: {
    Table,
  },
  setup() {
    const name = "clientUserGroup";
    const attrs = [
      {
        key: "name",
        type: "string",
        required: true,
        link: {
          to: {
            name: "SettingsClientUserGroupDetail",
          },
          idKey: "id",
          idParamName: "id",
        },
      },
      {
        key: "code",
        type: "string",
        required: false,
      },
      {
        key: "note",
        type: "string",
        required: false,
      },
    ] as Attrs;

    return {
      name,
      attrs,
    };
  },
});
