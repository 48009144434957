<style lang="less" scoped>
@import "../../../styles/variable.less";
.storagedetail {
  &-wrap {
    display: flex;
    align-items: flex-start;
    & > * {
      flex: 1 1 auto;
    }
  }
  &-edit {
    &-name {
      max-width: 420px;
    }
    &-description {
      min-height: 160px;
    }
  }
  &-buttons {
    padding-top: 20px;
    margin-top: 20px;
    border-top: solid 1px @clear-black02;
    &-col {
      display: flex;
      align-items: center;
      padding: 10px 0;
      &:nth-child(n + 2) {
        border-left: solid 1px @clear-black02;
      }
    }
  }
  /deep/ .ant-form {
    &-item-label {
      label {
        color: @clear-black06;
        font-size: 12px;
      }
    }
  }
}
</style>
<template>
  <div class="storagedetail" v-if="storageFile != null">
    <div class="detail-head">
      <a-button class="detail-head-back">
        <router-link :to="{ name: 'SettingsClientStorageFileList' }">
          <LeftOutlined />
        </router-link>
      </a-button>
      <h2 class="detail-head-name">
        {{ storageFile.name }}<br />
        <span class="detail-head-property">
          {{
            storageFile.mimeType
              .split("/")
              [storageFile.mimeType.split("/").length - 1].toUpperCase()
          }}
          / {{ getSize(storageFile.fileSize) }}
        </span>
      </h2>
    </div>
    <div class="storagedetail-wrap">
      <ImagePreview :preview="storageFile" />
      <a-form class="storagedetail-edit" layout="vertical">
        <a-form-item label="名前">
          <a-input
            class="storagedetail-edit-name"
            v-model:value="storageFile.name"
          ></a-input>
        </a-form-item>
        <a-form-item label="説明">
          <a-textarea
            class="storagedetail-edit-description"
            v-model:value="storageFile.note"
          ></a-textarea>
        </a-form-item>
        <div class="storagedetail-buttons">
          <a-row
            class="storagedetail-buttons-row"
            justify="end"
            :gutter="[32, 16]"
          >
            <a-col class="storagedetail-buttons-col">
              <a-button size="large" type="primary" @click="handleUpload"
                >編集を保存</a-button
              >
            </a-col>
            <a-col class="storagedetail-buttons-col">
              <a-button
                size="large"
                type="danger"
                @click="handleRemove(storageFile)"
                >データを削除</a-button
              >
            </a-col>
          </a-row>
        </div>
      </a-form>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, unref, ref } from "vue";
import { message } from "ant-design-vue";
import { clone, assert } from "@/generic";
import { ClientStorageFile } from "@/client/schema";
import {
  useGetOneClientStorageFileQuery,
  useUpdateOneClientStorageFileMutation,
  useRemoveOneClientStorageFileMutation,
} from "@/client/modules/api";
import { LeftOutlined } from "@ant-design/icons-vue";
import ImagePreview from "@/shared/components/ImagePreview.vue";
import { useRouter } from "vue-router";

export type Props = {
  id: string;
};

interface sizeObj {
  size: number;
  suffix: string;
}

export default defineComponent({
  components: {
    LeftOutlined,
    ImagePreview,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const router = useRouter();

    const { loading, refetch, onResult } = useGetOneClientStorageFileQuery({
      id: props.id,
    });

    const storageFile = ref(null as ClientStorageFile | null);

    onResult((result: any) => {
      storageFile.value = clone(result.data.clientStorageFile);
    });

    const useUploadClientStorageFile = () => {
      const { mutate } = useUpdateOneClientStorageFileMutation({});

      const handleUpload = async () => {
        const clientStorageFile = unref(storageFile);
        assert(clientStorageFile != null);
        await mutate({
          input: {
            id: clientStorageFile.id,
            name: clientStorageFile.name,
            note: clientStorageFile.note,
          },
        });
        await refetch();
        message.success(`更新しました。`);
      };

      return {
        handleUpload,
      };
    };

    const useRemoveClientStorageFile = () => {
      const { mutate } = useRemoveOneClientStorageFileMutation({});

      const handleRemove = async (clientStorageFile: ClientStorageFile) => {
        await mutate({
          input: {
            id: clientStorageFile.id,
          },
        });
        // await refetch();
        router.push({ name: "SettingsClientStorageFileList" });
        message.success(`削除しました。`);
      };

      return {
        handleRemove,
      };
    };

    const getSize = (size: number) => {
      const kb = 1024;
      const mb = Math.pow(kb, 2);
      const gb = Math.pow(kb, 3);
      let byteData: sizeObj = {
        size: size,
        suffix: "byte",
      };
      if (size >= gb) {
        byteData = {
          size: size / gb,
          suffix: "GB",
        };
      }
      if (size >= mb) {
        byteData = {
          size: size / mb,
          suffix: "MB",
        };
      }
      if (size >= kb) {
        byteData = {
          size: size / kb,
          suffix: "KB",
        };
      }
      const byte =
        Math.round(byteData.size).toString().toLocaleString() + byteData.suffix;

      return byte;
    };

    return {
      storageFile,
      loading,
      ...useUploadClientStorageFile(),
      ...useRemoveClientStorageFile(),
      getSize,
    };
  },
});
</script>
