import { RouteRecordRaw } from "vue-router";
import ProductList from "@/client/views/product/List.vue";
import ProductDetail from "@/client/views/product/Detail.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/products",
    name: "ProductList",
    component: ProductList,
    props: true,
  },
  {
    path: "/products/category/:categoryId",
    name: "ProductListByCategory",
    component: ProductList,
    props: true,
  },
  {
    path: "/products/:id/clientUserGroup/:clientUserGroupId",
    name: "ProductDetail",
    component: ProductDetail,
  },
];

export default routes;
