<style lang="less" scoped>
@import "../styles/variable.less";
@antl: .ant-layout;

.app-inner{
  background: @white01;
  /deep/ @{antl}{
    &-sider{
      background: @white01;
      flex: 0 1 auto !important;
      height: 100vh;
      overflow-y: auto;
      position: fixed;
      top: 0;
      left: 0;
      & + @{antl}{
        background: @white01;
        padding: 0 30px 30px 200px;
        @{antl}-content{
          background: @white02;
        }
      }
      &-collapsed{
        & + @{antl}{
          padding-left: 80px;
        }
      }
    }
  }
}
#sidemenu{
  &-inner, &-nav{
    height: 100%;
  }
  &-nav{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
</style>
<template>
  <SideMemuLayout class="app-inner" :collapsed="collapsed">
    <template #sidemenu>
      <div id="sidemenu-inner">
        <Nav v-model:collapsed="collapsed" id="sidemenu-nav"></Nav>
      </div>
    </template>
    <template #default>
      <router-view></router-view>
    </template>
  </SideMemuLayout>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { SideMemuLayout } from "@/generic";
import Nav from "./Nav.vue";

export default defineComponent({
  name: "Home",
  components: {
    SideMemuLayout,
    Nav,
  },
  setup() {
    const collapsed =
      localStorage["toggleCollapsed"] &&
      localStorage["toggleCollapsed"] === "true"
        ? ref(true)
        : ref(false);

    return {
      collapsed,
    };
  },
});
</script>
