
import { Moment } from "moment";
import { computed, defineComponent, reactive, SetupContext, toRefs } from "vue";
import {
  useGetManyProductCategoryQuery,
  useGetManyProductTagQuery,
  useGetManyOrderStatusQuery,
} from "@/client/modules/api";

import RangePicker from "@/admin/components/shared/RangePicker.vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import { useResult } from "@vue/apollo-composable";
import { GetManyProductTagQuery } from "@/client//schema";
import ClinetUserSelect from "@/client/components/shared/ClientUserSelect.vue";

export type Search = {
  clientUserId: string | null;
  orderedFrom: string | null;
  orderedTo: string | null;
  orderNo: string | null;
  productName: string | null;
  orderStatusId: string | null;
  productCategoryId: string | null;
  productTagIds: string[];
};

export default defineComponent({
  components: {
    RangePicker,
    SearchOutlined,
    ClinetUserSelect,
  },
  emits: ["search"],
  setup(_, { emit }: SetupContext) {
    const useProductTags = () => {
      const { result } = useGetManyProductTagQuery({});

      const productTags =
        useResult<GetManyProductTagQuery, "productTags">(result);
      const productTagOptions = computed(
        () =>
          productTags.value?.map((tag) => ({
            label: tag.name,
            value: tag.id,
          })) || []
      );

      return {
        productTagOptions,
      };
    };

    const useOrderStatusType = () => {
      const { result } = useGetManyOrderStatusQuery({});

      const orderStatuses = useResult(result);
      return {
        orderStatuses,
      };
    };

    const useProductCategories = () => {
      const { result } = useGetManyProductCategoryQuery({});

      const productCategories = useResult(result);
      return {
        productCategories,
      };
    };

    const state = reactive({
      clientUserId: null as string | null,
      orderedDates: [] as Moment[],
      orderNo: null as string | null,
      productName: null as string | null,
      orderStatusId: null as string | null,
      productCategoryId: null as string | null,
      productTagIds: [] as string[],
    });

    const handleSearch = () => {
      const search = {
        clientUserId: state.clientUserId,
        orderedFrom: state.orderedDates[0]?.format("YYYY-MM-DD"),
        orderedTo: state.orderedDates[1]?.format("YYYY-MM-DD"),
        orderNo: state.orderNo,
        productName: state.productName,
        orderStatusId: state.orderStatusId,
        productCategoryId: state.productCategoryId,
        productTagIds: state.productTagIds,
      } as Search;
      emit("search", search);
    };

    const handleClear = () => {
      state.clientUserId = null;
      state.orderedDates = [];
      state.orderNo = null;
      state.productName = null;
      state.orderStatusId = null;
      state.productCategoryId = null;
      state.productTagIds = [];
      emit("search", {});
    };

    return {
      ...toRefs(state),
      ...useProductCategories(),
      ...useProductTags(),
      ...useOrderStatusType(),
      handleSearch,
      handleClear,
    };
  },
});
