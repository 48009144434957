<style lang="less" scoped>
@import "../../../styles/variable.less";
.tool-active {
  color: @gray01;
}
</style>
<template>
  <div>
    <Content
      class="content-head"
      :style="{
        margin: 0,
        padding: '30px 0 30px 30px',
        height: 'auto',
      }"
    >
      <h1 class="content-head-title">ストレージ</h1>
      <StorageSummary></StorageSummary>
      <UserName class="content-head-username"></UserName>
    </Content>
    <div class="content-layout">
      <Content
        :style="{
          margin: 0,
          padding: '20px',
          height: 'auto',
        }"
      >
        <a-tabs v-if="currentUser">
          <a-tab-pane tab="全体">
            <StorageFileList></StorageFileList>
          </a-tab-pane>
          <template
            v-for="clientUserGroup in currentUser.userGroups"
            :key="clientUserGroup.id"
          >
            <a-tab-pane :tab="clientUserGroup.name">
              <StorageFileList
                :clientUserGroup="clientUserGroup"
              ></StorageFileList>
            </a-tab-pane>
          </template>
          <!-- <template #tabBarExtraContent>
            <a-typography-link
              class="head-search"
              @click="toggleSearch"
              v-bind:class="{ 'tool-active': visibleSearchTool }"
            >
              <SearchOutlined />
              <span v-if="visibleSearchTool">検索ツールを閉じる</span>
              <span v-else>検索ツールを表示</span>
            </a-typography-link>
          </template> -->
        </a-tabs>
      </Content>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { Content } from "@/generic";
import UserName from "@/shared/components/CurrentUserName.vue";

import StorageSummary from "@/client/components/settings/storageFile/Summary.vue";
import StorageFileList from "@/client/components/settings/storageFile/List.vue";
import { useGetSession } from "@/client/modules/session";

export default defineComponent({
  components: {
    Content,
    StorageSummary,
    StorageFileList,
    UserName,
  },
  setup() {
    const { currentUser } = useGetSession();
    const state = reactive({
      visibleSearchTool: false,
    });
    const toggleSearch = () => {
      state.visibleSearchTool = !state.visibleSearchTool;
    };
    return {
      ...toRefs(state),
      currentUser,
      toggleSearch,
    };
  },
});
</script>
