
import { defineComponent } from "vue";
import { Content } from "@/generic";
import { useRoute } from "vue-router";
import ProductDetail from "@/client/components/product/Detail.vue";
import UserName from "@/shared/components/CurrentUserName.vue";

export default defineComponent({
  components: {
    Content,
    ProductDetail,
    UserName,
  },
  setup() {
    const route = useRoute();
    const productId = route.params["id"];
    const clientUserGroupId = route.params["clientUserGroupId"];
    return {
      productId,
      clientUserGroupId,
    };
  },
});
