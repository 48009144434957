<style lang="less" scoped>
.reject-comment {
  color: #f00;
}
</style>
<template>
  <a-comment :author="commentData.username">
    <template #content>
      <p
        class="comment"
        v-text="commentData.comment"
        :class="commentColor(commentData.commentType)"
      ></p>
      <a-image-preview-group>
        <template v-for="file in commentData.files" :key="file.id">
          <a-image :width="120" :src="file.thumbnailPath" />
        </template>
      </a-image-preview-group>
    </template>
    <template #datetime>
      <span>{{ formatTimestamp(commentData.createdAt) }}</span>
    </template>
  </a-comment>
</template>

<script lang="ts">
import { defineComponent, SetupContext, unref, PropType, ref } from "vue";
import { message } from "ant-design-vue";
import { useUploadResource } from "@/client/components/orderComment/modules/uploadResource";
import { formatTimestamp } from "@/generic";
import { OrderComment } from "@/client/schema";

export type Props = {
  comment: OrderComment;
};

export default defineComponent({
  props: {
    comment: {
      type: Object as PropType<OrderComment>,
      required: true,
    },
  },
  emits: ["refetch"],
  setup(props: Props, context: SetupContext) {
    const commentData = ref(props.comment);
    const { uploadData, errorMessages: importErrorMessages } =
      useUploadResource();

    const appendFile = async (file: File) => {
      const fileExt = file.name.split(".").pop();

      uploadData(commentData.value.id, file, fileExt).then(
        async (result: boolean) => {
          // 非同期に呼び出したいのでawaitにしないこと
          if (result) {
            context.emit("refetch");
            message.success("アップロードしました。");
          } else {
            const errorMessages = unref(importErrorMessages);
            Object.keys(errorMessages).forEach((key) => {
              message.error(errorMessages[key]);
            });
          }
        }
      );
      return false;
    };

    const commentColor = (commentType: string) => {
      if (commentType === "reject") {
        return "reject-comment";
      } else {
        return "";
      }
    };

    return {
      commentData,
      formatTimestamp,
      appendFile,
      commentColor,
    };
  },
});
</script>
