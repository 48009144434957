<template>
  <div>
    <Content
      class="content-head"
      :style="{
        margin: 0,
        padding: '30px 0 30px 30px',
        height: 'auto',
      }"
    >
      <h1 class="content-head-title">注文詳細</h1>
      <UserName class="content-head-username"></UserName>
    </Content>
    <div class="content-layout">
      <!-- <Content>
        <a-breadcrumb>
          <a-breadcrumb-item>注文一覧</a-breadcrumb-item>
          <a-breadcrumb-item>注文</a-breadcrumb-item>
        </a-breadcrumb>
      </Content> -->
      <Content
        :style="{
          margin: 0,
          padding: '20px',
          height: 'auto',
        }"
      >
        <OrderDetail :orderId="orderId" :key="orderId"></OrderDetail>
        <template v-if="order && order.product.productType === 'draft'">
          <a-tabs>
            <a-tab-pane key="1" tab="コメント">
              <OrderCommentList :orderId="orderId"></OrderCommentList>
            </a-tab-pane>
            <a-tab-pane key="2" tab="注文入稿データ">
              <OrderProductFileList :orderId="orderId"></OrderProductFileList>
            </a-tab-pane>
          </a-tabs>
        </template>
        <template v-else>
          <OrderCommentList :orderId="orderId"></OrderCommentList>
        </template>
      </Content>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, unref } from "vue";
import { Content } from "@/generic";
import { useRoute } from "vue-router";
import { useGetOneOrderQuery } from "@/client/modules/api";
import { useResult } from "@vue/apollo-composable";
import OrderDetail from "@/client/components/order/Detail.vue";
import OrderCommentList from "@/client/components/orderComment/List.vue";
import OrderProductFileList from "@/client/components/orderProductFile/List.vue";
import UserName from "@/shared/components/CurrentUserName.vue";

export default defineComponent({
  components: {
    Content,
    OrderDetail,
    OrderCommentList,
    OrderProductFileList,
    UserName,
  },
  setup() {
    const route = useRoute();
    const orderId = computed(() => route.params["id"]);

    const { result } = useGetOneOrderQuery({
      id: unref(orderId) as string,
    });
    const order = useResult(result);

    return {
      orderId,
      order,
    };
  },
});
</script>
