<style lang="less" scoped></style>
<template>
  <div>
    <Content
      class="content-head"
      :style="{
        margin: 0,
        padding: '30px 0 30px 30px',
        height: 'auto',
      }"
    >
      <h1 class="content-head-title">承認一覧</h1>
      <UserName class="content-head-username"></UserName>
    </Content>
    <div class="content-layout">
      <ApprovalList></ApprovalList>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Content } from "@/generic";
import ApprovalList from "@/client/components/approval/List.vue";
import UserName from "@/shared/components/CurrentUserName.vue";

export default defineComponent({
  components: {
    Content,
    ApprovalList,
    UserName,
  },
});
</script>
