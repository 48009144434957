
import { defineComponent } from "vue";
import { useResult } from "@vue/apollo-composable";
import { useGetOneNotificationQuery } from "@/client/modules/api";
import { formatDate } from "@/generic";

export type Props = {
  notificationId: string;
};

export default defineComponent({
  props: {
    notificationId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const { result, loading } = useGetOneNotificationQuery({
      id: props.notificationId,
    });

    const notification = useResult(result);

    return {
      notification,
      loading,
      formatDate,
    };
  },
});
