import { RouteRecordRaw } from "vue-router";
import StorageFileList from "@/client/views/storageFile/List.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/storageFiles",
    name: "StorageFileList",
    component: StorageFileList,
  },
];

export default routes;
