<style lang="less" scoped>
@import "../../styles/variable.less";
.productdetail {
  &-content01 {
    display: flex;
    align-items: flex-start;
    & > * {
      flex: 1 1 auto;
    }
    .content01 {
      &-wrap {
        flex-basis: 280px;
        max-width: 280px;
        min-width: 280px;
        margin-right: 40px;
      }
      &-tag {
        display: flex;
        flex-wrap: wrap;
        color: @gray01;
        font-size: 12px;
        padding: 0;
        margin: -10px -5px 15px -5px;
        & > * {
          flex: 0 1 auto;
        }
        &item {
          display: block;
          margin: 10px 5px 0;
          padding: 2px 10px;
          border: solid 1px;
          border-radius: 3em;
        }
      }
      &-info {
        overflow: hidden;
      }
      &-title {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 15px;
      }
      &-description {
        color: @gray01;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: solid 1px @clear-black02;
        white-space: pre;
      }
      &-row {
        & + .content01-row {
          margin-top: 20px;
          padding-top: 20px;
          border-top: solid 1px @clear-black02;
        }
      }
      &-col {
        border-left: solid 1px @clear-black02;
        &:first-child {
          border-left: none;
        }
      }
      &-label {
        color: @clear-black06;
        font-size: 10px;
      }
      &-data {
        margin-bottom: 0;
        &.bold {
          font-weight: bold;
        }
      }
    }
  }
  &-content02 {
    margin-top: 40px;
    overflow: hidden;
    .content02 {
      &-title {
        font-weight: bold;
      }
      &-col {
        border-left: solid 1px @clear-black02;
      }
      &-delivery {
        color: @red01;
        margin: 0;
        &-small {
          font-size: 12px;
          color: @clear-red01;
        }
      }
      &-alert {
        color: @red01;
        margin-top: 30px;
        &-inner {
          display: table;
          background: @red02;
          padding: 3px 6px;
          border: solid 1px;
          &:before {
            content: "※";
            display: table-cell;
            padding-right: 5px;
          }
        }
      }
      &-list {
        color: @gray01;
        margin: 0;
        padding: 0;
      }
      &-item {
        display: table;
        &:before {
          content: "・";
          display: table-cell;
        }
        &:nth-child(n + 2) {
          margin-top: 5px;
        }
      }
    }
  }
  &-content03 {
    margin-top: 20px;
    padding-top: 30px;
    border-top: solid 1px @clear-black02;
    .content03 {
      &-title {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 20px;
      }
      &-order {
        &-list {
          margin: 0;
          padding: 0;
        }
        &-item {
          display: flex;
          width: 100%;
          border: solid 1px @clear-black02;
          & > * {
            flex: 0 1 auto;
            white-space: nowrap;
            padding: 5px;
            &:first-child {
              padding-left: 20px;
            }
          }
          .result {
            flex-grow: 1;
            text-align: right;
            padding-right: 20px;
          }
          .order {
            position: relative;
            padding-left: 20px;
            &:before {
              background: @clear-black02;
              display: block;
              content: "";
              width: 1px;
              height: calc(100% - 16px);
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              margin: auto;
            }
            .anticon-right {
              margin-left: 5px;
              transform: scale(0.8);
            }
          }
          &:nth-child(n + 2) {
            margin-top: 10px;
          }
        }
        &-quantity {
          display: flex;
          flex-flow: column;
          width: 100%;
          border: none;
          & > * {
            flex: 0 1 auto;
            white-space: nowrap;
            padding: 5px;
          }
          &:nth-child(n + 2) {
            margin-top: 10px;
          }
        }
        &-calc {
          display: flex;
          width: 100%;
          align-items: center;
          & > * {
            padding: 0 5px 0 10px;
            &:last-child {
              padding-left: 20px;
            }
          }
        }
      }
      &-sample {
        &-title {
          font-weight: bold;
          margin-bottom: 20px;
        }
        &-inner {
          background: @white03;
          padding: 20px;
          border: solid 1px @clear-black02;
        }
        &-list {
          color: @gray01;
          margin: 0;
          padding: 0;
        }
        &-item {
          display: table;
          &:before {
            content: "・";
            display: table-cell;
          }
          &:nth-child(n + 2) {
            margin-top: 5px;
          }
        }
        &-table {
          width: 100%;
          th,
          td {
            border-bottom: solid 1px @clear-black02;
            padding: 10px 0;
          }
          th {
            color: @gray01;
            font-weight: normal;
            padding-right: 1em;
          }
          td {
            padding-left: 1em;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="productdetail" v-if="!loading && product != null">
    <div>
      <div class="detail-head">
        <a-button class="detail-head-back" v-if="!parentId">
          <router-link :to="{ name: 'ProductList' }">
            <LeftOutlined />
          </router-link>
        </a-button>
        <h2 class="detail-head-name">
          {{ product.name }}<br />
          <span class="detail-head-property">{{ product.category?.name }}</span>
        </h2>
      </div>
      <div class="productdetail-content01">
        <div class="content01-wrap">
          <ul class="content01-tag">
            <li
              class="content01-tagitem"
              v-for="tag in product.tags"
              :key="tag"
            >
              {{ tag.name }}
            </li>
          </ul>
          <template v-if="product.files[0]">
            <ImagePreview :preview="product.files[0]" :child="true" />
          </template>
          <template v-else>
            <ImagePreview :fallback="true" :child="true" />
          </template>
        </div>
        <div class="content01-info">
          <h3 class="content01-title">商品概要</h3>
          <div class="content01-description">
            <template v-if="product.note">{{ product.note }}</template>
            <template v-else></template>
          </div>
          <a-row class="content01-row" justify="end" :gutter="[32, 16]">
            <a-col v-if="product.arrivalDate" class="content01-col">
              <p class="content01-label">本日注文・承認完了で</p>
              <p class="content01-data bold">
                <template v-if="product.prices.length > 0">
                  {{ moment(product.arrivalDate).format("MM月DD日（dd）") }}到着
                </template>
              </p>
            </a-col>
            <a-col v-else-if="product.unitBasicDueDate" class="content01-col">
              <p class="content01-label">単価商品のため到着日算出不可</p>
            </a-col>
            <a-col v-else class="content01-col">
              <p class="content01-label">商品価格未登録</p>
            </a-col>
            <!-- <a-col class="content01-col">
              <p class="content01-data">
                <a-button class="order-cancelbtn" size="large" type="primary"
                  >入稿データをアップロード</a-button
                >
              </p>
            </a-col> -->
          </a-row>
        </div>
      </div>
      <!-- <div class="productdetail-content02">
        <h3 class="content02-title">印刷日数の目安</h3>
        <a-row type="flex" class="content02-row" :gutter="[40, 20]">
          <a-col flex="auto" class="content02-col">
            <p class="content02-order">注文日（承認日）</p>
            <p class="content02-delivery">
              出荷日<br /><span class="content02-delivery-small">印刷日数</span>
            </p>
          </a-col>
          <a-col flex="auto" class="content02-col" v-for="n of 6" :key="n">
            <p class="content02-order">
              {{ moment(new Date()).add(n, "d").format("MM月DD日（dd）") }}
            </p>
            <p class="content02-delivery">
              {{
                moment(new Date())
                  .add(n + product.prices[0].basicDueDate, "d")
                  .format("MM月DD日（dd）")
              }}<br /><span class="content02-delivery-small"
                >印刷{{ product.prices[0].basicDueDate }}日</span
              >
            </p>
          </a-col>
        </a-row>
        <p class="content02-alert">
          <span class="content02-alert-inner"
            >商品によっては、「土・日・祝」など、納期計算されない場合がございます。</span
          >
        </p>
        <ul class="content02-list">
          <li class="content02-item">
            出荷日とお手元に届く日にちは異なります。
          </li>
          <li class="content02-item">
            データ入稿商品の場合、印刷データが未入稿であったり、印刷データに不具合があった場合、期日が異なります。
          </li>
        </ul>
      </div> -->
      <div class="productdetail-content03" v-if="product.priceType == 'table'">
        <h3 class="content03-title">料金表</h3>
        <a-row :gutter="24">
          <a-col class="content03-order" :span="12">
            <ul class="content03-order-list">
              <li
                class="content03-order-item"
                v-for="record in product.prices"
                :key="record.id"
              >
                <div><Number :value="record.quantity"></Number>部</div>
                <div class="result">
                  <Currency :value="record.price"></Currency>
                </div>
                <div class="order">
                  <a-typography-link
                    @click="handleNewOrderByProductPrice(record)"
                    >注文する<RightOutlined
                  /></a-typography-link>
                </div>
              </li>
            </ul>
          </a-col>
          <!-- <a-col class="content03-sample" :span="12">
            <div class="content03-sample-inner">
              <a-row :gutter="20">
                <a-col :span="12">
                  <h4 class="content03-sample-title">印刷代金の計算目安</h4>
                  <ul class="content03-sample-list">
                    <li class="content03-sample-item">あくまで目安ですので、実際の料金とは異なる場合があります。</li>
                  </ul>
                </a-col>
                <a-col :span="12">
                  <table class="content03-sample-table">
                    <tr class="content03-sample-row">
                      <th>用紙代</th>
                      <td>100 円</td>
                    </tr>
                    <tr class="content03-sample-row">
                      <th>判代</th>
                      <td>10,000 円 ÷ 部数</td>
                    </tr>
                    <tr class="content03-sample-row">
                      <th>インク代</th>
                      <td>10,000 円 ÷ 部数</td>
                    </tr>
                    <tr class="content03-sample-row">
                      <th>運送費</th>
                      <td>1,500 円 ÷ 部数</td>
                    </tr>
                    <tr class="content03-sample-row">
                      <th>部数割引</th>
                      <td>100部につき-0.5%</td>
                    </tr>
                  </table>
                </a-col>
              </a-row>
            </div>
          </a-col> -->
        </a-row>
      </div>
      <div class="productdetail-content03" v-if="product.priceType == 'unit'">
        <h3 class="content03-title">
          数量入力（単価：<Number v-model:value="product.unitPrice"></Number
          >円、最低金額：<Number v-model:value="product.minPrice"></Number>円）
        </h3>
        <a-row :gutter="24">
          <a-col class="content03-order" :span="12">
            <ul class="content03-order-list">
              <li class="content03-order-quantity">
                <div class="content03-order-calc">
                  <div>
                    数量：<InputNumber v-model:value="quantity"></InputNumber>
                    部
                  </div>
                  <div class="price">
                    金額：<Number :value="price"></Number> 円
                  </div>
                  <a-button type="link" @click="handleNewOrderByQuantity"
                    >注文する<RightOutlined
                  /></a-button>
                </div>
              </li>
            </ul>
          </a-col>
        </a-row>
      </div>
      <a-modal v-model:visible="visibleNewModal" @ok="handleCreteOrder">
        <p>注文しますか？</p>
        <a-alert
          v-if="errorMessages"
          type="error"
          message="注文できませんでした。"
        >
          <template #description>
            <div v-for="(error, index) in errorMessages" :key="index">
              {{ error }}
            </div>
          </template>
        </a-alert>
      </a-modal>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  SetupContext,
  computed,
  unref,
  ref,
} from "vue";
import { useRouter } from "vue-router";
import { parseError, assert, clone } from "@/generic";

import { useGetOneProductQuery } from "@/client/modules/api";
import { useI18n } from "@/shared/providors/i18n";
import { ProductPrice, Product } from "@/admin/schema";
import { useCreateOneOrderMutation } from "@/client/modules/api";
import { RightOutlined, LeftOutlined } from "@ant-design/icons-vue";
import ImagePreview from "@/shared/components/ImagePreview.vue";
import { Currency, Number } from "@/generic/components/display";
import { InputNumber } from "@/generic/components/entry";

import moment from "moment";
import "moment/locale/ja";
moment.locale("ja");

export type Props = {
  productId: string;
  clientUserGroupId: string;
  parentId?: string;
};

export default defineComponent({
  components: {
    RightOutlined,
    LeftOutlined,
    ImagePreview,
    Currency,
    Number,
    InputNumber,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    clientUserGroupId: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      required: false,
    },
  },
  setup(props: Props, { emit }: SetupContext) {
    const router = useRouter();

    const { ta } = useI18n();
    const { onResult, loading } = useGetOneProductQuery({
      id: props.productId,
    });

    const product = ref(null as Product | null);

    onResult((result: any) => {
      product.value = clone(result.data.product);
      if (product.value?.priceType === "unit") {
        state.quantity = 1000;
      }
    });

    const priceColumns = [
      {
        title: ta("quantity"),
        dataIndex: "quantity",
        key: "quantity",
        width: 120,
      },
      {
        title: ta("price"),
        dataIndex: "price",
        key: "price",
        width: 120,
      },
      {
        title: ta("basicDueDate"),
        dataIndex: "basicDueDate",
        key: "basicDueDate",
        width: 120,
      },
      {
        title: "",
        slots: {
          customRender: "action",
        },
        width: 60,
      },
    ];

    const { mutate: craeteOneOrder, error } = useCreateOneOrderMutation({});

    const state = reactive({
      visibleNewModal: false,
      currentPrice: null as ProductPrice | null,
      quantity: null as number | null,
      errorMessages: null as string[] | null,
    });

    const price = computed(() => {
      const _product = unref(clone(product)) as Product;
      if (state.quantity == null || _product.unitPrice == null) return 0;

      const calculatedPrice = Math.ceil(_product.unitPrice * state.quantity);
      if (
        _product.hasMinPrice &&
        _product.minPrice != null &&
        calculatedPrice < _product.minPrice
      ) {
        return _product.minPrice;
      } else {
        return calculatedPrice;
      }
    });

    const handleNewOrderByProductPrice = (productPrice: ProductPrice) => {
      state.visibleNewModal = true;
      state.currentPrice = productPrice;
      state.errorMessages = null;
    };

    const handleNewOrderByQuantity = () => {
      state.visibleNewModal = true;
      state.errorMessages = null;
    };

    const handleCreteOrder = async () => {
      try {
        assert(state.currentPrice != null || state.quantity != null);
        const productPrice: ProductPrice | null = state.currentPrice;
        const quantity = state.quantity;
        const result = await craeteOneOrder({
          input: {
            clientUserGroupId: props.clientUserGroupId,
            productPriceId: productPrice?.id,
            productId: props.productId,
            quantity: quantity,
            parentId: props.parentId || null,
          },
        });

        state.visibleNewModal = false;

        const orderId = result?.data?.createOneOrder?.order?.id;
        if (orderId) {
          emit("closeModal");
          await router.push({
            name: "OrderEdit",
            params: {
              id: orderId,
            },
          });
        }
      } catch (e) {
        if (error.value == null) throw e;
        state.errorMessages = parseError(error.value);
      }
    };

    return {
      ...toRefs(state),
      product,
      loading,
      priceColumns,
      price,
      handleNewOrderByProductPrice,
      handleNewOrderByQuantity,
      handleCreteOrder,
      moment,
    };
  },
});
</script>
