
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { Attrs } from "@/generic";

export type Props = {
  clientUserGroupId: string;
};

export default defineComponent({
  props: {
    clientUserGroupId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const route = useRoute();

    const clientUserGroupId = route.params["id"];

    const name = "clientUser";
    const queryVariables = [
      {
        key: "clientUserGroupId",
        graphQLType: "ID",
        value: props.clientUserGroupId,
      },
    ];

    const createMutationVariables = [
      {
        key: "clientUserGroupId",
        graphQLType: "ID",
        value: clientUserGroupId,
      },
    ];

    const attrs = [
      {
        key: "name",
        type: "string",
        required: true,
        link: {
          to: {
            name: "ClientDetail",
          },
        },
      },
    ] as Attrs;

    return {
      name,
      queryVariables,
      createMutationVariables,
      attrs,
    };
  },
});
