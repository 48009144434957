import gql from "graphql-tag";
import { useApolloClient } from "@vue/apollo-composable";
import { Ref, ComputedRef, toRefs, reactive } from "vue";
import { ErrorMessages } from "@/generic";

export function useUploadResource() {
  const { client } = useApolloClient();

  const state = reactive({
    errorMessages: {} as ErrorMessages,
  });

  const uploadData = async (
    resourceId: string | Ref<string> | ComputedRef<string> | null,
    name: string | null,
    file: any,
    fileFormat = "png",
    input: { [key: string]: any } = {}
  ): Promise<boolean> => {
    state.errorMessages = {} as ErrorMessages;
    try {
      const result = await client.query({
        query: gql`
          query PostUploadFilePath(
            $fileFormat: UploadFileFormat!
            $resourceId: String
            $name: String
          ) {
            uploadEndpoint(
              name: $name
              resourceId: $resourceId
              fileFormat: $fileFormat
            ) {
              endpoint
            }
          }
        `,
        variables: {
          fileFormat,
          resourceId,
          name,
        },
        fetchPolicy: "no-cache",
      });

      const uploadEndpoint = result.data.uploadEndpoint.endpoint;

      const formData = new FormData();
      formData.append("file", file);
      formData.append("input", JSON.stringify(input));

      await fetch(uploadEndpoint, {
        method: "POST",
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // },
        body: formData,
      });
      return true;
    } catch (err) {
      console.log("err", err);
      state.errorMessages = err.response?.data?.error?.message || err.message;
      return false;
    }
  };

  return {
    ...toRefs(state),
    uploadData,
  };
}
