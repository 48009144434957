import { isLoggined, isInited } from "@/client/store/session";
export const authGuard = async (to: any, from: any, next: any) => {
  if (to.matched.some((record: any) => record.meta.noAuth)) {
    // 認証なし
    next();
  } else if (isLoggined()) {
    // ログイン済み
    next();
  } else if (!isInited()) {
    // 初期化前
    if (to.fullPath === "/") {
      next({ name: "SessionUpdate" });
    } else {
      next({ name: "SessionUpdate", query: { redirect: to.fullPath } });
    }
  } else {
    if (to.fullPath === "/") {
      next({ name: "SessionCreate" });
    } else {
      next({ name: "SessionCreate", query: { redirect: to.fullPath } });
    }
  }
};
