
import {
  defineComponent,
  reactive,
  SetupContext,
  toRefs,
  watch,
  computed,
} from "vue";
import { useGetSession } from "@/client/modules/session";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  CheckCircleOutlined,
  RightOutlined,
  DatabaseOutlined,
  FormOutlined,
} from "@ant-design/icons-vue";
import ItemListIcon from "@/shared/components/svg-icon/ItemList.vue";
import OrderListIcon from "@/shared/components/svg-icon/OrderList.vue";
import UserIcon from "@/shared/components/svg-icon/User.vue";
import SettingIcon from "@/shared/components/svg-icon/Setting.vue";
import Logo from "@/shared/components/Logo.vue";
import { useGetManyProductCategoryQuery } from "@/client/modules/api";
import { GetManyProductCategoryQuery } from "@/client/schema";
import { useResult } from "@vue/apollo-composable";

type Props = {
  collapsed: boolean;
};

export default defineComponent({
  components: {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    HomeOutlined,
    CheckCircleOutlined,
    RightOutlined,
    ItemListIcon,
    DatabaseOutlined,
    FormOutlined,
    OrderListIcon,
    UserIcon,
    SettingIcon,
    Logo,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:collapsed"],
  setup(props: Props, { emit }: SetupContext) {
    const { result } = useGetManyProductCategoryQuery({});

    const logoSize = computed(() => (props.collapsed ? "s" : "l"));

    const productCategories =
      useResult<GetManyProductCategoryQuery, "productCategories">(result);

    const { currentUser, isManager, isStaff } = useGetSession();
    const state = reactive({
      collapsed: props.collapsed,
      openKeys: [],
      preOpenKeys: [],
      selectedKeys: [] as string[],
    });
    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      }
    );
    const toggleCollapsed = () => {
      state.collapsed = !state.collapsed;
      // サイドナビの開閉状態管理のBoolを保存
      localStorage["toggleCollapsed"] = state.collapsed;
      state.openKeys = state.collapsed ? [] : state.preOpenKeys;
      emit("update:collapsed", state.collapsed);
    };
    const onSelect = (obj: any) => {
      const selected = obj.currentTarget.getAttribute("data-select");
      state.selectedKeys = [];
      state.selectedKeys = [selected];
    };
    const menuAddClass = (key: string) => {
      return state.selectedKeys.indexOf(key) !== -1 ? "is-active" : "";
    };

    return {
      ...toRefs(state),
      productCategories,
      currentUser,
      isManager,
      isStaff,
      toggleCollapsed,
      onSelect,
      menuAddClass,
      logoSize,
    };
  },
});
