
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  PropType,
  SetupContext,
} from "vue";
import { Order } from "@/client/schema";
import { message } from "ant-design-vue";
import { useRemoveOneOrderProductFileMutation } from "@/client/modules/api";
export type Props = {
  order: Order;
};

import { useUploadResource } from "@/client/components/orderProductFile/modules/uploadResource";
import { OrderProductFile } from "../../../admin/schema";
import { PaperClipOutlined } from "@ant-design/icons-vue";
import { formatTimestamp } from "@/generic";

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
  preview?: string;
  originFileObj?: any;
  file: string | Blob;
}

export default defineComponent({
  components: {
    PaperClipOutlined,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  emits: ["change"],
  setup(props: Props, { emit }: SetupContext) {
    const { uploadData } = useUploadResource();
    // const isDraft = computed(
    //   () => props.order.orderProduct?.productType === ProductType.Draft
    // );
    const files = computed(() => props.order.orderProduct.files);

    const getCurrentClassObject = (file: OrderProductFile) => {
      const classObject = {
        "modal-row": true,
        "modal-row-disabled": file.disabled,
      } as { [key: string]: boolean };

      return classObject;
    };

    const state = reactive({
      visibleUpload: false,
    });

    const handleVisibleUpload = () => {
      state.visibleUpload = true;
    };

    const handleUpload = (file: FileItem) => {
      const orderProduct = props.order.orderProduct;

      const fileExt = file.name?.split(".").pop() || "uploadFile";

      uploadData(orderProduct.id, file, fileExt).then(
        async (result: boolean) => {
          // 非同期に呼び出したいのでawaitにしないこと
          if (result) {
            emit("change");
          } else {
            message.error("ファイルアップロードに失敗しました");
          }
        }
      );
      return false;
    };

    const useRemoveOneOrderProductFile = () => {
      const { mutate } = useRemoveOneOrderProductFileMutation({});

      const handleRemove = async (file: OrderProductFile) => {
        await mutate({
          input: {
            id: file.id,
          },
        });
        emit("change");
      };

      return {
        handleRemove,
      };
    };

    return {
      ...toRefs(state),
      getCurrentClassObject,
      handleVisibleUpload,
      handleUpload,
      // isDraft,
      files,
      ...useRemoveOneOrderProductFile(),
      formatTimestamp,
    };
  },
});
