
import { defineComponent, reactive, toRefs, ref } from "vue";
import AddressForm from "@/shared/components/AddressForm.vue";
import {
  Prefecture,
  CreateOneClientDeliveryInput,
  UpdateOneClientDeliveryInput,
  RemoveOneClientDeliveryInput,
} from "@/client/schema";

import {
  useGetManyClientDeliveryQuery,
  useGetManyCountClientDeliveryQuery,
  useCreateOneClientDeliveryMutation,
  useUpdateOneClientDeliveryMutation,
  useRemoveOneClientDeliveryMutation,
} from "@/client/modules/api";
import { useResult } from "@vue/apollo-composable";
import { useI18n } from "../../../../shared/providors/i18n";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";

import { getAccessToken } from "@/shared/providors/session";

export type Props = {
  clientUserGroupId: string;
};

// const fileFormats = ["csv", "xlsx"]; // エクセルファイルのエクスポートは未対応
const fileFormats = ["csv"];

export default defineComponent({
  components: {
    AddressForm,
    DownloadOutlined,
    UploadOutlined,
  },

  props: {
    clientUserGroupId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const { ta } = useI18n();

    const state = reactive({
      clientDelivery: {} as CreateOneClientDeliveryInput,
      updateClientDelivery: {} as UpdateOneClientDeliveryInput,
      errorMessages: [] as string[],
      currentPage: 1,
      pageSize: 10,
    });

    const { result, refetch, variables } = useGetManyClientDeliveryQuery(
      {
        clientUserGroupId: props.clientUserGroupId,
        offset: 0,
        limit: state.pageSize,
      },
      {
        fetchPolicy: "cache-and-network",
      }
    );

    const deliveries = useResult(result);

    const { result: totalResult, refetch: totalRefetch } =
      useGetManyCountClientDeliveryQuery(
        {
          clientUserGroupId: props.clientUserGroupId,
        },
        {
          fetchPolicy: "cache-and-network",
        }
      );

    const total = useResult(totalResult);

    const handlePageChange = (page: number) => {
      state.currentPage = page;
      variables.value = {
        clientUserGroupId: props.clientUserGroupId,
        offset: (page - 1) * state.pageSize,
        limit: state.pageSize,
      };
    };

    const columns = [
      {
        key: "name",
        title: ta("name"),
        dataIndex: "name",
      },
      {
        key: "code",
        title: ta("code"),
        dataIndex: "code",
      },
      {
        key: "zipCode",
        dataIndex: "zipCode",
        title: ta("zipCode"),
      },
      {
        key: "prefecture",
        dataIndex: "prefecture",
        title: ta("prefecture"),
        slots: {
          customRender: "prefecture",
        },
      },
      {
        key: "city",
        dataIndex: "city",
        title: ta("city"),
      },
      {
        key: "block",
        dataIndex: "block",
        title: ta("block"),
      },
      {
        key: "building",
        dataIndex: "building",
        title: ta("building"),
      },
      {
        key: "tel",
        dataIndex: "tel",
        title: ta("tel"),
      },
      {
        key: "default",
        dataIndex: "default",
        title: ta("default"),
        slots: {
          customRender: "boolean",
        },
      },
      {
        key: "actions",
        title: "アクション",
        fixed: "right",
        slots: {
          customRender: "actions",
        },
      },
    ];

    const handleCancel = () => {
      state.errorMessages = [];
    };

    const createClientDelivery = () => {
      const { mutate, error, onError, onDone } =
        useCreateOneClientDeliveryMutation({});

      const visibleAdd = ref<boolean>(false);
      const handleAdd = async () => {
        state.clientDelivery = {
          clientUserGroupId: props.clientUserGroupId,
          name: "",
          zipCode: "",
          prefecture: Prefecture.Tokyo,
          city: "",
          block: "",
          building: "",
          tel: "",
          fax: "",
          default: false,
        };
        visibleAdd.value = true;
      };

      const handlCreate = async () => {
        await mutate({
          input: {
            clientUserGroupId: props.clientUserGroupId,
            name: state.clientDelivery.name,
            zipCode: state.clientDelivery.zipCode,
            prefecture: state.clientDelivery.prefecture,
            city: state.clientDelivery.city,
            block: state.clientDelivery.block,
            building: state.clientDelivery.building,
            tel: state.clientDelivery.tel,
            fax: state.clientDelivery.fax,
            default: state.clientDelivery.default,
          },
        });
      };

      onDone(() => {
        refetch();
        totalRefetch();
        visibleAdd.value = false;
        handleCancel();
      });

      onError(() => {
        state.errorMessages = error.value.message.split(",");
      });

      return {
        visibleAdd,
        handleAdd,
        handlCreate,
      };
    };

    const updateClientDelivery = () => {
      const updateVisible = ref<boolean>(false);
      const {
        mutate: updateOneClientDelivery,
        error: updateError,
        onError: updateOnError,
        onDone: updateOnDone,
      } = useUpdateOneClientDeliveryMutation({});

      const handleUpdate = async () => {
        await updateOneClientDelivery({
          input: {
            id: state.updateClientDelivery.id,
            clientUserGroupId: state.updateClientDelivery.clientUserGroupId,
            name: state.updateClientDelivery.name,
            zipCode: state.updateClientDelivery.zipCode,
            prefecture: state.updateClientDelivery.prefecture,
            city: state.updateClientDelivery.city,
            block: state.updateClientDelivery.block,
            building: state.updateClientDelivery.building,
            tel: state.updateClientDelivery.tel,
            fax: state.updateClientDelivery.fax,
            default: state.updateClientDelivery.default,
          },
        });
      };

      const handleEdit = async (delivery: UpdateOneClientDeliveryInput) => {
        state.updateClientDelivery = {
          id: delivery.id,
          clientUserGroupId: delivery.clientUserGroupId,
          name: delivery.name,
          zipCode: delivery.zipCode,
          prefecture: delivery.prefecture,
          city: delivery.city,
          block: delivery.block,
          building: delivery.building,
          tel: delivery.tel,
          fax: delivery.fax,
          default: delivery.default,
        };
        updateVisible.value = true;
      };

      updateOnDone(() => {
        refetch();
        updateVisible.value = false;
      });

      updateOnError(() => {
        state.errorMessages = updateError.value.message.split(",");
      });

      return {
        updateVisible,
        handleUpdate,
        handleEdit,
      };
    };

    const removeClientDelivery = () => {
      const {
        mutate: removeOneClientDelivery,
        loading: removeLoading,
        error: removeError,
        onError: removeOnError,
        onDone: removeOnDone,
      } = useRemoveOneClientDeliveryMutation({});

      const handleRemove = async (delivery: RemoveOneClientDeliveryInput) => {
        await removeOneClientDelivery({
          input: {
            id: delivery.id,
          },
        });
      };

      removeOnDone(() => {
        refetch();
        totalRefetch();
      });

      removeOnError(() => {
        state.errorMessages = removeError.value.message.split(",");
      });

      return {
        handleRemove,
        removeLoading,
      };
    };

    const handleExport = async (fileFormat: string) => {
      try {
        const exportEndpoint = `/api/app/client_delivery/export/${props.clientUserGroupId}.${fileFormat}`;
        const accessToken = getAccessToken("client");
        await fetch(exportEndpoint, {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const anchor = document.createElement("a");
            anchor.href = window.URL.createObjectURL(blob);
            const date = new Date();
            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            const hour = ("0" + date.getHours()).slice(-2);
            const minute = ("0" + date.getMinutes()).slice(-2);
            const dateTime = `${year}${month}${day}_${hour}${minute}`;
            const fileName = `スタッフ配送先_${dateTime}.${fileFormat}`;
            anchor.download = fileName;
            anchor.click();
          });
      } catch (err) {
        console.log("err", err);
        message.error("エラーが発生し、ダウンロードできませんでした。");
        Object.keys(err).forEach((key) => {
          message.error(err[key]);
        });
      }
    };

    const handleImport = async (file: any) => {
      try {
        const importEndpoint = `/api/app/client_delivery/import/${props.clientUserGroupId}.csv`;
        const accessToken = getAccessToken("client");
        const formData = new FormData();
        formData.append("file", file);

        await fetch(importEndpoint, {
          method: "POST",
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
          // headers: {
          //   'Content-Type': 'multipart/form-data',
          // },
          body: formData,
        });
        await refetch();
        await totalRefetch();
        message.success("アップロードしました。");
      } catch (err) {
        console.log("err", err);
        message.error("エラーが発生し、アップロードできませんでした。");
        Object.keys(err).forEach((key) => {
          message.error(err[key]);
        });
      }
    };

    return {
      ...toRefs(state),
      fileFormats,
      deliveries,
      total,
      handlePageChange,
      columns,
      ...createClientDelivery(),
      ...updateClientDelivery(),
      ...removeClientDelivery(),
      handleExport,
      handleImport,
      handleCancel,
    };
  },
});
