<style lang="less" scoped></style>
<template>
  <div v-if="!loading && notification != null">
    <a-card :title="notification.title" :loading="loading">
      <p>［{{ formatDate(notification.publishedAt) }}］</p>
      <p>{{ notification.content }}</p>
    </a-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useResult } from "@vue/apollo-composable";
import { useGetOneNotificationQuery } from "@/client/modules/api";
import { formatDate } from "@/generic";

export type Props = {
  notificationId: string;
};

export default defineComponent({
  props: {
    notificationId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const { result, loading } = useGetOneNotificationQuery({
      id: props.notificationId,
    });

    const notification = useResult(result);

    return {
      notification,
      loading,
      formatDate,
    };
  },
});
</script>
