<style lang="less" scoped>
@import "../../styles/variable.less";
.orderdetail {
  .detail-head {
    display: flex;
    align-items: center;
    & > * {
      flex: 0 1 auto;
    }

    &-title {
      width: 650px;
    }
    &-btn {
      width: calc(100% - 710px);
      display: flex;
      justify-content: right;
    }
  }

  &-content01 {
    display: flex;
    align-items: flex-start;
    & > * {
      flex: 1 1 auto;
    }
    .content01 {
      &-info {
        overflow: hidden;
      }
      &-row {
        & + .content01-row {
          margin-top: 20px;
          padding-top: 20px;
          border-top: solid 1px @clear-black02;
        }
      }
      &-col {
        border-left: solid 1px @clear-black02;
      }
      &-label {
        color: @clear-black03;
        font-size: 10px;
      }
      &-data {
        margin-bottom: 0;
        &.bold {
          font-weight: bold;
        }
      }
      &-upload {
        width: 100%;
        &-head {
          width: 1px;
          padding-right: 5px;
          white-space: nowrap;
          font-weight: bold;
        }
      }
    }
  }
  &-content02 {
    padding: 20px;
    margin-top: 40px;
    border: solid 1px @clear-black02;
    .content02 {
      &-head {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: solid 1px @clear-black02;
        &-title {
          font-size: 14px;
          font-weight: bold;
          margin: 0 auto 0 0;
        }
      }
      &-deliver-savebtn,
      &-contact-savebtn {
        margin-left: 10px;
      }
      &-table {
        width: 100%;
      }
      &-cell {
        padding: 20px;
        vertical-align: top;
        &inner {
          padding-left: 20px;
          border-left: solid 1px @clear-black02;
          &.nowrap {
            white-space: nowrap;
          }
        }
        &.head {
          width: 120px;
          padding: 20px 0;
        }
        &.data {
          border-bottom: solid 1px @clear-black02;
          &:first-of-type {
            .content02-cellinner {
              padding-left: 0;
              border-left: none;
            }
          }
        }
      }
      &-label {
        color: @clear-black03;
        font-size: 10px;
      }
      &-data {
        margin: 0;
        & + .content02-data {
          margin-top: 10px;
        }
      }
    }
  }

  .summary {
    /deep/ .ant-card {
      margin-top: 30px;
      padding: 0 20px 20px;
      border: solid 1px @clear-black02;
      &-head {
        padding: 0;
        &-title {
          font-size: 14px;
          font-weight: bold;
        }
      }
      &-body {
        padding: 20px 0 0;
      }
    }

    &-table {
      width: 100%;
    }
    &-head,
    &-data {
      vertical-align: baseline;
      padding: 16px 0;
    }
    &-head {
      width: 1px;
      padding-right: 60px;
      white-space: nowrap;
      &-data {
        font-weight: normal;
      }
    }
    &-data {
      padding-left: 20px;
      border-bottom: solid 1px @clear-black02;
      & > * {
        margin-bottom: 10px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
.currency-attention {
  color: @red01;
}
.copy-warning {
  margin-bottom: 10px;
}
</style>
<template>
  <div class="orderdetail" v-if="order">
    <div class="detail-head">
      <a-button class="detail-head-back">
        <router-link to="/orders">
          <LeftOutlined />
        </router-link>
      </a-button>
      <div class="detail-head-title">
        <div v-if="order.parent">
          <router-link
            :to="{ name: 'OrderDetail', params: { id: order.parent.id } }"
            >{{ order.parent.name }}</router-link
          >
          >
        </div>
        <h2 class="detail-head-name" v-if="order">{{ order.product.name }}</h2>
      </div>
      <div class="detail-head-btn" v-if="order">
        <a-space>
          <a-button
            v-if="
              order.applicationStatus !== 'approved' &&
              order.applicationStatus !== 'canceled' &&
              !order.parent
            "
            size="large"
            @click="handleCancel"
            >取り消し</a-button
          >
          <div v-if="order.applicationStatus === 'rejected'">
            <a-button
              v-if="!order.parent"
              size="large"
              type="primary"
              @click="handleReorder"
              >再注文</a-button
            >
            <router-link
              v-else
              :to="{ name: 'OrderDetail', params: { id: order.parent.id } }"
              ><a-button size="large" type="primary"
                >親注文に戻って再注文</a-button
              >
            </router-link>
          </div>
          <a-button size="large" @click="handleCopyOrder" v-if="!order.parent"
            >この内容でもう一度注文</a-button
          >
          <a-button size="large" @click="handleExportQuotation"
            >見積書を発行</a-button
          >
          <a-button
            v-if="order.orderNo === null"
            size="large"
            type="primary"
            @click="handleUploaded"
            :disabled="!order.uploaded"
          >
            入稿完了
          </a-button>
        </a-space>
      </div>
    </div>

    <div class="orderdetail-content01" v-if="order">
      <template v-if="order.product.thumbnailPath">
        <ImagePreview :preview="order.product" />
      </template>
      <template v-else>
        <ImagePreview :fallback="true" />
      </template>
      <div class="content01-info">
        <a-row class="content01-row" :gutter="[32, 16]">
          <a-col class="content01-col">
            <p class="content01-label">注文番号</p>
            <p class="content01-data">{{ order.orderNo }}</p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">商品名</p>
            <p class="content01-data">
              {{ order.product.name }}
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">注文者</p>
            <p class="content01-data">
              {{ order.clientUser.name }}
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">注文状況</p>
            <p class="content01-data">
              {{
                order.orderStatus != null ? order.orderStatus.name : "承認待ち"
              }}
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">承認状況</p>
            <p class="content01-data">
              {{
                $t(`enums.application_status_type.${order.applicationStatus}`)
              }}
            </p>
          </a-col>
          <a-col
            class="content01-col"
            v-if="order.orderProduct.productType == 'draft'"
          >
            <p class="content01-label">入稿状況</p>
            <p class="content01-data">
              <template v-if="order.uploaded">
                <span class="bold">入稿済み</span>
              </template>
              <template v-else>
                <span class="bold">未入稿</span>
              </template>
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">配送状況</p>
            <p class="content01-data">
              <template v-if="order.delivered">
                <span class="bold">配送済み</span>
              </template>
              <template v-else>
                <span class="bold">未配送</span>
              </template>
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">注文日</p>
            <p class="content01-data">{{ formatTimestamp(order.orderedAt) }}</p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">出荷日</p>
            <p class="content01-data">
              {{ formatDate(order.deliveryAt) }}
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">スタッフグループ</p>
            <p class="content01-data">
              {{ order.clientUserGroup.name }}
            </p>
          </a-col>
        </a-row>
        <a-row class="content01-row" :gutter="[32, 16]">
          <a-col class="content01-col">
            <p class="content01-label">部数</p>
            <p class="content01-data bold">
              <Number :value="order.orderProduct.quantity"></Number>部
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">金額</p>
            <p
              class="content01-data bold"
              :class="
                currencyStyle(
                  order.orderProduct.price,
                  order.orderProduct.adjustedPrice
                )
              "
            >
              <Currency :value="order.orderProduct.adjustedPrice"></Currency>
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">送料</p>
            <p
              class="content01-data bold"
              :class="
                currencyStyle(order.shippingFee, order.adjustedShippingFee)
              "
            >
              <Currency :value="order.adjustedShippingFee"></Currency>
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">小計</p>
            <p class="content01-data bold">
              <Currency :value="order.amount"></Currency>
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">消費税</p>
            <p class="content01-data bold">
              <Currency :value="order.tax"></Currency>
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-label">合計金額</p>
            <p class="content01-data bold">
              <Currency :value="order.totalAmount"></Currency>
            </p>
          </a-col>
          <a-col class="content01-col">
            <p class="content01-data">
              <TypesettingButton
                v-if="order.orderProduct.productType == 'typesetting_tag'"
                :typesettingParamId="order.orderProduct.typesettingParam.id"
                :previewOnly="true"
                @change="handleChange"
              ></TypesettingButton>
              <OuterCartonTypesettingButton
                v-if="order.orderProduct.productType == 'outer_carton_label'"
                :outerCartonParamId="order.orderProduct.outerCartonParam.id"
                :previewOnly="true"
                @change="handleChange"
              ></OuterCartonTypesettingButton>
              <InnerCartonParamPreviewButton
                v-if="order.orderProduct.productType == 'inner_carton_label'"
                :innerCartonParam="order.orderProduct.innerCartonParam"
              ></InnerCartonParamPreviewButton>
              <PriceLabelParamPreviewButton
                v-if="order.orderProduct.productType == 'price_label'"
                :priceLabelParam="order.orderProduct.priceLabelParam"
              ></PriceLabelParamPreviewButton>
              <UploadButton
                v-if="order.orderProduct.productType == 'draft'"
                :order="order"
                @change="handleChange"
              ></UploadButton>
            </p>
          </a-col>
        </a-row>
        <a-row
          class="content01-row"
          :gutter="[32, 16]"
          v-if="order.orderProduct.files.length"
        >
          <a-col class="content01-col">
            <table class="content01-upload">
              <th class="content01-upload-head">入稿データ：</th>
              <td class="content01-upload-data">
                <UploadFileList :files="order.orderProduct.files" />
              </td>
            </table>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="orderdetail-content02">
      <div class="content02-head">
        <h3 class="content02-head-title">注文内容</h3>
        <!-- <a-button class="content02-deliver-savebtn" disabled size="large"
          >配送先をマスターに保存</a-button
        >
        <a-button class="content02-contact-savebtn" disabled size="large"
          >連絡先をマスターに保存</a-button
        > -->
      </div>
      <table class="content02-table" v-if="order">
        <tr class="content02-row">
          <th class="content02-cell head">
            <p class="content02-title">製品名設定</p>
          </th>
          <td class="content02-cell data" colspan="2">
            <div class="content02-cellinner">
              <p class="content02-data">
                {{ order.orderProduct.name }}
              </p>
            </div>
          </td>
        </tr>
        <template
          v-for="orderDelivery in order.orderDeliveries"
          :key="orderDelivery.id"
        >
          <tr class="content02-row">
            <th class="content02-cell head">
              <p class="content02-title">注文配送先</p>
            </th>
            <td class="content02-cell data">
              <div class="content02-cellinner">
                <p class="content02-data">
                  {{ orderDelivery.name }}
                  {{ orderDelivery.address }}
                  TEL : {{ orderDelivery.tel }}
                </p>
                <p class="content02-data" v-if="orderDelivery.shipper">
                  荷主：
                  {{ orderDelivery.shipper.name }}
                  {{ orderDelivery.shipper.address }}
                  TEL : {{ orderDelivery.shipper.tel }}
                </p>
              </div>
            </td>
            <td class="content02-cell data">
              <div class="content02-cellinner nowrap">
                <p class="content02-label">部数</p>
                <p class="content02-data">
                  <Number :value="orderDelivery.quantity"></Number>部
                </p>
              </div>
            </td>
          </tr>
        </template>
      </table>
      <table class="content02-table" v-if="order">
        <tr
          class="content02-row"
          v-for="contact in order.orderContacts"
          :key="contact.id"
        >
          <th class="content02-cell head">
            <p class="content02-title">注文連絡先</p>
          </th>
          <td class="content02-cell data" colspan="2">
            <div class="content02-cellinner">
              <p class="content02-data">
                {{ contact.name }}
                {{ contact.address }}
              </p>
              <p class="content02-data">
                TEL : {{ contact.tel }} FAX : {{ contact.fax }}
              </p>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="summary" v-if="order.children.length">
      <a-card title="注文合計" bordered>
        <table class="summary-table">
          <tr class="summary-row">
            <th class="summary-head">
              <p class="summary-head-title">この注文</p>
            </th>
            <td class="summary-data">
              <p class="summary-person">
                {{ order.orderProduct.name }}
              </p>
            </td>
            <td class="summary-data">
              <p class="summary-person">
                <Currency :value="order.totalAmount"></Currency>
              </p>
            </td>
          </tr>
          <tr
            class="summary-row"
            v-for="childOrder in order.children"
            :key="childOrder.id"
          >
            <th class="summary-head">
              <p class="summary-head-title">子注文</p>
            </th>
            <td class="summary-data">
              <p class="summary-person">
                <router-link
                  :to="{ name: 'OrderDetail', params: { id: childOrder.id } }"
                >
                  {{ childOrder.orderProduct.name }}
                </router-link>
              </p>
            </td>
            <td class="summary-data">
              <p
                class="summary-person"
                :class="currencyStyleByObject(childOrder)"
              >
                <Currency :value="childOrder.totalAmount"></Currency>
              </p>
            </td>
          </tr>
          <tr class="summary-row">
            <th class="summary-head">
              <p class="summary-head-title">合計</p>
            </th>
            <td class="summary-data"></td>
            <td class="summary-data">
              <p class="summary-person">
                <Currency :value="order.setAmount"></Currency>
              </p>
            </td>
          </tr>
        </table>
      </a-card>
    </div>
    <!-- <a-modal v-model:visible="visibleCopyModal" @ok="handleCopy" :title="adjustedColumnName !== null ? '確認' : null"> -->
    <a-modal
      v-model:visible="visibleCopyModal"
      @ok="handleCopy"
      title="再注文確認"
    >
      <!-- <div v-if="adjustedColumnName !== null" class="copy-warning">
        この注文は、{{ adjustedColumnName }}が調整されていますが、再注文時は調整前の{{ adjustedColumnName.replace("子注文の", "") }}に戻ります。 -->
      <div class="copy-warning">
        <p>
          注文後に金額調整があった場合、再注文時の金額は調整前のものになります。また、価格改訂があった場合は、最新の価格で再計算された金額になります。
        </p>
      </div>
      <div>再注文しますか？</div>
      <a-alert
        v-if="errorMessages"
        type="error"
        message="注文できませんでした。"
      >
        <template #description>
          <div v-for="(error, index) in errorMessages" :key="index">
            {{ error }}
          </div>
        </template>
      </a-alert>
    </a-modal>
  </div>
</template>
<script lang="ts">
import { defineComponent, unref, reactive, toRefs } from "vue";
import {
  useGetOneOrderQuery,
  useCancelOrderMutation,
  useReorderOrderMutation,
  useCopyOneOrderMutation,
  useUpdateOneOrderMutation,
} from "@/client/modules/api";
import { GetOneOrderQuery, Order } from "@/client/schema";
import { useResult } from "@vue/apollo-composable";
import {
  formatTimestamp,
  formatDate,
  Currency,
  Number,
  parseError,
} from "@/generic";
import { useRouter } from "vue-router";
import { LeftOutlined } from "@ant-design/icons-vue";
import UploadButton from "./UploadButton.vue";
import TypesettingButton from "./TypesettingButton.vue";
import OuterCartonTypesettingButton from "./OuterCartonTypesettingButton.vue";
import InnerCartonParamPreviewButton from "./InnerCartonParamPreviewButton.vue";
import PriceLabelParamPreviewButton from "./PriceLabelParamPreviewButton.vue";
import ImagePreview from "@/shared/components/ImagePreview.vue";
import UploadFileList from "@/shared/components/UploadFileList.vue";

import { message } from "ant-design-vue";
import { getAccessToken } from "@/shared/providors/session";

export type Props = {
  orderId: string;
};

export default defineComponent({
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  components: {
    UploadButton,
    TypesettingButton,
    OuterCartonTypesettingButton,
    InnerCartonParamPreviewButton,
    PriceLabelParamPreviewButton,
    Currency,
    Number,
    LeftOutlined,
    UploadFileList,
    ImagePreview,
  },
  setup(props: Props) {
    const router = useRouter();

    const { result, loading, refetch } = useGetOneOrderQuery(
      {
        id: props.orderId,
      },
      {
        fetchPolicy: "network-only",
      }
    );

    const order = useResult<GetOneOrderQuery, "order">(result);

    const useCancelOrder = () => {
      const { mutate: cancel } = useCancelOrderMutation({});
      const handleCancel = async () => {
        await cancel({
          input: {
            id: props.orderId,
          },
        });
      };
      return {
        handleCancel,
      };
    };

    const useReorderOrder = () => {
      const state = reactive({
        errorMessages: null as string[] | null,
      });

      const { mutate: reorder, error } = useReorderOrderMutation({});

      const handleReorder = async () => {
        try {
          state.errorMessages = null;
          await reorder({
            input: {
              id: props.orderId,
            },
          });

          router.push({
            name: "OrderEdit",
            params: {
              id: props.orderId,
            },
          });
        } catch (e) {
          if (error.value == null) throw e;
          state.errorMessages = parseError(error.value);
        }
      };
      return {
        handleReorder,
      };
    };

    const useCopyOrder = () => {
      const state = reactive({
        visibleCopyModal: false,
        errorMessages: null as string[] | null,
      });

      const handleCopyOrder = () => {
        state.visibleCopyModal = true;
        state.errorMessages = null;
      };

      const { mutate: copyOne, error } = useCopyOneOrderMutation({});

      const handleCopy = async () => {
        try {
          const { data } = await copyOne({
            input: {
              id: props.orderId,
            },
          });
          const newOrderId = data?.copyOneOrder?.order?.id as string;

          state.visibleCopyModal = false;
          router.push({
            name: "OrderEdit",
            params: {
              id: newOrderId,
            },
          });
        } catch (e) {
          if (error.value == null) throw e;
          state.errorMessages = parseError(error.value);
        }
      };

      return {
        ...toRefs(state),
        handleCopyOrder,
        handleCopy,
      };
    };

    const handleChange = async () => {
      await refetch();
    };

    const useUpdateOrder = () => {
      const { mutate: updateOneOrder } = useUpdateOneOrderMutation({});

      const handleUploaded = async () => {
        await updateOneOrder({
          input: {
            id: props.orderId,
            draft: false,
          },
        });
        await refetch();
      };

      return {
        handleUploaded,
      };
    };

    // const adjustedColumnName = computed(() => {
    //   const safeOrder = unref(order);
    //   if (safeOrder == null) return null;

    //   if (safeOrder.orderProduct.price !== safeOrder.orderProduct.adjustedPrice && safeOrder.shippingFee !== safeOrder.adjustedShippingFee) {
    //     return "価格と送料";
    //   } else if (safeOrder.orderProduct.price !== safeOrder.orderProduct.adjustedPrice) {
    //     return "価格";
    //   } else if (safeOrder.shippingFee !== safeOrder.adjustedShippingFee) {
    //     return "送料";
    //   } else {
    //     if (safeOrder.children.some((child) => child.orderProduct.price !== child.orderProduct.adjustedPrice || child.shippingFee !== child.adjustedShippingFee)){
    //       return "子注文の金額"
    //     } else {
    //       return null;
    //     }
    //   };
    // });

    const currencyStyle = (price: number, adjustedPrice: number) => {
      if (adjustedPrice !== price) {
        return "currency-attention";
      }
    };

    const currencyStyleByObject = (order: Order) => {
      if (
        order.orderProduct.price !== order.orderProduct.adjustedPrice ||
        order.shippingFee !== order.adjustedShippingFee
      ) {
        return "currency-attention";
      }
    };

    const handleExportQuotation = async () => {
      try {
        const exportEndpoint = `/api/app/reports/quotation/${props.orderId}.pdf`;
        const accessToken = getAccessToken("client");
        await fetch(exportEndpoint, {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const anchor = document.createElement("a");
            anchor.href = window.URL.createObjectURL(blob);
            const orderSafe = unref(order) as Order;
            const orderName = orderSafe?.name;
            const date = new Date();
            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            const hour = ("0" + date.getHours()).slice(-2);
            const minute = ("0" + date.getMinutes()).slice(-2);
            const dateTime = `${year}${month}${day}_${hour}${minute}`;
            const fileName = `見積書_${orderName}_${dateTime}.pdf`;
            anchor.download = fileName;
            anchor.click();
          });
        message.success("ダウンロードしました。");
      } catch (err) {
        console.log("err", err);
        message.error("エラーが発生し、ダウンロードできませんでした。");
        Object.keys(err).forEach((key) => {
          message.error(err[key]);
        });
      }
    };

    return {
      order,
      loading,
      ...useCancelOrder(),
      ...useReorderOrder(),
      ...useCopyOrder(),
      ...useUpdateOrder(),
      formatTimestamp,
      formatDate,
      handleChange,
      // adjustedColumnName,
      currencyStyle,
      currencyStyleByObject,
      handleExportQuotation,
    };
  },
});
</script>
