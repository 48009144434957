<template>
  <a-config-provider :locale="locale">
    <StatusChecker>
      <template #relogin>
        <Relogin @clearAuthToken="clearAuthToken" />
      </template>
      <router-view></router-view>
    </StatusChecker>
  </a-config-provider>
</template>

<script lang="ts">
import { defineComponent, provide } from "vue";
import StatusChecker from "@/shared/components/StatusChecker.vue";
import Relogin from "@/shared/components/Relogin.vue";
import { clearAuthToken } from "@/client/store/session";
// import { usePwa, UsePwaKey } from "@/modules/pwa";
// import { useConfig, UseUIConfigKey } from "@/modules/ui";
import { useSession, UseSessionKey } from "@/client/modules/session";

// 型情報がない為のrequireで読み込みます。
// eslint-disable-next-line @typescript-eslint/no-var-requires
const jaJP = require("ant-design-vue/lib/locale-provider/ja_JP")?.default;

import moment from "moment";
import "moment/locale/ja";
moment.locale("ja");

export default defineComponent({
  components: {
    StatusChecker,
    Relogin,
  },
  setup() {
    provide(UseSessionKey, useSession());
    return {
      locale: jaJP,
      clearAuthToken,
    };
  },
});
</script>
