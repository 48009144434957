<style lang="less" scoped>
@import "../../styles/variable.less";
.searchtool {
  margin-bottom: 20px;
  &-options {
    background: @white03;
    padding: 20px;
    border: solid 1px @clear-black02;
  }
  &-title {
    font-weight: bold;
    margin-bottom: 0;
  }
  &-block {
    margin-top: 20px;
    overflow: hidden;
    &.divide {
      padding-top: 20px;
      border-top: solid 1px @clear-black02;
    }
    &.align-right {
      text-align: right;
    }
    .block {
      &-title {
        font-weight: bold;
      }
      &-row {
        & + .block-row {
          margin-top: 20px;
        }
      }
      &-col {
        & + .block-col {
          border-left: solid 1px @clear-black02;
        }
        &.dflex {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          & > * {
            flex: 1 1 100%;
            max-width: 100%;
          }
        }
      }
      &-label {
        margin-bottom: 0.4em;
      }
      &-output {
        text-align: right;
        font-weight: bold;
        margin-bottom: 0;
      }
      &-resetbtn {
        margin-left: 10px;
      }
    }
  }
}
</style>
<template>
  <div class="searchtool">
    <div class="searchtool-options">
      <p class="searchtool-title">検索ツール</p>
      <div class="searchtool-block divide">
        <p class="block-title">情報指定による検索</p>
        <a-row class="block-row" :gutter="[32, 16]">
          <a-col class="block-col" :span="6">
            <p class="block-label">担当者名</p>
            <ClinetUserSelect v-model:value="clientUserId" />
          </a-col>
          <a-col class="block-col" :span="6">
            <p class="block-label">注文日</p>
            <RangePicker
              class="ant-picker-cell-inner"
              v-model:value="orderedDates"
            />
          </a-col>
          <a-col class="block-col" :span="6">
            <p class="block-label">注文番号</p>
            <a-input v-model:value="orderNo" placeholder="入力してください" />
          </a-col>

          <a-col class="block-col" :span="18">
            <p class="block-label">商品名</p>
            <a-input
              v-model:value="productName"
              placeholder="入力してください"
            />
          </a-col>
          <!-- <a-col class="block-col" :span="6">
            <p class="block-label">入稿済み</p>
          </a-col> -->
          <!-- <a-col class="block-col" :span="6">
            <p class="block-label">承認状況</p>
          </a-col> -->
          <a-col class="block-col" :span="6">
            <p class="block-label">注文状況</p>
            <a-select
              v-model:value="orderStatusId"
              :allowClear="true"
              style="width: 100%"
              placeholder="選択してください"
            >
              <template v-for="status in orderStatuses" :key="status.id">
                <a-select-option :value="status.id">{{
                  status.name
                }}</a-select-option>
              </template>
            </a-select>
          </a-col>
          <!-- <a-col class="block-col" :span="6">
            <p class="block-label">配送状況</p>
          </a-col> -->
          <a-col class="block-col" :span="6">
            <p class="block-label">カテゴリ</p>
            <a-select
              v-model:value="productCategoryId"
              :allowClear="true"
              style="width: 100%"
              placeholder="選択してください"
            >
              <template
                v-for="category in productCategories"
                :key="category.id"
              >
                <a-select-option :value="category.id">{{
                  category.name
                }}</a-select-option>
              </template>
            </a-select>
          </a-col>
        </a-row>
      </div>
      <div class="searchtool-block">
        <p class="block-title">タグ</p>
        <a-checkbox-group
          v-model:value="productTagIds"
          class="block-check"
          :options="productTagOptions"
        />
      </div>
      <div class="searchtool-block divide align-right">
        <a-button
          class="block-searchbtn"
          size="large"
          type="primary"
          @click="handleSearch"
        >
          <SearchOutlined />検索
        </a-button>
        <a-button class="block-resetbtn" size="large" @click="handleClear"
          >検索条件リセット</a-button
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Moment } from "moment";
import { computed, defineComponent, reactive, SetupContext, toRefs } from "vue";
import {
  useGetManyProductCategoryQuery,
  useGetManyProductTagQuery,
  useGetManyOrderStatusQuery,
} from "@/client/modules/api";

import RangePicker from "@/admin/components/shared/RangePicker.vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import { useResult } from "@vue/apollo-composable";
import { GetManyProductTagQuery } from "@/client//schema";
import ClinetUserSelect from "@/client/components/shared/ClientUserSelect.vue";

export type Search = {
  clientUserId: string | null;
  orderedFrom: string | null;
  orderedTo: string | null;
  orderNo: string | null;
  productName: string | null;
  orderStatusId: string | null;
  productCategoryId: string | null;
  productTagIds: string[];
};

export default defineComponent({
  components: {
    RangePicker,
    SearchOutlined,
    ClinetUserSelect,
  },
  emits: ["search"],
  setup(_, { emit }: SetupContext) {
    const useProductTags = () => {
      const { result } = useGetManyProductTagQuery({});

      const productTags =
        useResult<GetManyProductTagQuery, "productTags">(result);
      const productTagOptions = computed(
        () =>
          productTags.value?.map((tag) => ({
            label: tag.name,
            value: tag.id,
          })) || []
      );

      return {
        productTagOptions,
      };
    };

    const useOrderStatusType = () => {
      const { result } = useGetManyOrderStatusQuery({});

      const orderStatuses = useResult(result);
      return {
        orderStatuses,
      };
    };

    const useProductCategories = () => {
      const { result } = useGetManyProductCategoryQuery({});

      const productCategories = useResult(result);
      return {
        productCategories,
      };
    };

    const state = reactive({
      clientUserId: null as string | null,
      orderedDates: [] as Moment[],
      orderNo: null as string | null,
      productName: null as string | null,
      orderStatusId: null as string | null,
      productCategoryId: null as string | null,
      productTagIds: [] as string[],
    });

    const handleSearch = () => {
      const search = {
        clientUserId: state.clientUserId,
        orderedFrom: state.orderedDates[0]?.format("YYYY-MM-DD"),
        orderedTo: state.orderedDates[1]?.format("YYYY-MM-DD"),
        orderNo: state.orderNo,
        productName: state.productName,
        orderStatusId: state.orderStatusId,
        productCategoryId: state.productCategoryId,
        productTagIds: state.productTagIds,
      } as Search;
      emit("search", search);
    };

    const handleClear = () => {
      state.clientUserId = null;
      state.orderedDates = [];
      state.orderNo = null;
      state.productName = null;
      state.orderStatusId = null;
      state.productCategoryId = null;
      state.productTagIds = [];
      emit("search", {});
    };

    return {
      ...toRefs(state),
      ...useProductCategories(),
      ...useProductTags(),
      ...useOrderStatusType(),
      handleSearch,
      handleClear,
    };
  },
});
</script>
