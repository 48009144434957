
import { defineComponent, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import Logo from "@/shared/components/Logo.vue";
import {
  CreatePasswordInput,
  useCreatePassword,
} from "@/client/modules/password";

export default defineComponent({
  components: {
    Logo,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      input: {
        email: null,
      } as CreatePasswordInput,
      errorMessage: null as string | null,
    });

    const { create, loading } = useCreatePassword();

    const handleSubmit = async () => {
      await create(state.input);
      router.push({ name: "PasswordUpdate" });
    };

    return {
      ...toRefs(state),
      loading,
      handleSubmit,
    };
  },
});
