
import { defineComponent, reactive, toRefs } from "vue";
import {
  useGetManyClientApplicationFlowQuery,
  useCreateOneClientApplicationFlowMutation,
  useRemoveOneClientApplicationFlowMutation,
} from "@/client/modules/api";
import { useResult } from "@vue/apollo-composable";
import { ClientApplicationFlow } from "../../../../admin/schema";
import { useRouter } from "vue-router";
// import Dragable from "@/generic/components/etc/Dragable.vue";
// import { MoreOutlined } from "@ant-design/icons-vue";
import {
  Currency,
  // clone,
  // Resource,
} from "@/generic";

export type Props = {
  clientUserGroupId: string;
};

export default defineComponent({
  components: {
    // MoreOutlined,
    // Dragable,
    Currency,
  },
  props: {
    clientUserGroupId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const router = useRouter();

    const { result, loading, refetch } = useGetManyClientApplicationFlowQuery({
      clientUserGroupId: props.clientUserGroupId,
    });

    const flows = useResult(result);

    const columns = [
      // {
      //   title: "",
      //   key: "dragHandle",
      //   width: 40,
      //   slots: {
      //     customRender: "dragHandle"
      //   },
      // },
      {
        title: "経路名",
        key: "name",
        slots: {
          customRender: "name",
        },
      },
      {
        title: "経路条件金額",
        key: "condPrice",
        slots: {
          customRender: "condPrice",
        },
      },
      {
        title: "承認フロー",
        key: "levels",
        slots: {
          customRender: "levels",
        },
      },
      {
        title: "アクション",
        key: "actions",
        fixed: "right",
        slots: {
          customRender: "actions",
        },
      },
      // {
      //   title:"デフォルト",
      //   key: "default",
      //   dataindex: "default",
      //   slots: {
      //     customRender: "default",
      //   },
      // }
    ];

    const handleEdit = (clientApplicationFlow: ClientApplicationFlow) => {
      router.push({
        name: "SettingsFlowEdit",
        params: {
          id: clientApplicationFlow.id,
          clientUserGroupId: props.clientUserGroupId,
        },
      });
    };

    const useCreateApplicationFlow = () => {
      const state = reactive({
        visibleNewModal: false,
        newFlow: {} as { name: string; default: boolean },
      });

      const { mutate } = useCreateOneClientApplicationFlowMutation({});

      const handleNew = async () => {
        state.newFlow = {
          name: "",
          default: false,
        };
        state.visibleNewModal = true;
      };

      const handleCreate = async () => {
        try {
          const result = await mutate({
            input: {
              clientUserGroupId: props.clientUserGroupId,
              name: state.newFlow.name,
              default: state.newFlow.default,
            },
          });
          await refetch();
          state.visibleNewModal = false;

          const clientApplicationFlowId =
            result?.data?.createOneClientApplicationFlow?.clientApplicationFlow
              ?.id;
          if (clientApplicationFlowId) {
            router.push({
              name: "SettingsFlowEdit",
              params: {
                id: clientApplicationFlowId,
                clientUserGroupId: props.clientUserGroupId,
              },
            });
          }
        } catch (e) {
          console.log(e);
        }
      };

      return {
        ...toRefs(state),
        handleCreate,
        handleNew,
      };
    };

    const useRemoveApplicationFlow = () => {
      const { mutate } = useRemoveOneClientApplicationFlowMutation({});

      const handleRemove = async (
        clientApplicationFlow: ClientApplicationFlow
      ) => {
        try {
          await mutate({
            input: {
              id: clientApplicationFlow.id,
            },
          });
          await refetch();
        } catch (e) {
          console.log(e);
        }
      };

      return {
        handleRemove,
      };
    };

    // const handleDragged = async (
    //   draggingItem: any,
    //   draggedItem: any,
    //   dragedUpper: boolean,
    //   dataSource: Resource[]
    // ) => {
    //   const draggingItemIdx = dataSource.indexOf(draggingItem);
    //   const draggedItemIdx = dataSource.indexOf(draggedItem);

    //   const target = clone(draggingItem);

    //   if (dragedUpper) {
    //     if (draggingItemIdx + 1 === draggedItemIdx) {
    //       return;
    //     }
    //     target[props.orderAttr] = draggedItemIdx;
    //   } else {
    //     if (draggingItemIdx > draggedItemIdx) {
    //       target[props.orderAttr] = draggedItemIdx + 1;
    //     } else {
    //       target[props.orderAttr] = draggedItemIdx;
    //     }
    //   }

    //   await update(target);
    // };

    return {
      flows,
      loading,
      columns,
      handleEdit,
      ...useCreateApplicationFlow(),
      ...useRemoveApplicationFlow(),
    };
  },
});
