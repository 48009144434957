import { AuthToken } from "@/client/schema";

const prefix = "client.";

let inited = false;
let loggined = false;

export const onInited = (): void => {
  inited = true;
};
export const onLoggined = () => {
  inited = true;
  loggined = true;
};
export const offInited = (): void => {
  inited = false;
  loggined = false;
};
export const offLoggined = () => {
  loggined = false;
};
export const isInited = () => inited;
export const isLoggined = () => loggined;

// const storeCompanyName = (companyName: string | null) => {
//   if (companyName != null) {
//     localStorage.setItem("companyname", companyName);
//   }
// }

// const restoreCompanyName = () => localStorage.getItem("companyname")

export const setAuthToken = (authToken: AuthToken) => {
  localStorage.setItem(prefix + "authTokenId", authToken.id);
  localStorage.setItem(prefix + "accessToken", authToken.accessToken);
  localStorage.setItem(prefix + "refreshToken", authToken.refreshToken);
  localStorage.setItem(
    prefix + "refreshTokenExpiredAt",
    authToken.refreshTokenExpiredAt
  );
  onLoggined();
};

export const getAuthToken = (): AuthToken => {
  return {
    id: localStorage.getItem(prefix + "authTokenId") as string,
    accessToken: localStorage.getItem(prefix + "accessToken") as string,
    refreshToken: localStorage.getItem(prefix + "refreshToken") as string,
    refreshTokenExpiredAt: localStorage.getItem(
      prefix + "refreshTokenExpiredAt"
    ) as string,
  };
};

export const clearAuthToken = () => {
  localStorage.removeItem(prefix + "accessToken");
  localStorage.removeItem(prefix + "refreshToken");
  localStorage.removeItem(prefix + "refreshToken");
  localStorage.removeItem(prefix + "refreshTokenExpiredAt");
  offLoggined();
};
