
import { defineComponent, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { UseSessionKey, UseSessionStore } from "@/client/modules/session";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();

    const { restore } = inject(UseSessionKey) as UseSessionStore;
    restore().then((success) => {
      // 初期化失敗の場合は、ログイン画面ヘ
      if (!success) {
        router.push({ name: "SessionCreate" });
        return {};
      }

      // 成功時の画面遷移
      const redirect = route.query["redirect"] as string | null;
      if (redirect == null) {
        router.push({ name: "Home" });
      } else {
        router.push({ path: redirect });
      }
    });
    return {};
  },
});
