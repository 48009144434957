import { RouteRecordRaw } from "vue-router";
import ClientUserList from "@/client/views/client/user/List.vue";
import ClientUserGroupList from "@/client/views/client/userGroup/List.vue";
import ClientUserGroupDetail from "@/client/views/client/userGroup/Detail.vue";

import ClientStorageFileList from "@/client/views/client/storageFile/List.vue";
import ClientStorageFileDetail from "@/client/views/client/storageFile/Detail.vue";
import FlowEdit from "@/client/views/flow/flow/Edit.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/settings/users",
    name: "SettingsClientUserList",
    component: ClientUserList,
    props: true,
  },
  {
    path: "/settings/userGroups",
    name: "SettingsClientUserGroupList",
    component: ClientUserGroupList,
    props: true,
  },
  {
    path: "/settings/userGroups/:id/:tab?",
    name: "SettingsClientUserGroupDetail",
    component: ClientUserGroupDetail,
    props: true,
  },
  {
    path: "/settings/storage",
    name: "SettingsClientStorageFileList",
    component: ClientStorageFileList,
    props: true,
  },
  {
    path: "/settings/storage/:id",
    name: "SettingsClientStorageFileDetail",
    component: ClientStorageFileDetail,
    props: true,
  },
  {
    path: "/settings/flows/:id/edit",
    name: "SettingsFlowEdit",
    component: FlowEdit,
    props: true,
  },
];

export default routes;
