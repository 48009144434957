
import { defineComponent } from "vue";
import { Content } from "@/generic";
import ApplicationFlowEdit from "@/client/components/settings/applicationFlow/Edit.vue";
import ClientUserGroupNav from "@/client/components/settings/clientUserGroup/Nav.vue";
import UserName from "@/shared/components/CurrentUserName.vue";

export default defineComponent({
  components: {
    Content,
    ApplicationFlowEdit,
    ClientUserGroupNav,
    UserName,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    clientUserGroupId: {
      type: String,
      required: true,
    },
  },
});
