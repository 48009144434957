<template>
  <div>
    <Content
      class="content-head"
      :style="{
        margin: 0,
        padding: '30px 0 30px 30px',
        height: 'auto',
      }"
    >
      <h1 class="content-head-title">商品詳細</h1>
      <UserName class="content-head-username"></UserName>
    </Content>
    <div class="content-layout">
      <Content
        :style="{
          margin: 0,
          padding: '20px',
          height: 'auto',
        }"
      >
        <ProductDetail
          :clientUserGroupId="clientUserGroupId"
          :productId="productId"
        ></ProductDetail>
      </Content>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Content } from "@/generic";
import { useRoute } from "vue-router";
import ProductDetail from "@/client/components/product/Detail.vue";
import UserName from "@/shared/components/CurrentUserName.vue";

export default defineComponent({
  components: {
    Content,
    ProductDetail,
    UserName,
  },
  setup() {
    const route = useRoute();
    const productId = route.params["id"];
    const clientUserGroupId = route.params["clientUserGroupId"];
    return {
      productId,
      clientUserGroupId,
    };
  },
});
</script>
