
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    size: {
      type: String as PropType<"s" | "l">,
      required: false,
      default: "l",
    },
  },
});
