<style lang="less" scoped></style>
<template>
  <Table
    :name="name"
    :queryVariables="queryVariables"
    :createMutationVariables="createMutationVariables"
    :attrs="attrs"
    :editable="true"
    :importable="false"
    :exportable="false"
    editMode="inline"
  ></Table>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useRoute } from "vue-router";
import { Attrs, Table } from "@/generic";

export type Props = {
  clientUserGroupId: string;
};

export default defineComponent({
  components: {
    Table,
  },
  props: {
    clientUserGroupId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const route = useRoute();

    const clientUserGroupId = route.params["id"];

    const state = reactive({});

    const name = "clientUserGroupProduct";
    const queryVariables = [
      {
        key: "clientUserGroupId",
        graphQLType: "ID!",
        value: props.clientUserGroupId,
      },
    ];
    const createMutationVariables = [
      {
        key: "clientUserGroupId",
        graphQLType: "ID",
        value: clientUserGroupId,
      },
    ];

    const attrs = [
      {
        key: "viewOrder",
        titleKey: "viewOrder",
        type: "integer",
        required: false,
      },
      {
        key: "product",
        type: "model",
        typeName: "Product",
        required: true,
        createonly: true,
      },
      {
        key: "applicationFlow",
        type: "model",
        typeName: "ClientApplicationFlow",
        required: true,
        allowClear: true,
        arguments: [
          {
            key: "clientUserGroupId",
            graphQLType: "ID!",
            value: props.clientUserGroupId,
          },
        ],
      },
    ] as Attrs;

    return {
      ...toRefs(state),
      name,
      queryVariables,
      createMutationVariables,
      attrs,
    };
  },
});
</script>
