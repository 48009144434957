import { RouteRecordRaw } from "vue-router";
import ApprovalList from "@/client/views/approval/List.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/approvals",
    name: "ApprovalList",
    component: ApprovalList,
  },
];

export default routes;
