import gql from "graphql-tag";
import { computed, InjectionKey, unref } from "vue";
import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import {
  setAuthToken,
  clearAuthToken,
  isInited,
  onInited,
  getAuthToken,
} from "@/client/store/session";
import { ClientUserRole } from "@/client/schema";

export function useGetSession() {
  const queryReturn = useQuery(
    gql`
      query GetSession {
        session {
          currentUser {
            id
            name
            clientUserRole
            userGroups {
              id
              name
            }
            rootUserGroups {
              id
              name
              children {
                id
                name
              }
            }
          }
        }
      }
    `,
    null,
    {
      fetchPolicy: "network-only",
    }
  );

  const session = useResult(queryReturn.result);

  const currentUser = computed(() => unref(session)?.currentUser);
  const isManager = computed(
    () =>
      unref(currentUser)?.clientUserRole === ClientUserRole.Manager ||
      unref(currentUser)?.clientUserRole === ClientUserRole.Admin
  );
  const isStaff = computed(
    () =>
      unref(currentUser)?.clientUserRole === ClientUserRole.Staff ||
      unref(currentUser)?.clientUserRole === ClientUserRole.Manager ||
      unref(currentUser)?.clientUserRole === ClientUserRole.Admin
  );

  return {
    ...queryReturn,
    session,
    currentUser,
    isStaff,
    isManager,
  };
}

export type CreateSessionInput = {
  username: string | null;
  password: string | null;
};

export function useSession() {
  const CreateSessionReturn = useMutation(
    gql`
      mutation CreateSession($input: CreateSessionInput!) {
        createSession(input: $input) {
          authToken {
            id
            accessToken
            refreshToken
            refreshTokenExpiredAt
          }
        }
      }
    `,
    {
      refetchQueries: ["GetSession"],
    }
  );

  // ログイン
  const create = async (input: CreateSessionInput): Promise<boolean> => {
    try {
      const result = await CreateSessionReturn.mutate({
        input,
      });
      const authToken = result.data.createSession.authToken;
      //storeCompanyName(input.companyname);
      setAuthToken(authToken);
      return true;
    } catch (e) {
      console.error("session create error.");
      clearAuthToken();
      return false;
    }
  };

  const { mutate: updateSession } = useMutation(
    gql`
      mutation UpdateSession($input: UpdateSessionInput!) {
        updateSession(input: $input) {
          authToken {
            id
            accessToken
            refreshToken
            refreshTokenExpiredAt
          }
        }
      }
    `,
    {
      refetchQueries: ["GetSession"],
    }
  );

  // 初期化時のセッション更新
  const update = async (input: { refreshToken: string }): Promise<boolean> => {
    try {
      const result = await updateSession({
        input,
      });
      const authToken = result.data.updateSession.authToken;
      setAuthToken(authToken);
      return true;
    } catch (e) {
      console.error("session update error", e);
      clearAuthToken();
      return false;
    }
  };

  // ログアウト
  const remove = async () => {
    clearAuthToken();
  };

  // セッションの復帰
  const restore = async (): Promise<boolean> => {
    if (isInited()) return true;

    try {
      const authToken = getAuthToken();
      const refreshToken = authToken?.refreshToken;
      if (refreshToken == null) {
        clearAuthToken();
        return false;
      }
      await update({ refreshToken });
      onInited();
      return true;
    } catch (e) {
      console.error("error", e);
      clearAuthToken();
      return false;
    }
  };

  return {
    ...CreateSessionReturn,
    restore,
    create,
    remove,
  };
}

export type UseGetSessionStore = ReturnType<typeof useGetSession>;
export const UseGetSessionKey: InjectionKey<UseGetSessionStore> =
  Symbol("UseGetSessionStore");

export type UseSessionStore = ReturnType<typeof useSession>;
export const UseSessionKey: InjectionKey<UseSessionStore> =
  Symbol("UseSessionStore");
