import gql from "graphql-tag";
import { useMutation } from "@vue/apollo-composable";

export type CreatePasswordInput = {
  email: string | null;
};

export function useCreatePassword() {
  const CreateSessionResturn = useMutation(
    gql`
      mutation CreatePassword($input: CreatePasswordInput!) {
        createPassword(input: $input) {
          clientUser {
            id
          }
        }
      }
    `
  );

  // ログイン
  const create = async (input: CreatePasswordInput): Promise<boolean> => {
    try {
      await CreateSessionResturn.mutate({
        input,
      });
      return true;
    } catch (e) {
      console.error("password create error.");
      return false;
    }
  };

  return {
    ...CreateSessionResturn,
    create,
  };
}

export type UpdatePasswordInput = {
  passwordToken?: string;
  password?: string;
};

export function useUpdatePassword() {
  const UpdateSessionResturn = useMutation(
    gql`
      mutation UpdatePassword($input: UpdatePasswordInput!) {
        updatePassword(input: $input) {
          clientUser {
            id
          }
        }
      }
    `
  );

  // 初期化時のセッション更新
  const update = async (input: UpdatePasswordInput): Promise<boolean> => {
    try {
      await UpdateSessionResturn.mutate({
        input,
      });
      return true;
    } catch (e) {
      console.error("password update error", e);
      return false;
    }
  };

  return {
    ...UpdateSessionResturn,
    update,
  };
}
