<style lang="less" scoped></style>
<template>
  <div>
    <Content>
      <a-breadcrumb>
        <a-breadcrumb-item>お知らせ一覧</a-breadcrumb-item>
      </a-breadcrumb>
    </Content>
    <Content>
      <NotificationList></NotificationList>
    </Content>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Content } from "@/generic";
import NotificationList from "@/client/components/notification/List.vue";

export default defineComponent({
  components: {
    Content,
    NotificationList,
  },
});
</script>
