<style lang="less" scoped>
@import "../../styles/variable.less";
.order {
  &-head {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .head {
      &-title {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        &.selected {
          margin-right: auto;
        }
      }
      &-search {
        margin-left: auto;
        .anticon {
          margin-right: 5px;
        }
        &.tool-active {
          color: @gray01;
        }
      }
      &-toggle {
        margin-left: 30px;
      }
    }
  }
  &-order-save {
    text-align: right;
    .order {
      &-cancelbtn {
        margin-left: 10px;
      }
    }
  }
  &-pagination {
    text-align: right;
    margin-top: 20px;
    padding-top: 20px;
    border-top: solid 1px @clear-black02;
  }
}
.searchtool {
  &-block {
    overflow: hidden;
  }
  &-result {
    overflow: hidden;
    margin-bottom: 20px;
    .block {
      &-title {
        font-weight: bold;
      }
      &-row {
        & + .block-row {
          margin-top: 20px;
        }
      }
      &-col {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        & > * {
          flex: 1 1 100%;
          max-width: 100%;
        }
        & + .block-col {
          border-left: solid 1px @clear-black02;
        }
      }
      &-label {
        color: @clear-black06;
        margin-bottom: 0.4em;
      }
      &-output {
        text-align: right;
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  }
}
</style>
<template>
  <Content
    :style="{
      margin: 0,
      padding: '20px',
      height: 'auto',
    }"
  >
    <div class="order-head">
      <h2 class="head-title">
        <span>{{ draftOnly ? "下書き履歴" : "注文履歴" }}</span>
      </h2>
      <a-typography-link
        class="head-search"
        @click="toggleSearch"
        v-bind:class="{ 'tool-active': visibleSearchTool }"
      >
        <SearchOutlined />
        <span v-if="visibleSearchTool">検索ツールを閉じる</span>
        <span v-else>検索ツールを表示</span>
      </a-typography-link>
    </div>
    <template v-if="visibleSearchTool">
      <SearchTool @search="handleSearch"></SearchTool>
      <div class="searchtool-result">
        <a-row class="block-row" :gutter="[32, 16]">
          <a-col class="block-col">
            <p class="block-label">合計件数</p>
            <p class="block-output"><Number :value="total"></Number>件</p>
          </a-col>
          <a-col class="block-col">
            <p class="block-label">合計金額</p>
            <p class="block-output">
              <Currency :value="amount"></Currency>
            </p>
          </a-col>
          <a-col class="block-col">
            <a-button size="large" type="primary" @click="handleExport"
              >{{
                draftOnly ? "下書き" : "注文"
              }}情報をCSVでダウンロード</a-button
            >
          </a-col>
          <!-- <a-col class="block-col">
            <a-button disabled size="large" type="primary"
              >製品情報をCSVでダウンロード</a-button
            >
          </a-col> -->
        </a-row>
      </div>
    </template>
    <div v-if="orders">
      <OrderList :orders="orders" :loading="loading"></OrderList>
      <template v-if="count > pageSize">
        <a-pagination
          class="order-pagination"
          v-model:current="currentPage"
          :pageSize="pageSize"
          :total="count"
          @change="handlePageChange"
        ></a-pagination>
      </template>
    </div>
  </Content>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { default as SearchTool, Search } from "./SearchTool.vue";
import OrderList from "./OrderList.vue";
import { Content } from "@/generic";
import { SearchOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { useResult } from "@vue/apollo-composable";
import {
  useGetManyOrderQuery,
  useGetManyCountOrderQuery,
  useGetTotalOrderQuery,
  useGetAmountOrderQuery,
} from "@/client/modules/api";
import { getAccessToken } from "@/shared/providors/session";
import { Currency, Number } from "@/generic/components/display";

export type Props = {
  draftOnly: boolean;
};

export default defineComponent({
  components: {
    Content,
    SearchTool,
    OrderList,
    SearchOutlined,
    Currency,
    Number,
  },
  props: {
    draftOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props: Props) {
    const state = reactive({
      visibleSearchTool: false,
      currentPage: 1,
      pageSize: 10,
      search: {} as Search,
    });

    const { result, loading, variables } = useGetManyOrderQuery(
      {
        draftOnly: props.draftOnly,
        offset: 0,
        limit: state.pageSize,
      },
      {
        fetchPolicy: "cache-and-network",
      }
    );

    const { result: totalResult, variables: totalVariables } =
      useGetTotalOrderQuery(
        {
          draftOnly: props.draftOnly,
        },
        {
          fetchPolicy: "cache-and-network",
        }
      );

    // ページング用の件数取得
    const { result: countResult, variables: countVariables } =
      useGetManyCountOrderQuery(
        {
          draftOnly: props.draftOnly,
        },
        {
          fetchPolicy: "cache-and-network",
        }
      );

    const { result: amountResult, variables: amountVariables } =
      useGetAmountOrderQuery(
        {
          draftOnly: props.draftOnly,
        },
        {
          fetchPolicy: "cache-and-network",
        }
      );

    const orders = useResult(result);
    const total = useResult(totalResult);
    const count = useResult(countResult);
    const amount = useResult(amountResult);

    const toggleSearch = () => {
      state.visibleSearchTool = !state.visibleSearchTool;
    };

    const handleSearch = (search: Search) => {
      state.search = search;
      variables.value = {
        ...state.search,
        draftOnly: props.draftOnly,
        offset: (state.currentPage - 1) * state.pageSize,
        limit: state.pageSize,
      };
      totalVariables.value = {
        ...state.search,
        draftOnly: props.draftOnly,
      };
      countVariables.value = {
        ...state.search,
        draftOnly: props.draftOnly,
      };
      amountVariables.value = {
        ...state.search,
        draftOnly: props.draftOnly,
      };
    };

    const handlePageChange = (page: number) => {
      state.currentPage = page;
      variables.value = {
        ...state.search,
        draftOnly: props.draftOnly,
        offset: (page - 1) * state.pageSize,
        limit: state.pageSize,
      };
    };

    const handleExport = async () => {
      try {
        const variables = state.search;
        const params: { [key: string]: any } = {};
        params["draft_only"] = props.draftOnly;
        if (variables.clientUserId)
          params["client_user_id"] = variables.clientUserId;
        if (variables.orderedFrom)
          params["ordered_from"] = variables.orderedFrom;
        if (variables.orderedTo) params["ordered_to"] = variables.orderedTo;
        if (variables.orderNo) params["order_no"] = variables.orderNo;
        if (variables.productName)
          params["product_name"] = variables.productName;
        if (variables.orderStatusId)
          params["order_status_id"] = variables.orderStatusId;
        if (variables.productTagIds?.length)
          params["product_tag_ids"] = variables.productTagIds.toString();
        if (variables.productCategoryId)
          params["product_category_id"] = variables.productCategoryId;
        const query = new URLSearchParams(params);
        const exportEndpoint = `/api/app/order/export.csv?${query}`;
        const accessToken = getAccessToken("client");
        await fetch(exportEndpoint, {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const anchor = document.createElement("a");
            anchor.href = window.URL.createObjectURL(blob);
            const date = new Date();
            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            const hour = ("0" + date.getHours()).slice(-2);
            const minute = ("0" + date.getMinutes()).slice(-2);
            const dateTime = `${year}${month}${day}_${hour}${minute}`;
            const name = props.draftOnly ? "下書き" : "注文";
            const fileName = `${name}_${dateTime}.csv`;
            anchor.download = fileName;
            anchor.click();
          });
      } catch (err) {
        console.log("err", err);
        Object.keys(err).forEach((key) => {
          message.error(err[key]);
        });
      }
    };

    return {
      ...toRefs(state),
      orders,
      total,
      count,
      amount,
      loading,
      toggleSearch,
      handleSearch,
      handlePageChange,
      handleExport,
    };
  },
});
</script>
