import { loadLanguage } from "@/shared/providors/i18n";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import { authGuard } from "./guards";
import sessionRoutes from "./session";
import orderRoutes from "./order";
import approvalRoutes from "./approval";
import settingsRoutes from "./settings";
import productRoutes from "./product";
import notificationRoutes from "./notification";
import storageFileRoutes from "./storageFile";
import topRoutes from "./top";

import Home from "@/client/views/Home.vue";
import NotFound from "@/client/views/errors/NotFound.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: { name: "ProductList" },
    children: [
      ...settingsRoutes,
      ...orderRoutes,
      ...productRoutes,
      ...approvalRoutes,
      ...notificationRoutes,
      ...storageFileRoutes,
      ...topRoutes,
    ],
  },
  ...sessionRoutes,
  {
    path: "/:pathMatch(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_CLIENT_BASE_URL || "/client/"),
  routes,
});

// load i18n
router.beforeEach(async (to, from, next) => {
  await loadLanguage("ja");
  next();
});

router.beforeEach(authGuard);

export default router;
