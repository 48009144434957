
import { defineComponent, unref } from "vue";
import { message } from "ant-design-vue";
import { useUploadResource } from "@/client/components/orderProductFile/modules/uploadResource";
import { UploadOutlined } from "@ant-design/icons-vue";
import {
  useGetOneOrderQuery,
  useGetManyOrderProductFileQuery,
  useRemoveOneOrderProductFileMutation,
} from "@/client/modules/api";
import { useResult } from "@vue/apollo-composable";
import OrderProductFileImage from "@/client/components/orderProductFile/Image.vue";
import {
  OrderProductFile,
  GetOneOrderQuery,
  GetManyOrderProductFileQuery,
} from "@/client/schema";
import { assert } from "@/generic";

export type Props = {
  orderId: string;
};

export default defineComponent({
  components: {
    OrderProductFileImage,
    UploadOutlined,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const { result: orderResult, loading } = useGetOneOrderQuery({
      id: props.orderId,
    });

    const order = useResult<GetOneOrderQuery, "order">(orderResult);
    const { result: orderProductFileResult, refetch } =
      useGetManyOrderProductFileQuery({
        orderId: props.orderId,
      });

    const orderProductFiles = useResult<
      GetManyOrderProductFileQuery,
      "orderProductFiles"
    >(orderProductFileResult);

    const useUploadOrderProductFile = () => {
      const { uploadData, errorMessages: importErrorMessages } =
        useUploadResource();

      const handleUpload = async (file: File) => {
        const fileExt = file.name.split(".").pop();

        assert(order.value != null);
        uploadData(order.value.orderProduct.id, file, fileExt).then(
          async (result: boolean) => {
            // 非同期に呼び出したいのでawaitにしないこと
            if (result) {
              await refetch();
              message.success("アップロードしました。");
            } else {
              const errorMessages = unref(importErrorMessages);
              Object.keys(errorMessages).forEach((key) => {
                message.error(errorMessages[key]);
              });
            }
          }
        );

        return false;
      };

      return {
        handleUpload,
      };
    };

    const useRemoveOrderProductFile = () => {
      const { mutate } = useRemoveOneOrderProductFileMutation({});

      const handleRemove = async (orderProductFile: OrderProductFile) => {
        await mutate({
          input: { id: orderProductFile.id },
        });
        await refetch();
        message.success(`削除しました。`);
      };

      return {
        handleRemove,
      };
    };

    return {
      orderProductFiles,
      loading,
      ...useUploadOrderProductFile(),
      ...useRemoveOrderProductFile(),
    };
  },
});
