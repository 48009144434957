<style lang="less" scoped>
@import "../../../styles/variable.less";
@antt: .ant-table;
.flow{
  &-table{
    /deep/ @{antt} {
      &-fixed {
        // border-top: solid 1px @clear-black02;
      }
      @{antt}-content{
        @{antt}{
          &-thead {
            white-space: nowrap;
            & > tr {
              & > th {
                font-size: 10px;
                color: @clear-black03;
                background: @white01;
                border: none;
              }
            }
          }
          &-tbody {
            & > tr {
              & > *{
                vertical-align: baseline;
              }
              & > td {
                border-bottom: none;
              }
              &:nth-child(odd) {
                background: @white03;
                @{antt}{
                  &-cell-fix-left,
                  &-cell-fix-right{
                    background: @white03;
                  }
                }
              }
              &:hover{
                @{antt}{
                  &-cell-fix-left,
                  &-cell-fix-right{
                    background: #edf6ff;
                  }
                }
              }
            }
          }
        }
      }
    }
    .price{
      &-wrapper{
        margin-bottom: 1em;
        white-space: nowrap;
        & > *{
          display: inline-block;
        }
      }
      &-lower{
        margin: 0;
      }
    }
    .approve{
      &-route{
        margin-bottom: 1em;
        & > *{
          display: inline-block;
          margin: 0 0 0 .3em;
          &:nth-child(n+2){
            &:before{
              content: "＞";
              display: inline-block;
              color: @clear-black03;
              padding-right: .3em;
            }
          }
        }
      }
      &-or{
        color: @clear-black03;
      }
    }
  }
  &-option{
    background: @white01;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    margin-top: 20px;
    border-top: solid 1px @clear-black02;
    position: sticky;
    bottom: 0;
    z-index: 5;
  }
}
</style>
<template>
  <div>
    <a-table
      class="flow-table"
      :columns="columns"
      :data-source="flows"
      :pagination="false"
      :scroll="{ x: 'max-content' }"
    >
      <!-- <template #dragHandle>
        <dragable
          :draggable="true"
        >
          <MoreOutlined />
        </dragable>
      </template>
 -->
      <template #name="{ record }">{{ record.name }}</template>
      <template #condPrice="{ record }">
        <div class="price-wrapper" v-for="route in record.routes" :key="route">
          <p class="price-lower">
            <Currency :value="route.condLowerPrice"></Currency>〜
          </p>
        </div>
      </template>
      <template #levels="{ record }">
        <div class="approve-route" v-for="route in record.routes" :key="route">
          <p class="approve-level" v-for="level in route.levels" :key="level">
            <template v-if="level.userApprovers.length">
              <span
                class="approve-name"
                v-for="(userApprover, index) in level.userApprovers"
                :key="userApprover"
              >
                {{ userApprover.name }}
                <span
                  class="approve-or"
                  v-if="index !== level.userApprovers.length - 1"
                >
                  or
                </span>
              </span>
            </template>
            <template v-else>
              <span class="approve-name">&nbsp;</span>
            </template>
          </p>
        </div>
      </template>
      <template #actions="{ record }">
        <a-space>
          <a-button type="link" @click="handleEdit(record)">編集</a-button>
          <a-button type="link" danger @click="handleRemove(record)"
            >削除</a-button
          >
        </a-space>
      </template>
    </a-table>
    <div class="flow-option">
      <a-button
        class="add-button"
        type="primary"
        size="large"
        @click="handleNew"
        >新規経路作成</a-button
      >
    </div>

    <!-- <a-table
      :dataSource="flows"
      :columns="columns"
      :pagination="false"
    >
      <template #name="{ record }">
        <router-link :to="{ name: 'SettingsFlowDetail', params: {id: record.id} }">
          {{record.name}}
        </router-link>
      </template>
      <template #condPrice="{ record }">
        <a-tag>{{record.condLowerPrice}}</a-tag>
      </template>
       <template #levels="{ record }">
        {{record.default ? "◯" : "✕"}}
      </template>
    </a-table> -->

    <a-modal
      v-model:visible="visibleNewModal"
      title="新規作成"
      @ok="handleCreate"
    >
      <a-form v-if="newFlow != null">
        <a-form-item label="名前">
          <a-input v-model:value="newFlow.name" />
        </a-form-item>
        <a-form-item label="デフォルト">
          <a-checkbox v-model:checked="newFlow.default">デフォルト</a-checkbox>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import {
  useGetManyClientApplicationFlowQuery,
  useCreateOneClientApplicationFlowMutation,
  useRemoveOneClientApplicationFlowMutation,
} from "@/client/modules/api";
import { useResult } from "@vue/apollo-composable";
import { ClientApplicationFlow } from "../../../../admin/schema";
import { useRouter } from "vue-router";
// import Dragable from "@/generic/components/etc/Dragable.vue";
// import { MoreOutlined } from "@ant-design/icons-vue";
import {
  Currency,
  // clone,
  // Resource,
} from "@/generic";

export type Props = {
  clientUserGroupId: string;
};

export default defineComponent({
  components: {
    // MoreOutlined,
    // Dragable,
    Currency,
  },
  props: {
    clientUserGroupId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const router = useRouter();

    const { result, loading, refetch } = useGetManyClientApplicationFlowQuery({
      clientUserGroupId: props.clientUserGroupId,
    });

    const flows = useResult(result);

    const columns = [
      // {
      //   title: "",
      //   key: "dragHandle",
      //   width: 40,
      //   slots: {
      //     customRender: "dragHandle"
      //   },
      // },
      {
        title: "経路名",
        key: "name",
        slots: {
          customRender: "name",
        },
      },
      {
        title: "経路条件金額",
        key: "condPrice",
        slots: {
          customRender: "condPrice",
        },
      },
      {
        title: "承認フロー",
        key: "levels",
        slots: {
          customRender: "levels",
        },
      },
      {
        title: "アクション",
        key: "actions",
        fixed: "right",
        slots: {
          customRender: "actions",
        },
      },
      // {
      //   title:"デフォルト",
      //   key: "default",
      //   dataindex: "default",
      //   slots: {
      //     customRender: "default",
      //   },
      // }
    ];

    const handleEdit = (clientApplicationFlow: ClientApplicationFlow) => {
      router.push({
        name: "SettingsFlowEdit",
        params: {
          id: clientApplicationFlow.id,
          clientUserGroupId: props.clientUserGroupId,
        },
      });
    };

    const useCreateApplicationFlow = () => {
      const state = reactive({
        visibleNewModal: false,
        newFlow: {} as { name: string; default: boolean },
      });

      const { mutate } = useCreateOneClientApplicationFlowMutation({});

      const handleNew = async () => {
        state.newFlow = {
          name: "",
          default: false,
        };
        state.visibleNewModal = true;
      };

      const handleCreate = async () => {
        try {
          const result = await mutate({
            input: {
              clientUserGroupId: props.clientUserGroupId,
              name: state.newFlow.name,
              default: state.newFlow.default,
            },
          });
          await refetch();
          state.visibleNewModal = false;

          const clientApplicationFlowId =
            result?.data?.createOneClientApplicationFlow?.clientApplicationFlow
              ?.id;
          if (clientApplicationFlowId) {
            router.push({
              name: "SettingsFlowEdit",
              params: {
                id: clientApplicationFlowId,
                clientUserGroupId: props.clientUserGroupId,
              },
            });
          }
        } catch (e) {
          console.log(e);
        }
      };

      return {
        ...toRefs(state),
        handleCreate,
        handleNew,
      };
    };

    const useRemoveApplicationFlow = () => {
      const { mutate } = useRemoveOneClientApplicationFlowMutation({});

      const handleRemove = async (
        clientApplicationFlow: ClientApplicationFlow
      ) => {
        try {
          await mutate({
            input: {
              id: clientApplicationFlow.id,
            },
          });
          await refetch();
        } catch (e) {
          console.log(e);
        }
      };

      return {
        handleRemove,
      };
    };

    // const handleDragged = async (
    //   draggingItem: any,
    //   draggedItem: any,
    //   dragedUpper: boolean,
    //   dataSource: Resource[]
    // ) => {
    //   const draggingItemIdx = dataSource.indexOf(draggingItem);
    //   const draggedItemIdx = dataSource.indexOf(draggedItem);

    //   const target = clone(draggingItem);

    //   if (dragedUpper) {
    //     if (draggingItemIdx + 1 === draggedItemIdx) {
    //       return;
    //     }
    //     target[props.orderAttr] = draggedItemIdx;
    //   } else {
    //     if (draggingItemIdx > draggedItemIdx) {
    //       target[props.orderAttr] = draggedItemIdx + 1;
    //     } else {
    //       target[props.orderAttr] = draggedItemIdx;
    //     }
    //   }

    //   await update(target);
    // };

    return {
      flows,
      loading,
      columns,
      handleEdit,
      ...useCreateApplicationFlow(),
      ...useRemoveApplicationFlow(),
    };
  },
});
</script>
