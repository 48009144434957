
import { defineComponent, reactive, toRefs, PropType } from "vue";
import { PriceLabelParam } from "@/client/schema";
import { Currency } from "@/generic/components/display";

export type Props = {
  priceLabelParam: PriceLabelParam;
};

export default defineComponent({
  components: {
    Currency,
  },
  props: {
    priceLabelParam: {
      type: Object as PropType<PriceLabelParam>,
      required: true,
    },
  },
  setup() {
    const state = reactive({
      visibleParamPreview: false,
    });

    const handleVisibleParamPreview = () => {
      state.visibleParamPreview = true;
    };

    const handleClose = () => {
      state.visibleParamPreview = false;
    };

    return {
      ...toRefs(state),
      handleVisibleParamPreview,
      handleClose,
    };
  },
});
