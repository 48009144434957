
import { defineComponent } from "vue";
import { Content } from "@/generic";
import { useRoute } from "vue-router";
import NotificationDatail from "@/client/components/notification/Detail.vue";

export default defineComponent({
  components: {
    Content,
    NotificationDatail,
  },
  setup() {
    const route = useRoute();
    const notificationId = route.params["id"];

    return {
      notificationId,
    };
  },
});
