
import { computed, defineComponent, ref, unref } from "vue";
import { Content } from "@/generic";
import UserName from "@/shared/components/CurrentUserName.vue";
import StorageFileList from "@/client/components/storageFile/List.vue";
import { useGetSession } from "@/client/modules/session";

export default defineComponent({
  components: {
    Content,
    UserName,
    StorageFileList,
  },
  setup() {
    const { currentUser } = useGetSession();

    const currentUserGroups = computed(
      () => unref(currentUser)?.userGroups || []
    );
    const defaultUserGroupId = computed(
      () => unref(currentUser)?.userGroups?.[0]?.id || null
    );
    const currentUserGroupId = ref(undefined as string | null | undefined);

    return {
      currentUserGroups,
      currentUserGroupId,
      defaultUserGroupId,
    };
  },
});
