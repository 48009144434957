
import { defineComponent, computed, unref } from "vue";
import { Content } from "@/generic";
import { useRoute } from "vue-router";
import { useGetOneOrderQuery } from "@/client/modules/api";
import { useResult } from "@vue/apollo-composable";
import OrderDetail from "@/client/components/order/Detail.vue";
import OrderCommentList from "@/client/components/orderComment/List.vue";
import OrderProductFileList from "@/client/components/orderProductFile/List.vue";
import UserName from "@/shared/components/CurrentUserName.vue";

export default defineComponent({
  components: {
    Content,
    OrderDetail,
    OrderCommentList,
    OrderProductFileList,
    UserName,
  },
  setup() {
    const route = useRoute();
    const orderId = computed(() => route.params["id"]);

    const { result } = useGetOneOrderQuery({
      id: unref(orderId) as string,
    });
    const order = useResult(result);

    return {
      orderId,
      order,
    };
  },
});
