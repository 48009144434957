
import {
  defineComponent,
  SetupContext,
  reactive,
  toRefs,
  PropType,
  ref,
} from "vue";
import AddressForm from "@/shared/components/AddressForm.vue";
import { parseError, debounce } from "@/generic";
import {
  useUpdateOneOrderDeliveryMutation,
  useRemoveOneOrderDeliveryMutation,
  useUpdateOneOrderDeliveryShipperMutation,
} from "@/client/modules/api";
import { userSelectClientDelivery, userSelectClientShipper } from "./module";
import {
  Order,
  OrderDelivery,
  ClientDelivery,
  ClientShipper,
  OrderDeliveryShipper,
} from "@/client/schema";

export type Props = {
  order: Order;
  orderDelivery: OrderDelivery;
  deletable: boolean;
};

export default defineComponent({
  components: {
    AddressForm,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
    orderDelivery: {
      type: Object as PropType<OrderDelivery>,
      required: true,
    },
    deletable: {
      type: Boolean,
      required: true,
    },
  },
  setup(props: Props, context: SetupContext) {
    const state = reactive({
      errorMessages: [] as string[],
    });

    const quantity = ref(props.orderDelivery.quantity);

    const { mutate: update, error } = useUpdateOneOrderDeliveryMutation({});
    const { mutate: remove, error: removeError } =
      useRemoveOneOrderDeliveryMutation({});

    const handleUpdate = async (deliveryModel: any) => {
      try {
        state.errorMessages = [];
        await update({
          input: {
            id: props.orderDelivery.id,
            name: deliveryModel.name,
            zipCode: deliveryModel.zipCode,
            prefecture: deliveryModel.prefecture,
            city: deliveryModel.city,
            block: deliveryModel.block,
            building: deliveryModel.building,
            tel: deliveryModel.tel,
            fax: deliveryModel.fax,
            quantity: quantity.value,
          },
        });
        context.emit("change");
      } catch (e) {
        if (error.value == null) throw e;
        state.errorMessages = parseError(error.value);
      }
    };

    const handleDebounceUpdate = debounce((deliveryModel: OrderDelivery) => {
      handleUpdate(deliveryModel);
    });

    const handleRemove = async () => {
      try {
        state.errorMessages = [];
        await remove({
          input: {
            id: props.orderDelivery.id,
          },
        });

        context.emit("change");
      } catch (e) {
        if (removeError.value == null) throw e;
        state.errorMessages = parseError(removeError.value);
      }
    };

    const { mutate: updateShipper, error: updateShipperError } =
      useUpdateOneOrderDeliveryShipperMutation({});

    const handleUpdateShipper = async (deliveryModel: any) => {
      try {
        if (props.orderDelivery.shipper == null) return;
        state.errorMessages = [];
        await updateShipper({
          input: {
            id: props.orderDelivery.shipper.id,
            name: deliveryModel.name,
            zipCode: deliveryModel.zipCode,
            prefecture: deliveryModel.prefecture,
            city: deliveryModel.city,
            block: deliveryModel.block,
            building: deliveryModel.building,
            tel: deliveryModel.tel,
          },
        });
        context.emit("change");
      } catch (e) {
        if (updateShipperError.value == null) throw e;
        state.errorMessages = parseError(updateShipperError.value);
      }
    };

    const handleResetShipper = async () => {
      try {
        if (props.orderDelivery.shipper == null) return;
        state.errorMessages = [];
        await updateShipper({
          input: {
            id: props.orderDelivery.shipper.id,
            reset: true,
          },
        });
        context.emit("change");
      } catch (e) {
        if (updateShipperError.value == null) throw e;
        state.errorMessages = parseError(updateShipperError.value);
      }
    };

    const { clientDeliveryOptions, handleSelectDelivery } =
      userSelectClientDelivery(props.order.clientUserGroup.id);
    const handleSelectClientDelivery = async (
      orderDelivery: OrderDelivery,
      clientDelivery: ClientDelivery
    ) => {
      await handleSelectDelivery(orderDelivery, clientDelivery);
      handleUpdate(orderDelivery);
    };

    const { clientShipperOptions, handleSelectShipper } =
      userSelectClientShipper(props.order.clientUserGroup.id);
    const handleSelectClientShipper = async (
      orderDeliveryShipper: OrderDeliveryShipper,
      clientShipper: ClientShipper
    ) => {
      await handleSelectShipper(orderDeliveryShipper, clientShipper);
      handleUpdateShipper(orderDeliveryShipper);
    };

    return {
      ...toRefs(state),
      clientDeliveryOptions,
      handleSelectClientDelivery,
      clientShipperOptions,
      handleSelectClientShipper,
      handleUpdate,
      handleDebounceUpdate,
      handleUpdateShipper,
      handleResetShipper,
      handleRemove,
      quantity,
    };
  },
});
