<style lang="less" scoped></style>
<template>
  <Table
    :name="name"
    :queryVariables="queryVariables"
    :createMutationVariables="createMutationVariables"
    :attrs="attrs"
    :editable="false"
    :importable="false"
    :exportable="false"
    editMode="inline"
  ></Table>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { Attrs, Table } from "@/generic";

export type Props = {
  clientUserGroupId: string;
};

export default defineComponent({
  components: {
    Table,
  },
  props: {
    clientUserGroupId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const state = reactive({});

    const name = "clientUserGroupMembership";
    const queryVariables = [
      {
        key: "clientUserGroupId",
        graphQLType: "ID!",
        value: props.clientUserGroupId,
      },
    ];
    const createMutationVariables = [
      {
        key: "clientUserGroupId",
        graphQLType: "ID",
        value: props.clientUserGroupId,
      },
    ];

    const attrs = [
      {
        key: "clientUser",
        type: "model",
        typeName: "ClientUser",
        required: true,
      },
    ] as Attrs;

    return {
      ...toRefs(state),
      name,
      queryVariables,
      createMutationVariables,
      attrs,
    };
  },
});
</script>
