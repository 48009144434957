
import { defineComponent, reactive, toRefs } from "vue";
import { clone, parseError } from "@/generic";
import {
  useUpdateOneInnerCartonParamMutation,
  useGetOneInnerCartonParamQuery,
} from "@/client/modules/api";
import { InputCurrency } from "@/generic/components/entry";
import { message } from "ant-design-vue";

export type Props = {
  innerCartonParamId: string;
};

export default defineComponent({
  components: {
    InputCurrency,
  },
  props: {
    innerCartonParamId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const state = reactive({
      innerCartonParam: null as null | any,
      visibleParamInput: false,
      errorMessages: [] as string[],
    });

    const { loading, onResult, refetch } = useGetOneInnerCartonParamQuery(
      {
        id: props.innerCartonParamId,
      },
      {
        fetchPolicy: "network-only",
      }
    );
    onResult((result) => {
      state.innerCartonParam = clone(result.data.innerCartonParam);
    });

    const handleVisibleParamInput = () => {
      state.visibleParamInput = true;
    };

    const { mutate: update, error: updateError } =
      useUpdateOneInnerCartonParamMutation({});

    const handleUpdate = async () => {
      const safeInnerCartonParam = state.innerCartonParam;
      try {
        if (safeInnerCartonParam == null) return;
        state.errorMessages = [];
        await update({
          input: {
            id: props.innerCartonParamId,
            janCode12Digits: safeInnerCartonParam.janCode12Digits,
            productName: safeInnerCartonParam.productName,
            quantityPerInnerCartonText:
              safeInnerCartonParam.quantityPerInnerCartonText,
            taxIncludedPrice: safeInnerCartonParam.taxIncludedPrice,
          },
        });

        await refetch();
      } catch (e) {
        if (updateError.value == null) throw e;
        const errorMessages = parseError(updateError.value);
        errorMessages.forEach((msg: string) => message.error(msg));
      }
    };

    const handleClose = () => {
      state.visibleParamInput = false;
    };

    return {
      ...toRefs(state),
      loading,
      handleVisibleParamInput,
      handleUpdate,
      handleClose,
    };
  },
});
