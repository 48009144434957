<style lang="less" scoped>
@import "../../../styles/variable.less";
.storage-summary {
  display: flex;
  &-percent-warning {
    color: @red01;
  }
  &-percent-caution {
    color: #fa8c16;
  }
  &-percent-normal {
    color: @blue01;
  }
  &-gigabyte {
    color: @gray02;
  }
}
</style>
<template>
  <div class="storage-summary" v-if="!loading && storageSummary != null">
    <a-space>
      <div :class="percentStyle(storageSummary.storageRatio)">
        {{ storageSummary.storageRatio }}%を使用中
      </div>
      <div class="storage-summary-gigabyte">
        - {{ storageSummary.maxStorageSize }}GB 中
        {{ storageSummary.fileSizeSummary }}GB を使用中
      </div>
    </a-space>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useResult } from "@vue/apollo-composable";
import { useGetOneClientStorageSummaryQuery } from "@/client/modules/api";

export default defineComponent({
  components: {},
  setup() {
    const { result, loading } = useGetOneClientStorageSummaryQuery({});
    const storageSummary = useResult(result);

    const percentStyle = (storageRatio: number) => {
      if (storageRatio >= 90) {
        return "storage-summary-percent-warning";
      } else if (storageRatio >= 70) {
        return "storage-summary-percent-caution";
      } else {
        return "storage-summary-percent-normal";
      }
    };

    return {
      loading,
      storageSummary,
      percentStyle,
    };
  },
});
</script>
