<style lang="less" scoped>
@import "../../../styles/variable.less";
#storage-list {
  padding-bottom: 5px;
  /deep/ .storage {
    &-item,
    &-link {
      display: flex;
      & > * {
        flex: 1 1 100%;
      }
    }
    &-option {
      background: @white01;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 0;
      margin-top: 50px;
      border-top: solid 1px @clear-black02;
      position: sticky;
      bottom: 0;
      z-index: 5;
    }
  }
  /deep/ .ant-card {
    border-radius: 5px;
    padding: 5px;
    height: 230px;
    width: 225px;
    &-body {
      padding: 15px 10px;
    }
    &-cover {
      position: relative;
      .storage {
        &-thumbnail {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          border: solid 1px @clear-black02;
          max-height: 160px;
          border-radius: 5px;
          overflow: hidden;
          vertical-align: bottom;
        }
      }
      .ant-image {
        opacity: 0;
      }
    }
    &-meta {
      &-title {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 6px;
        a {
          color: inherit;
        }
      }
    }
    .storage {
      &-info {
        font-size: 12px;
        color: @gray01;
        margin: 0;
      }
    }
  }
}
</style>
<template>
  <div id="storage-list" v-if="storageFiles">
    <a-row :gutter="[10, 10]">
      <a-col
        class="storage-item"
        v-for="storageFile in storageFiles"
        :key="storageFile.id"
        :span="6"
        :xs="24"
        :sm="12"
        :md="12"
        :lg="8"
        :xl="6"
        :xxl="6"
      >
        <router-link
          class="storage-link"
          :to="{
            name: 'SettingsClientStorageFileDetail',
            params: { id: storageFile.id },
          }"
        >
          <a-card hoverable>
            <template #cover>
              <div
                class="storage-thumbnail"
                :style="{
                  backgroundImage: 'url(' + storageFile.thumbnailPath + ')',
                }"
              >
                <a-image
                  width="100%"
                  :src="storageFile.thumbnailPath"
                  :preview="false"
                />
              </div>
            </template>
            <a-card-meta>
              <template #title>{{ storageFile.name }}</template>
              <template #description>
                <p class="storage-info">
                  {{
                    storageFile.mimeType
                      .split("/")
                      [storageFile.mimeType.split("/").length - 1].toUpperCase()
                  }}
                  / {{ getSize(storageFile.fileSize) }}
                </p>
              </template>
            </a-card-meta>
          </a-card>
        </router-link>
      </a-col>
    </a-row>
    <div class="storage-option">
      <div class="add-button">
        <a-button size="large" type="primary" @click="handleNew">
          データアップロード
        </a-button>
      </div>
      <a-pagination
        v-model:current="currentPage"
        :pageSize="pageSize"
        :total="total"
        :show-total="
          (total, range) => `${range[0]} 〜 ${range[1]} / ${total} 件`
        "
        @change="handlePageChange"
      />
    </div>
    <a-modal
      class="storagefile-upload-modal"
      title="データのアップロード"
      :visible="visibleUpdloadModal"
      :okButtonProps="{ disabled: !canUpload }"
      @cancel="handleNewCancel"
      @ok="handleUpload"
    >
      <a-form layout="vertical" :v-if="visibleUpdloadModal">
        <a-form-item>
          <a-upload-dragger
            :multiple="false"
            :beforeUpload="handleFileSelet"
            :fileList="fileList"
          >
            <p class="ant-upload-drag-icon">
              <UploadOutlined></UploadOutlined>
            </p>
            <p class="ant-upload-text">
              ここをクリックするか、このエリアにファイルをドラッグで、<br />アップロード
            </p>
            <!-- <p class="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
            band files
          </p>            -->
          </a-upload-dragger>
        </a-form-item>
        <a-form-item label="名前">
          <a-input v-model:value="newUpload.name"></a-input>
        </a-form-item>
        <a-form-item label="備考">
          <a-textarea v-model:value="newUpload.note"></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  toRefs,
  unref,
} from "vue";
import { useResult } from "@vue/apollo-composable";
import { message } from "ant-design-vue";
import { UploadOutlined } from "@ant-design/icons-vue";
import { assert } from "@/generic";
import {
  GetManyClientStorageFileQuery,
  ClientStorageFile,
  ClientUserGroup,
} from "@/client/schema";
import {
  useGetManyClientStorageFileQuery,
  useGetManyCountClientStorageFileQuery,
  useRemoveOneClientStorageFileMutation,
} from "@/client/modules/api";
import { useUploadResource } from "@/client/modules/uploadResource";

export type Props = {
  clientUserGroup?: ClientUserGroup;
};

interface sizeObj {
  size: number;
  suffix: string;
}

export default defineComponent({
  components: {
    UploadOutlined,
  },
  props: {
    clientUserGroup: {
      type: Object as PropType<ClientUserGroup>,
      required: false,
    },
  },
  setup(props: Props) {
    const pagination = reactive({
      currentPage: 1,
      pageSize: 8,
    });

    const {
      result: filesResult,
      loading,
      refetch,
      variables,
    } = useGetManyClientStorageFileQuery({
      clientUserGroupId: props.clientUserGroup?.id,
      offset: 0,
      limit: pagination.pageSize,
    });

    const storageFiles =
      useResult<GetManyClientStorageFileQuery, "clientStorageFiles">(
        filesResult
      );

    const { result: totalResult, refetch: totalRefetch } =
      useGetManyCountClientStorageFileQuery({
        clientUserGroupId: props.clientUserGroup?.id,
      });

    const total = useResult(totalResult);

    const handlePageChange = (page: number) => {
      pagination.currentPage = page;
      variables.value = {
        clientUserGroupId: props.clientUserGroup?.id,
        offset: (page - 1) * pagination.pageSize,
        limit: pagination.pageSize,
      };
    };

    const useUploadStorageFile = () => {
      const { uploadData, errorMessages: importErrorMessages } =
        useUploadResource();

      const state = reactive({
        visibleUpdloadModal: false,
        newUpload: {} as {
          name?: string;
          note?: string;
          file?: File;
        },
        fileList: [] as File[],
      });

      const canUpload = computed(() => state.newUpload?.file != null);

      const handleNew = () => {
        state.visibleUpdloadModal = true;
      };

      const handleNewCancel = () => {
        state.visibleUpdloadModal = false;
        state.newUpload = {};
        state.fileList = [];
      };

      const handleFileSelet = (file: File) => {
        state.newUpload.file = file;
        state.newUpload.name = file.name;
        return false;
      };

      const handleUpload = () => {
        const file = state.newUpload.file;
        assert(file != null);
        const fileExt = file.name.split(".").pop();

        uploadData(null, "ClientStorageFile", file, fileExt, {
          clientUserGroupId: props.clientUserGroup?.id,
          name: state.newUpload.name,
          note: state.newUpload.note,
        }).then(async (result: boolean) => {
          // 非同期に呼び出したいのでawaitにしないこと
          if (result) {
            await refetch();
            await totalRefetch();
            message.success("アップロードしました。");
          } else {
            const errorMessages = unref(importErrorMessages);
            Object.keys(errorMessages).forEach((key) => {
              message.error(errorMessages[key]);
            });
          }
          handleNewCancel();
        });
      };

      return {
        ...toRefs(state),
        canUpload,
        handleNew,
        handleNewCancel,
        handleUpload,
        handleFileSelet,
      };
    };

    const useRemoveClientStorageFile = () => {
      const { mutate } = useRemoveOneClientStorageFileMutation({});

      const handleRemove = async (clientStorageFile: ClientStorageFile) => {
        await mutate({
          input: { id: clientStorageFile.id },
        });
        await refetch();
        await totalRefetch();
        message.success(`削除しました。`);
      };

      return {
        handleRemove,
      };
    };

    const getSize = (size: number) => {
      const kb = 1024;
      const mb = Math.pow(kb, 2);
      const gb = Math.pow(kb, 3);
      let byteData: sizeObj = {
        size: size,
        suffix: "byte",
      };
      if (size >= gb) {
        byteData = {
          size: size / gb,
          suffix: "GB",
        };
      }
      if (size >= mb) {
        byteData = {
          size: size / mb,
          suffix: "MB",
        };
      }
      if (size >= kb) {
        byteData = {
          size: size / kb,
          suffix: "KB",
        };
      }
      const byte =
        Math.round(byteData.size).toString().toLocaleString() + byteData.suffix;

      return byte;
    };

    return {
      ...toRefs(pagination),
      storageFiles,
      total,
      loading,
      handlePageChange,
      ...useUploadStorageFile(),
      ...useRemoveClientStorageFile(),
      getSize,
    };
  },
});
</script>
