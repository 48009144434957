<style lang="less" scoped>
.box {
  position: relative;
  &:hover .overlap {
    opacity: 0.7;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .img {
    width: 100%;
  }

  .overlap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.3s;
    display: flex;

    .btn-box {
      margin: auto;
    }
  }
}

.preview {
  .img {
    width: 100%;
  }
}
</style>
<template>
  <div class="box">
    <img class="img" :src="orderProductFile.thumbnailPath" />
    <div class="overlap">
      <div class="btn-box">
        <a-button type="link" @click="handlePreview">
          <template #icon>
            <EyeOutlined />
          </template>
        </a-button>
        <a-button type="link" @click="handleRemove">
          <template #icon>
            <DeleteOutlined />
          </template>
        </a-button>
      </div>
    </div>
  </div>
  <a-modal v-model:visible="preview" :footer="false" class="preview">
    <img class="img" :src="orderProductFile.thumbnailPath" />
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, SetupContext, toRefs, reactive, PropType } from "vue";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons-vue";
import { OrderProductFile } from "../../schema";

export type Props = {
  orderProductFile: OrderProductFile;
};

export default defineComponent({
  components: {
    DeleteOutlined,
    EyeOutlined,
  },
  props: {
    orderProductFile: {
      type: Object as PropType<OrderProductFile>,
      required: true,
    },
  },
  emits: ["preview", "remove"],
  setup(props: Props, context: SetupContext) {
    console.log(props.orderProductFile);
    const state = reactive({
      preview: false,
    });
    const handlePreview = () => {
      state.preview = true;
      //context.emit("preview");
    };

    const handleRemove = () => {
      context.emit("remove");
    };

    return {
      ...toRefs(state),
      handlePreview,
      handleRemove,
    };
  },
});
</script>
