
import { defineComponent } from "vue";
import { useGetSession } from "@/client/modules/session";

export default defineComponent({
  setup() {
    const { currentUser } = useGetSession();
    return {
      currentUser,
    };
  },
});
