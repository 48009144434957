import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

import generic from "@/generic";
import {
  createApolloClient,
  defaultApolloClient,
} from "@/shared/providors/apollo";
import { default as i18n, i18nExtend } from "@/shared/providors/i18n";
import antd from "@/shared/providors/antd";
import { bugsnagVue } from "@/shared/providors/bugsnag";

import "./styles/all.less";

const apolloClient = createApolloClient("/api/app/graphql", "client");

const app = createApp(App);
app.use(generic);
app.use(router);
app.use(i18n);
app.use(i18nExtend);
app.use(defaultApolloClient, apolloClient);
app.use(antd);

// @ts-ignore
app.use(bugsnagVue);

app.mount("#app");
