<template>
  <span role="img" class="anticon"
    ><svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.30902 0C1.93025 0 1.58398 0.214 1.41459 0.55279L0.05279 3.27639C0.01807 3.34582 0 3.42238 0 3.5V12C0 12.5523 0.44772 13 1 13H12C12.5523 13 13 12.5523 13 12V3.5C13 3.42238 12.9819 3.34582 12.9472 3.27639L11.5854 0.55281C11.416 0.21403 11.0698 3.00407e-05 10.691 3.00407e-05L6.5 1.00136e-05L2.30902 0ZM2.30902 1L6 1.00001V3H1.30902L2.30902 1ZM7 3V1.00002L10.691 1.00003L11.691 3H7ZM6.5 4H12V12H1V4H6.5ZM4.5 6C4.22386 6 4 6.22386 4 6.5C4 6.77614 4.22386 7 4.5 7H8.5C8.77614 7 9 6.77614 9 6.5C9 6.22386 8.77614 6 8.5 6H4.5Z"
        fill="currentColor"
      /></svg
  ></span>
</template>
