<style lang="less" scoped>
@import "../../styles/variable.less";
.form {
  padding: 60px 30px;
  /deep/ .ant-card {
    &-body {
      padding: 0;
    }
  }
  &-logo {
    text-align: center;
    margin-bottom: 40px;
  }
  &-title {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 60px;
  }
  &-item {
    &:nth-child(n + 2) {
      margin-top: 20px;
    }
  }
  &-label {
    color: @clear-black06;
    font-size: 12px;
    margin-bottom: 10px;
  }
  &-data {
    min-width: 280px;
  }
  &-action {
    text-align: center;
    margin-top: 60px;
    .ant-btn {
      padding: 10px 20px;
    }
  }
  &-extra {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
<template>
  <a-card class="form" :bordered="false">
    <div class="form-logo">
      <Logo style="width: 100px" />
    </div>
    <!-- <h1 class="form-title">ログイン</h1> -->
    <a-form
      layout="horizontal"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      @submit.prevent.stop="handleCreateSession"
    >
      <a-alert
        type="error"
        :message="errorMessage"
        banner
        v-if="errorMessage"
      />
      <div class="form-item">
        <div class="form-label">{{ $ta("username") }}/{{ $ta("email") }}</div>
        <div class="form-data">
          <a-input
            placeholder="入力してください"
            v-model:value="input.username"
            :required="true"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-label">{{ $ta("password") }}</div>
        <div class="form-data">
          <a-input-password
            placeholder="入力してください"
            v-model:value="input.password"
            :required="true"
          />
        </div>
      </div>
      <div class="form-action">
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          :loading="loading"
          >ログイン</a-button
        >
      </div>
      <div class="form-extra">
        <router-link to="/password/new"
          >パスワードを忘れたので再設定します。</router-link
        >
        <div>
          <a href="/terms_of_service.pdf">利用規約</a>
        </div>
      </div>
    </a-form>
  </a-card>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";
import Logo from "@/shared/components/Logo.vue";
import {
  UseSessionKey,
  UseSessionStore,
  CreateSessionInput,
} from "@/client/modules/session";

export default defineComponent({
  components: {
    Logo,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const state = reactive({
      input: {
        username: null,
        password: null,
      } as CreateSessionInput,
      errorMessage: null as string | null,
    });

    const { create, loading } = inject(UseSessionKey) as UseSessionStore;

    const handleCreateSession = async () => {
      if (!(await create(state.input))) {
        state.errorMessage = "ログインに失敗しました。";
      } else {
        const redirect = route.query["redirect"] as string | null;
        if (redirect == null) {
          router.push({ name: "Home" });
        } else {
          router.push({ path: redirect });
        }
      }
    };

    return {
      ...toRefs(state),
      loading,
      handleCreateSession,
    };
  },
});
</script>
