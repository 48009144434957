
import { defineComponent, unref, ref } from "vue";
import { message } from "ant-design-vue";
import { clone, assert } from "@/generic";
import { ClientStorageFile } from "@/client/schema";
import {
  useGetOneClientStorageFileQuery,
  useUpdateOneClientStorageFileMutation,
  useRemoveOneClientStorageFileMutation,
} from "@/client/modules/api";
import { LeftOutlined } from "@ant-design/icons-vue";
import ImagePreview from "@/shared/components/ImagePreview.vue";
import { useRouter } from "vue-router";

export type Props = {
  id: string;
};

interface sizeObj {
  size: number;
  suffix: string;
}

export default defineComponent({
  components: {
    LeftOutlined,
    ImagePreview,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const router = useRouter();

    const { loading, refetch, onResult } = useGetOneClientStorageFileQuery({
      id: props.id,
    });

    const storageFile = ref(null as ClientStorageFile | null);

    onResult((result: any) => {
      storageFile.value = clone(result.data.clientStorageFile);
    });

    const useUploadClientStorageFile = () => {
      const { mutate } = useUpdateOneClientStorageFileMutation({});

      const handleUpload = async () => {
        const clientStorageFile = unref(storageFile);
        assert(clientStorageFile != null);
        await mutate({
          input: {
            id: clientStorageFile.id,
            name: clientStorageFile.name,
            note: clientStorageFile.note,
          },
        });
        await refetch();
        message.success(`更新しました。`);
      };

      return {
        handleUpload,
      };
    };

    const useRemoveClientStorageFile = () => {
      const { mutate } = useRemoveOneClientStorageFileMutation({});

      const handleRemove = async (clientStorageFile: ClientStorageFile) => {
        await mutate({
          input: {
            id: clientStorageFile.id,
          },
        });
        // await refetch();
        router.push({ name: "SettingsClientStorageFileList" });
        message.success(`削除しました。`);
      };

      return {
        handleRemove,
      };
    };

    const getSize = (size: number) => {
      const kb = 1024;
      const mb = Math.pow(kb, 2);
      const gb = Math.pow(kb, 3);
      let byteData: sizeObj = {
        size: size,
        suffix: "byte",
      };
      if (size >= gb) {
        byteData = {
          size: size / gb,
          suffix: "GB",
        };
      }
      if (size >= mb) {
        byteData = {
          size: size / mb,
          suffix: "MB",
        };
      }
      if (size >= kb) {
        byteData = {
          size: size / kb,
          suffix: "KB",
        };
      }
      const byte =
        Math.round(byteData.size).toString().toLocaleString() + byteData.suffix;

      return byte;
    };

    return {
      storageFile,
      loading,
      ...useUploadClientStorageFile(),
      ...useRemoveClientStorageFile(),
      getSize,
    };
  },
});
