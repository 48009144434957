
import { defineComponent } from "vue";
import { Content } from "@/generic";
import OrderList from "@/client/components/order/List.vue";
import UserName from "@/shared/components/CurrentUserName.vue";

export default defineComponent({
  components: {
    Content,
    OrderList,
    UserName,
  },
});
