<style lang="less" scoped></style>
<template>
  <div>
    <Content
      class="content-head"
      :style="{
        margin: 0,
        padding: '30px 0 30px 30px',
        height: 'auto',
      }"
    >
      <h1 class="content-head-title">スタッフグループ</h1>
      <ClientUserGroupNav
        :clientUserGroupId="clientUserGroupId"
      ></ClientUserGroupNav>
      <UserName class="content-head-username"></UserName>
    </Content>
    <div class="content-layout">
      <Content
        :style="{
          margin: 0,
          padding: '20px',
          height: 'auto',
        }"
      >
        <ApplicationFlowEdit :flowId="id"></ApplicationFlowEdit>
      </Content>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Content } from "@/generic";
import ApplicationFlowEdit from "@/client/components/settings/applicationFlow/Edit.vue";
import ClientUserGroupNav from "@/client/components/settings/clientUserGroup/Nav.vue";
import UserName from "@/shared/components/CurrentUserName.vue";

export default defineComponent({
  components: {
    Content,
    ApplicationFlowEdit,
    ClientUserGroupNav,
    UserName,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    clientUserGroupId: {
      type: String,
      required: true,
    },
  },
});
</script>
