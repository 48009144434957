<template>
  <a-tree-select
    class="select"
    :value="clientUserId"
    show-search
    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
    :style="{ width: '100%' }"
    allow-clear
    :tree-data="treeData"
    tree-default-expand-all
    placeholder="担当者を選択してください"
    @change="onChange"
    treeNodeFilterProp="title"
  >
    <template #suffixIcon><SmileOutlined /></template>
  </a-tree-select>
</template>

<script lang="ts">
import { defineComponent, SetupContext, computed, unref } from "vue";
import { useGetManyClientUserGroupQuery } from "@/client/modules/api";
import { useResult } from "@vue/apollo-composable";
import { GetManyClientUserGroupQuery } from "../../schema";
export type Props = {
  clientUserId: string;
};

export default defineComponent({
  props: {
    clientUserId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props, context: SetupContext) {
    const { result } = useGetManyClientUserGroupQuery({});

    const clientUserGroups =
      useResult<GetManyClientUserGroupQuery, "clientUserGroups">(result);

    const treeData = computed(() => {
      return unref(clientUserGroups)?.map((clientUserGroup) => {
        return {
          title: clientUserGroup?.name || "",
          key: clientUserGroup.id,
          children: [
            ...clientUserGroup.members.map((clientUser) => ({
              title: clientUser.name,
              value: clientUser.id,
              key: clientUser.id,
            })),
            ...clientUserGroup.children.map((childrenUserGroup) => ({
              title: childrenUserGroup.name,
              key: childrenUserGroup.id,
              children: childrenUserGroup.members.map((clientUser) => ({
                title: clientUser.name,
                value: clientUser.id,
                key: clientUser.id,
              })),
            })),
          ],
        };
      });
    });

    const onChange = (current: string) => {
      context.emit("update:clientUserId", current);
      context.emit("change", current);
    };

    return {
      onChange,
      treeData,
    };
  },
});
</script>
