const endpoint = "https://apis.postcode-jp.com/api/v4";
const apikey = "CQNyFOKYE5D9BAgmVCaT0tDxpYM7XMd4BstQo3i";

export type PostalCode = {
  pref: string;
  prefCode: string;
  city: string;
  cityCode: string;
  town: string;
  office: string;
};

export type City = {
  city: string;
  cityCode: string;
};

export type Pref = {
  pref: string;
  prefCode: string;
};

export const usePostalCode = () => {
  const searchAddress = async (postcode: string): Promise<PostalCode[]> => {
    const request = `${endpoint}/postcodes/${postcode}`;
    const response = await fetch(request, {
      headers: {
        apikey: apikey,
      },
    });
    const result = await response.json();
    return result;
  };
  return {
    searchAddress,
  };
};
