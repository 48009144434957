<style lang="less" scoped>
@import "../../styles/variable.less";
.contact-input {
  margin: 10px 0;

  /deep/ .ant-card {
    padding: 0 20px 20px;
    margin-bottom: 10px;
    &-head {
      padding: 0;
      &-title {
        font-size: 14px;
        font-weight: bold;
        padding: 0;
      }
    }
    &-body {
      padding: 20px 0;
    }
    .btn-blue {
      color: @blue01;
      border-color: @blue01;
      &:hover {
        color: @white01;
        background: @blue01;
      }
    }
  }
  /deep/ .ant-form {
    .ant-form {
      &-item {
        margin-bottom: 0;
        &-label {
          font-size: 12px;
          color: @clear-black06;
        }
      }
    }
  }
  .address-addbtn {
    color: @blue01;
    width: 100%;
    height: auto;
    border: dashed 1px @blue01;
    padding: 14px;
    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
<template>
  <div class="contact-input">
    <template v-for="(contact, index) in orderContacts" :key="contact.id">
      <EditContact
        :order="order"
        :orderContact="contact"
        :deletable="index > 0"
        @change="handleChange"
      ></EditContact>
    </template>
    <a-button size="large" class="address-addbtn" @click="handleCreate">
      連絡先を追加
    </a-button>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  PropType,
  SetupContext,
} from "vue";
import EditContact from "./EditContact.vue";
import { Order } from "@/client/schema";
import { useCreateOneOrderContactMutation } from "@/client/modules/api";
import { parseError } from "@/generic/core";
import { userSelectClientContacts } from "./module";

export type Props = {
  order: Order;
};

export default defineComponent({
  components: {
    EditContact,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  emits: ["change"],
  setup(props: Props, context: SetupContext) {
    const { mutate: create, error } = useCreateOneOrderContactMutation({});

    const orderContacts = computed(() => props.order.orderContacts);

    const state = reactive({
      errorMessages: [] as string[],
      creating: false,
    });

    const handleCreate = async () => {
      if (state.creating) return;
      try {
        state.creating = true;
        await create({
          input: {
            orderId: props.order.id,
          },
        });
        context.emit("change");
      } catch (e) {
        if (error.value == null) throw e;
        state.errorMessages = parseError(error);
      } finally {
        state.creating = false;
      }
    };

    const handleChange = async () => {
      context.emit("change");
    };

    return {
      ...toRefs(state),
      ...userSelectClientContacts(props.order.clientUserGroup.id),
      orderContacts,
      handleCreate,
      handleChange,
    };
  },
});
</script>
