
import { defineComponent } from "vue";
import { useResult } from "@vue/apollo-composable";
import { useGetManyNotificationQuery } from "@/client/modules/api";
import { formatDate } from "@/generic";

export default defineComponent({
  components: {},
  setup() {
    const { result, loading } = useGetManyNotificationQuery({
      fetchPolicy: "network-only",
    });

    const notifications = useResult(result);

    return {
      notifications,
      loading,
      formatDate,
    };
  },
});
