<style lang="less" scoped>
.loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  //background-color: ;
}
</style>

<template>
  <div class="loading">
    <a-spin size="large" />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { useRouter } from "vue-router";
import { UseSessionStore, UseSessionKey } from "@/client/modules/session";

export default defineComponent({
  setup() {
    const router = useRouter();

    const { remove } = inject(UseSessionKey) as UseSessionStore;
    remove();

    router.push({ name: "Home" });

    return {};
  },
});
</script>
