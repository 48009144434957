
import { defineComponent, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import Logo from "@/shared/components/Logo.vue";
import {
  UpdatePasswordInput,
  useUpdatePassword,
} from "@/client/modules/password";

export default defineComponent({
  components: {
    Logo,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      input: {} as UpdatePasswordInput,
      errorMessage: null as string | null,
    });

    const { update, loading } = useUpdatePassword();

    const handleSubmit = async () => {
      await update(state.input);
      router.push({ name: "SessionCreate" });
    };

    return {
      ...toRefs(state),
      loading,
      handleSubmit,
    };
  },
});
