<style lang="less" scoped>
@import "../../styles/variable.less";
.order-param-input-modal {
  .content {
    .main-content {
      padding: 10px 10px 0 10px;
      .jan-code-input {
        display: flex;

        .jan-code-12-digits {
          width: 100%;
        }

        .jan-code-check-digit {
          width: 33px;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
<template>
  <div v-if="innerCartonParam">
    <a-button
      size="large"
      type="primary"
      v-if="!previewOnly"
      @click="handleVisibleParamInput"
    >
      ラベル情報設定
    </a-button>

    <a-modal
      v-if="!loading"
      class="order-param-input-modal"
      width="600px"
      title="ラベル情報設定"
      v-model:visible="visibleParamInput"
      :okButtonProps="{ style: 'display: none' }"
    >
      <template #footer>
        <a-button size="large" key="back" @click="handleClose">閉じる</a-button>
      </template>
      <div class="content">
        <a-form
          :model="innerCartonParam"
          layout="horizontal"
          :labelCol="{ span: 8 }"
        >
          <div class="main-content">
            <a-form-item :label="$t('attributes.jan_code')">
              <div class="jan-code-input">
                <a-input
                  class="jan-code-12-digits"
                  :maxlength="12"
                  v-model:value="innerCartonParam.janCode12Digits"
                  @blur="handleUpdate"
                />
                <a-input
                  disabled
                  class="jan-code-check-digit"
                  :value="innerCartonParam.janCode?.substring(12) || ''"
                />
              </div>
            </a-form-item>
            <a-form-item :label="$t('attributes.product_name')">
              <a-input
                v-model:value="innerCartonParam.productName"
                @blur="handleUpdate"
              />
            </a-form-item>
            <a-form-item
              :label="$t('attributes.quantity_per_inner_carton_text')"
            >
              <a-input
                v-model:value="innerCartonParam.quantityPerInnerCartonText"
                @blur="handleUpdate"
              />
            </a-form-item>
            <a-form-item :label="$t('attributes.tax_included_price')">
              <InputCurrency
                v-model:value="innerCartonParam.taxIncludedPrice"
                @blur="handleUpdate"
              />
            </a-form-item>
          </div>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { clone, parseError } from "@/generic";
import {
  useUpdateOneInnerCartonParamMutation,
  useGetOneInnerCartonParamQuery,
} from "@/client/modules/api";
import { InputCurrency } from "@/generic/components/entry";
import { message } from "ant-design-vue";

export type Props = {
  innerCartonParamId: string;
};

export default defineComponent({
  components: {
    InputCurrency,
  },
  props: {
    innerCartonParamId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const state = reactive({
      innerCartonParam: null as null | any,
      visibleParamInput: false,
      errorMessages: [] as string[],
    });

    const { loading, onResult, refetch } = useGetOneInnerCartonParamQuery(
      {
        id: props.innerCartonParamId,
      },
      {
        fetchPolicy: "network-only",
      }
    );
    onResult((result) => {
      state.innerCartonParam = clone(result.data.innerCartonParam);
    });

    const handleVisibleParamInput = () => {
      state.visibleParamInput = true;
    };

    const { mutate: update, error: updateError } =
      useUpdateOneInnerCartonParamMutation({});

    const handleUpdate = async () => {
      const safeInnerCartonParam = state.innerCartonParam;
      try {
        if (safeInnerCartonParam == null) return;
        state.errorMessages = [];
        await update({
          input: {
            id: props.innerCartonParamId,
            janCode12Digits: safeInnerCartonParam.janCode12Digits,
            productName: safeInnerCartonParam.productName,
            quantityPerInnerCartonText:
              safeInnerCartonParam.quantityPerInnerCartonText,
            taxIncludedPrice: safeInnerCartonParam.taxIncludedPrice,
          },
        });

        await refetch();
      } catch (e) {
        if (updateError.value == null) throw e;
        const errorMessages = parseError(updateError.value);
        errorMessages.forEach((msg: string) => message.error(msg));
      }
    };

    const handleClose = () => {
      state.visibleParamInput = false;
    };

    return {
      ...toRefs(state),
      loading,
      handleVisibleParamInput,
      handleUpdate,
      handleClose,
    };
  },
});
</script>
