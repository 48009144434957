<style lang="less" scoped></style>
<template>
  <Table
    :name="name"
    :attrs="attrs"
    :pagination="true"
    :creatable="isManager"
    editMode="inline"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Attrs, Table } from "@/generic";
import { ClientUserAppRole } from "@/client/schema";
import { useGetSession } from "@/client/modules/session";

export default defineComponent({
  components: {
    Table,
  },
  setup() {
    const name = "clientUser";

    const attrs = [
      {
        key: "name",
        type: "string",
        required: true,
      },
      {
        key: "username",
        type: "string",
        required: true,
      },
      {
        key: "email",
        type: "string",
        required: true,
      },
      {
        key: "password",
        type: "password",
        required: true,
      },
      {
        key: "clientUserRole",
        type: "enum",
        typeName: "ClientUserAppRole",
        typeDef: ClientUserAppRole,
        required: true,
      },
      {
        key: "tel",
        type: "string",
        required: true,
      },
    ] as Attrs;

    const { isManager } = useGetSession();
    return {
      name,
      attrs,
      isManager,
    };
  },
});
</script>
