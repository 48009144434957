
import { defineComponent } from "vue";
import { Content } from "@/generic";
import OrderConfirm from "@/client/components/order/Confirm.vue";
import UserName from "@/shared/components/CurrentUserName.vue";

export default defineComponent({
  components: {
    Content,
    OrderConfirm,
    UserName,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
});
