<style lang="less" scoped>
.form-item {
  margin: 20px 0;
}

.shipper-card {
  margin-top: 20px;
}
</style>
<template>
  <a-card :title="$tr('orderDelivery')">
    <template v-slot:extra>
      <a-space>
        <a-popover title="選択してください" trigger="click">
          <template #content>
            <a-list size="small" :data-source="clientDeliveryOptions">
              <template #renderItem="{ item }">
                <a-list-item>
                  <a-button
                    type="link"
                    @click="
                      handleSelectClientDelivery(orderDelivery, item.value)
                    "
                    >{{ item.label }}</a-button
                  >
                </a-list-item>
              </template>
            </a-list>
          </template>
          <a-button size="large" class="btn-blue"
            >グループの配送先から入力</a-button
          >
        </a-popover>
        <template v-if="deletable">
          <a-button size="large" @click="handleRemove">削除</a-button>
        </template>
      </a-space>
    </template>
    <ul v-if="errorMessages.length">
      <li v-for="message in errorMessages" class="error-message" :key="message">
        {{ message }}
      </li>
    </ul>
    <AddressForm
      :model="orderDelivery"
      :fax="false"
      @change="handleUpdate"
    ></AddressForm>
    <div class="form-item">
      <a-form layout="vertical">
        <a-form-item label="数量">
          <a-input-number
            v-model:value="quantity"
            @change="handleDebounceUpdate"
          ></a-input-number>
        </a-form-item>
      </a-form>
    </div>
    <a-card
      class="shipper-card"
      :title="$tr('shipper')"
      v-if="orderDelivery.shipper"
    >
      <template v-slot:extra>
        <a-space>
          <a-popover title="選択してください" trigger="click">
            <template #content>
              <a-list size="small" :data-source="clientShipperOptions">
                <template #renderItem="{ item }">
                  <a-list-item>
                    <a-button
                      type="link"
                      @click="
                        handleSelectClientShipper(
                          orderDelivery.shipper,
                          item.value
                        )
                      "
                      >{{ item.label }}</a-button
                    >
                  </a-list-item>
                </template>
              </a-list>
            </template>
            <a-button size="large" class="btn-blue"
              >グループの荷主から入力</a-button
            >
          </a-popover>
          <a-button size="large" @click="handleResetShipper">リセット</a-button>
        </a-space>
      </template>
      <div>
        <AddressForm
          :model="orderDelivery.shipper"
          :fax="false"
          @change="handleUpdateShipper"
        ></AddressForm>
      </div>
    </a-card>
  </a-card>
</template>

<script lang="ts">
import {
  defineComponent,
  SetupContext,
  reactive,
  toRefs,
  PropType,
  ref,
} from "vue";
import AddressForm from "@/shared/components/AddressForm.vue";
import { parseError, debounce } from "@/generic";
import {
  useUpdateOneOrderDeliveryMutation,
  useRemoveOneOrderDeliveryMutation,
  useUpdateOneOrderDeliveryShipperMutation,
} from "@/client/modules/api";
import { userSelectClientDelivery, userSelectClientShipper } from "./module";
import {
  Order,
  OrderDelivery,
  ClientDelivery,
  ClientShipper,
  OrderDeliveryShipper,
} from "@/client/schema";

export type Props = {
  order: Order;
  orderDelivery: OrderDelivery;
  deletable: boolean;
};

export default defineComponent({
  components: {
    AddressForm,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
    orderDelivery: {
      type: Object as PropType<OrderDelivery>,
      required: true,
    },
    deletable: {
      type: Boolean,
      required: true,
    },
  },
  setup(props: Props, context: SetupContext) {
    const state = reactive({
      errorMessages: [] as string[],
    });

    const quantity = ref(props.orderDelivery.quantity);

    const { mutate: update, error } = useUpdateOneOrderDeliveryMutation({});
    const { mutate: remove, error: removeError } =
      useRemoveOneOrderDeliveryMutation({});

    const handleUpdate = async (deliveryModel: any) => {
      try {
        state.errorMessages = [];
        await update({
          input: {
            id: props.orderDelivery.id,
            name: deliveryModel.name,
            zipCode: deliveryModel.zipCode,
            prefecture: deliveryModel.prefecture,
            city: deliveryModel.city,
            block: deliveryModel.block,
            building: deliveryModel.building,
            tel: deliveryModel.tel,
            fax: deliveryModel.fax,
            quantity: quantity.value,
          },
        });
        context.emit("change");
      } catch (e) {
        if (error.value == null) throw e;
        state.errorMessages = parseError(error.value);
      }
    };

    const handleDebounceUpdate = debounce((deliveryModel: OrderDelivery) => {
      handleUpdate(deliveryModel);
    });

    const handleRemove = async () => {
      try {
        state.errorMessages = [];
        await remove({
          input: {
            id: props.orderDelivery.id,
          },
        });

        context.emit("change");
      } catch (e) {
        if (removeError.value == null) throw e;
        state.errorMessages = parseError(removeError.value);
      }
    };

    const { mutate: updateShipper, error: updateShipperError } =
      useUpdateOneOrderDeliveryShipperMutation({});

    const handleUpdateShipper = async (deliveryModel: any) => {
      try {
        if (props.orderDelivery.shipper == null) return;
        state.errorMessages = [];
        await updateShipper({
          input: {
            id: props.orderDelivery.shipper.id,
            name: deliveryModel.name,
            zipCode: deliveryModel.zipCode,
            prefecture: deliveryModel.prefecture,
            city: deliveryModel.city,
            block: deliveryModel.block,
            building: deliveryModel.building,
            tel: deliveryModel.tel,
          },
        });
        context.emit("change");
      } catch (e) {
        if (updateShipperError.value == null) throw e;
        state.errorMessages = parseError(updateShipperError.value);
      }
    };

    const handleResetShipper = async () => {
      try {
        if (props.orderDelivery.shipper == null) return;
        state.errorMessages = [];
        await updateShipper({
          input: {
            id: props.orderDelivery.shipper.id,
            reset: true,
          },
        });
        context.emit("change");
      } catch (e) {
        if (updateShipperError.value == null) throw e;
        state.errorMessages = parseError(updateShipperError.value);
      }
    };

    const { clientDeliveryOptions, handleSelectDelivery } =
      userSelectClientDelivery(props.order.clientUserGroup.id);
    const handleSelectClientDelivery = async (
      orderDelivery: OrderDelivery,
      clientDelivery: ClientDelivery
    ) => {
      await handleSelectDelivery(orderDelivery, clientDelivery);
      handleUpdate(orderDelivery);
    };

    const { clientShipperOptions, handleSelectShipper } =
      userSelectClientShipper(props.order.clientUserGroup.id);
    const handleSelectClientShipper = async (
      orderDeliveryShipper: OrderDeliveryShipper,
      clientShipper: ClientShipper
    ) => {
      await handleSelectShipper(orderDeliveryShipper, clientShipper);
      handleUpdateShipper(orderDeliveryShipper);
    };

    return {
      ...toRefs(state),
      clientDeliveryOptions,
      handleSelectClientDelivery,
      clientShipperOptions,
      handleSelectClientShipper,
      handleUpdate,
      handleDebounceUpdate,
      handleUpdateShipper,
      handleResetShipper,
      handleRemove,
      quantity,
    };
  },
});
</script>
