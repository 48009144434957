
import { defineComponent, inject } from "vue";
import { useRouter } from "vue-router";
import { UseSessionStore, UseSessionKey } from "@/client/modules/session";

export default defineComponent({
  setup() {
    const router = useRouter();

    const { remove } = inject(UseSessionKey) as UseSessionStore;
    remove();

    router.push({ name: "Home" });

    return {};
  },
});
