<style lang="less" scoped>
@import "../../styles/variable.less";
.approval {
  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .head {
      &-title {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        &.selected {
          margin-right: auto;
        }
      }
      &-button {
        border-radius: 5px;
        padding: 10px 20px;
        color: #6e6e6e;
      }
      &-search {
        margin-left: auto;
        .anticon {
          margin-right: 5px;
        }
        &.tool-active {
          color: @gray01;
        }
      }
      &-toggle {
        margin-left: 30px;
      }
    }
  }
  &-table {
    /deep/ .ant-table {
      border-top: solid 1px @clear-black02;
      &-thead {
        white-space: nowrap;
        & > tr {
          & > th {
            font-size: 10px;
            color: @clear-black03;
            background: none;
            border: none;
          }
        }
      }
      &-tbody {
        & > tr {
          & > td {
            border-bottom: none;
          }
          &:nth-child(odd) {
            background: @white03;
          }
        }
      }
      .cell {
        &-action,
        &-number {
          width: 1px;
          white-space: nowrap;
        }
      }
      .ant-btn {
        border-radius: 5px;
        & + .ant-btn {
          margin-left: 10px;
        }
      }
      .approve-btn,
      .reject-btn {
        padding: 6px 10px;
      }
      .reject-btn {
        background: none;
        color: @gray01;
        border: solid 1px @gray02;
      }
    }
    /deep/ .ant-table-pagination {
      text-align: right;
      margin: 20px 0 0;
      padding-top: 20px;
      border-top: solid 1px @clear-black02;
      float: none;
    }
  }
}
.reject-comment {
  &.ant-comment {
    /deep/ .ant-comment {
      &-inner {
        padding: 0;
      }
      &-avatar {
        display: none;
      }
    }
    .errors {
      margin: 0;
    }
    .error-message {
      margin-top: 10px;
    }
    .comment-send {
      background: @white03;
      border: solid 1px @clear-black02;
      border-top: none;
    }
    .filebtn {
      background: none;
      border: none;
      /deep/ path {
        fill: @clear-black03;
      }
    }
  }
}
</style>
<template>
  <Content
    :style="{
      margin: 0,
      padding: '20px',
      height: 'auto',
    }"
  >
    <div class="approval-head">
      <h2 class="head-title">
        <span>承認申請がある商品</span>
      </h2>
      <a-button
        class="head-button"
        @click="handleApproveAll"
        :disabled="disableApproveAll"
        >チェックしたものを一括承認する</a-button
      >
      <!-- <a-typography-link
        class="head-search"
        @click="toggleSearch"
        v-bind:class="{ 'tool-active': visibleSearchTool }"
      >
        <SearchOutlined />
        <span v-if="visibleSearchTool">検索ツールを閉じる</span>
        <span v-else>検索ツールを表示</span>
      </a-typography-link> -->
    </div>
    <!-- <template v-if="visibleSearchTool">
      <SearchTool></SearchTool>
    </template> -->
    <a-table
      class="approval-table"
      :data-source="orderApproveRecords"
      :columns="columns"
      :row-key="(record) => record.id"
      :loading="loading"
      :scroll="{ x: 'max-content' }"
      :row-selection="{
        selectedRowKeys: approveRecordIds,
        onChange: handleSelectApproveRecord,
        getCheckboxProps: checkboxProps,
      }"
    >
      <template #expandIcon="{ record, expanded, onExpand }">
        <template v-if="record.children == null || record.children.length == 0">
          <span
            v-if="!record.parent"
            class="ant-table-row-indent indent-level-1"
            style="padding-left: 20px"
          ></span>
          <span class="ant-table-row-expand-icon ant-table-row-spaced" />
        </template>
        <template v-else-if="expanded">
          <a-button
            type="link"
            @click="onExpand(record, $event)"
            class="ant-table-row-expand-icon`ant-table-row-expanded"
          >
            <RightSquareOutlined />
          </a-button>
        </template>
        <template v-else>
          <a-button
            type="link"
            @click="onExpand(record, $event)"
            class="ant-table-row-expand-icon`ant-table-row-collapsed"
          >
            <DownSquareOutlined />
          </a-button>
        </template>
      </template>
      <template #orderNo="{ record }">
        <router-link
          :to="{ name: 'OrderDetail', params: { id: record.order.id } }"
        >
          {{ record.order.orderNo }}
        </router-link>
      </template>
      <template #productName="{ record }">{{
        record.order.orderProduct.name
      }}</template>
      <template #clientUser="{ record }">{{
        record.order.clientUser.name
      }}</template>
      <template #orderedAt="{ record }">{{
        formatTimestamp(record.order.orderedAt)
      }}</template>
      <template #basicDueDate="{ record }"
        >{{ record.order.orderProduct.basicDueDate }}日</template
      >
      <template #shippingFee="{ record }">{{
        record.order.shippingFee
      }}</template>
      <template #amount="{ record }">{{ record.order.amount }}</template>
      <template #clientUserGroup="{ record }">{{
        record.order.clientUserGroup?.name
      }}</template>
      <template #action="{ record }">
        <a-button
          type="primary"
          class="approve-btn"
          @click="handleApproveConfirm(record)"
          v-if="!record.parent"
          >承認</a-button
        >
        <a-button
          class="reject-btn"
          @click="handleRejectConfirm(record)"
          v-if="!record.parent"
          >否認</a-button
        >
      </template>
      <template #date="{ text }">{{ formatDate(text) }}</template>
    </a-table>
    <a-modal
      v-model:visible="visbleRejectConfirm"
      class="reject-modal"
      title="否認理由をコメントする"
      @ok="handleReject"
      v-if="visbleRejectConfirm"
    >
      <template #footer>
        <a-button size="large" key="back" @click="handleCancel"
          >キャンセル</a-button
        >
      </template>
      <a-comment class="reject-comment">
        <template #content>
          <a-textarea :rows="4" v-model:value="rejectMessage" />
          <ul class="errors">
            <li
              v-for="message in errorMessages"
              class="error-message"
              :key="message"
            >
              {{ message }}
            </li>
          </ul>
          <a-row type="flex" class="comment-send">
            <a-col flex="auto">
              <a-upload
                :file-list="fileList"
                :remove="handleRemoveFile"
                :before-upload="beforeUpload"
              >
                <a-button class="filebtn"><PaperClipOutlined /></a-button>
              </a-upload>
            </a-col>
            <a-col>
              <a-button
                html-type="submit"
                :loading="addLoading"
                type="primary"
                @click="handleReject"
                :disabled="!rejectMessage"
              >
                送信<SubmitIcon />
              </a-button>
            </a-col>
          </a-row>
        </template>
      </a-comment>
    </a-modal>

    <a-modal
      v-model:visible="visbleApproveConfirm"
      class="approval-modal"
      title="承認しますか？"
      ok-text="承認"
      cancel-text="キャンセル"
      @ok="handleApprove"
      :bodyStyle="{
        display: 'none',
      }"
      :okButtonProps="{ size: 'large' }"
      :cancelButtonProps="{ size: 'large' }"
    >
    </a-modal>
  </Content>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  unref,
  inject,
} from "vue";
import { message } from "ant-design-vue";
import {
  ReloadOutlined,
  PaperClipOutlined,
  RightSquareOutlined,
  DownSquareOutlined,
} from "@ant-design/icons-vue";
import { useResult } from "@vue/apollo-composable";
import {
  useGetManyOrderApprovalRecordQuery,
  useApproveManyOrderApprovalRecordMutation,
  useRejectOrderApprovalRecordMutation,
} from "@/client/modules/api";
import { useUploadResource } from "@/client/components/orderComment/modules/uploadResource";
import { assert, formatDate, formatTimestamp, Content } from "@/generic";
import { useI18n } from "@/shared/providors/i18n";
import { OrderApprovalRecord } from "../../schema";
import SubmitIcon from "@/shared/components/svg-icon/Submit.vue";
// import { default as SearchTool, Search } from "./SearchTool.vue";
import { UsePwaKey, UsePwaStore } from "@/shared/modules/pwa";

import moment from "moment";
import "moment/locale/ja";
moment.locale("ja");

interface FileItem {
  uid: string;
  name: string;
  status?: string;
  response?: string;
  url?: string;
  preview?: string;
  originFileObj?: any;
  file: string | Blob;
}

export default defineComponent({
  components: {
    Content,
    PaperClipOutlined,
    SubmitIcon,
    RightSquareOutlined,
    DownSquareOutlined,
    // SearchTool,
  },
  setup() {
    const { ta } = useI18n();
    const formRef = ref();

    const { result, refetch, loading } = useGetManyOrderApprovalRecordQuery({
      current: true,
    });

    const { stage } = inject(UsePwaKey) as UsePwaStore;
    const columns = computed((): any[] => {
      let _columns: any = [
        {
          title: ta("orderNo"),
          dataIndex: "orderNo",
          key: "orderNo",
          slots: {
            customRender: "orderNo",
          },
          class: "cell-number",
        },
        {
          title: ta("productName"),
          dataIndex: "productName",
          key: "productName",
          slots: {
            customRender: "productName",
          },
        },
        {
          title: ta("clientUser"),
          dataIndex: "clientUser",
          key: "clientUser",
          slots: {
            customRender: "clientUser",
          },
        },
        {
          title: ta("orderedAt"),
          dataIndex: "orderedAt",
          key: "orderedAt",
          slots: {
            customRender: "orderedAt",
          },
        },
        {
          title: ta("basicDueDate"),
          dataIndex: "basicDueDate",
          key: "basicDueDate",
          slots: {
            customRender: "basicDueDate",
          },
        },
        {
          title: ta("clientUserGroup"),
          dataIndex: "clientUserGroup",
          key: "clientUserGroup",
          slots: {
            customRender: "clientUserGroup",
          },
        },
        {
          title: "",
          key: "action",
          slots: {
            customRender: "action",
          },
          class: "cell-action",
        },
      ];

      if (unref(stage) === "productions-dtag") {
        _columns.splice(1, 0, {
          title: ta("janCode"),
          dataIndex: "order.janCode",
          key: "order.janCode",
        });
      }

      return _columns;
    });

    const orderApproveRecords = ref(useResult(result));

    const handleRefetch = async () => {
      await refetch();
    };

    const useApprove = () => {
      const { mutate: approve } = useApproveManyOrderApprovalRecordMutation({});

      const state = reactive({
        visbleApproveConfirm: false,
        approveRecord: null as OrderApprovalRecord | null,
      });

      const handleApproveConfirm = (record: OrderApprovalRecord) => {
        state.approveRecord = record;
        state.visbleApproveConfirm = true;
      };

      const handleApprove = async () => {
        assert(state.approveRecord != null);
        await approve({
          input: {
            ids: [state.approveRecord.id],
          },
        });
        await refetch();

        state.approveRecord = null;
        state.visbleApproveConfirm = false;
      };

      return {
        ...toRefs(state),
        handleApproveConfirm,
        handleApprove,
      };
    };

    const useApproveAll = () => {
      const { mutate: approve } = useApproveManyOrderApprovalRecordMutation({});

      const state = reactive({
        approveRecordIds: [] as string[],
      });

      const handleSelectApproveRecord = (selectedRowKeys: string[]) => {
        state.approveRecordIds = selectedRowKeys;
      };

      const checkboxProps = (record: OrderApprovalRecord) => {
        return {
          disabled: record.parent !== null,
        };
      };

      const disableApproveAll = computed(
        () => state.approveRecordIds.length === 0
      );

      const handleApproveAll = async () => {
        await approve({
          input: {
            ids: state.approveRecordIds,
          },
        });
        await refetch();
      };

      return {
        ...toRefs(state),
        disableApproveAll,
        handleApproveAll,
        handleSelectApproveRecord,
        checkboxProps,
      };
    };

    const useReject = () => {
      const {
        mutate: reject,
        onDone,
        onError,
        error,
      } = useRejectOrderApprovalRecordMutation({});

      const state = reactive({
        visbleRejectConfirm: false,
        rejectMessage: null as string | null,
        rejectRecord: null as OrderApprovalRecord | null,
        fileList: [] as FileItem[],
        errorMessages: [] as string[],
      });

      const handleRejectConfirm = (record: OrderApprovalRecord) => {
        state.rejectMessage = null;
        state.rejectRecord = record;
        state.visbleRejectConfirm = true;
      };

      const handleReject = async () => {
        assert(state.rejectRecord != null);
        await reject({
          input: {
            id: state.rejectRecord.id,
            message: state.rejectMessage,
          },
        });
        await refetch();

        state.rejectRecord = null;
        state.visbleRejectConfirm = false;
      };
      const handleCancel = () => {
        state.visbleRejectConfirm = false;
      };

      const { uploadData, errorMessages: importErrorMessages } =
        useUploadResource();

      const beforeUpload = (file: FileItem) => {
        state.fileList = [...state.fileList, file];
        return false;
      };

      const fileUpload = (id: string) => {
        state.fileList.forEach((file) => {
          const fileExt = file.name.split(".").pop();
          uploadData(id, file, fileExt).then(async (result: boolean) => {
            // 非同期に呼び出したいのでawaitにしないこと
            if (result) {
              message.success("否認しました。");
            } else {
              const errorMessages = unref(importErrorMessages);
              Object.keys(errorMessages).forEach((key) => {
                message.error(errorMessages[key]);
              });
            }
          });
        });
      };

      onDone((result) => {
        assert(result.data != null);
        assert(result.data.rejectOrderApprovalRecord != null);
        assert(result.data.rejectOrderApprovalRecord.orderComment != null);
        fileUpload(result.data.rejectOrderApprovalRecord.orderComment.id);
        state.rejectMessage = "";
        state.fileList = [];
      });

      onError(() => {
        state.errorMessages = error.value.message.split(",");
      });

      const handleRemoveFile = (file: FileItem) => {
        const index = state.fileList.indexOf(file);
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);
        state.fileList = newFileList;
      };

      return {
        ...toRefs(state),
        handleReject,
        handleRejectConfirm,
        beforeUpload,
        handleRemoveFile,
        handleCancel,
      };
    };

    return {
      columns,
      orderApproveRecords,
      handleRefetch,
      ...useApprove(),
      ...useApproveAll(),
      ...useReject(),
      formatDate,
      formatTimestamp,
      loading,
      ReloadOutlined,
      formRef,
      moment,
    };
  },
});
</script>
