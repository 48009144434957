import { RouteRecordRaw } from "vue-router";
import NotificationList from "@/client/views/notification/List.vue";
import NotificationDetail from "@/client/views/notification/Detail.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/notifications",
    name: "NotificationList",
    component: NotificationList,
  },
  {
    path: "/notifications/:id",
    name: "NotificationDetail",
    component: NotificationDetail,
  },
];

export default routes;
