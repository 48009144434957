<style lang="less" scoped>
@import "../styles/variable.less";
@antm: .ant-menu;

.menu{
  &-logo,
  &-toggle{
    text-align: center;
  }
  &-logo{
    margin: 15px 0 50px;
  }
  &-toggle{
    margin-bottom: auto;
    &-btn{
      color: @blue01 !important;
      width: 100%;
      border: solid 1px rgba(@blue01, .2) !important;
      &.fold{
        border: none !important;
      }
    }
  }
  &-mainnav{
    margin: auto;
  }
  &-logout{
    margin-top: auto;
  }
}

#sidemenu-nav {
  padding: 0 10px;
  /deep/ @{antm}{
    &-root{
      width: 100% !important;
      border-right: none;
    }
    @{antm}-title-content{
      position: relative;
      .anticon-right{
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
    &-inline{
      @{antm}{
        &-item{
          width: 100%;
          &.top-level{
            &@{antm}{
              &-item{
                &-active{
                  background: @blue02;
                  &:hover{
                    a, path{
                      color: @clear-black05;
                    }
                  }
                }
                &-selected{
                  background: @blue02;
                  color: @white01;
                  & > *{
                    position: relative;
                    z-index: 2;
                  }
                  &:after{
                    background: @blue01;
                    width: calc(100% - 8px);
                    height: calc(100% - 8px);
                    margin: auto;
                    border: none;
                    left: 0;
                  }
                  a, path{
                    color: @white01;
                  }
                  &:hover{
                    a, path{
                      color: @white01;
                    }
                  }
                }
              }
            }
          }
        }
        &-submenu{
          @{antm}{
            &-submenu{
              &-title{
                width: 100%;
                &:hover{
                  background: @blue02;
                  color: @clear-black05;
                  @{antm}-submenu-arrow{
                    color: @clear-black05;
                  }
                }
              }
            }
            &-item-icon{
              color: @clear-black05;
            }
            &-sub{
              &@{antm}-inline{
                background: none;
              }
            }
          }
          &@{antm}-submenu{
            @{antm}{
              &-item{
                background: @blue01;
                &-selected {
                  background: @blue02;
                  color: @white01;
                  & > *{
                    position: relative;
                    z-index: 2;
                  }
                  &:after{
                    background: @blue01;
                    width: calc(100% - 8px);
                    height: calc(100% - 8px);
                    margin: auto;
                    border: none;
                    left: 0;
                  }
                  a, path{
                    color: @white01;
                  }
                  &:hover{
                    a, path{
                      color: @white01;
                    }
                  }
                  @{antm}{
                    &-item{
                      background: none;
                      &:hover{
                        background: @blue02;
                      }
                    }
                  }
                }
              }
            }
            &-open{
              //background: @blue02;
              position: relative;
              & > *{
                position: relative;
                z-index: 2;
              }
              &:after{
                content: "";
                //background: @blue01;
                width: calc(100% - 8px);
                height: calc(100% - 8px);
                margin: auto;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
              }
              @{antm}{
                &-item{
                  background: none;
                  &:hover{
                    background: @blue02;
                  }
                }

                &-submenu{
                  &-title{
                    &:hover{
                      background: @blue02;
                      @{antm}-submenu-arrow{
                        background: @blue02;
                      }
                    }
                    &:active{
                      background: @blue01;
                    }
                  }
                }
              }
            }
            &-selected{
              color: @blue01;
            }
          }
        }
      }
    }
    &-vertical{
      @{antm}{
        &-item{
          text-align: center;
          padding: 0;
          &-icon{
            display: inline-block;
            width: 40px;
          }
          &.top-level{
            padding: 10px 0;
            height: auto;
            &.ant-tooltip-open{
              @{antm}{
                &-item-icon{
                  background: @blue02;
                }
              }
            }
            &@{antm}{
              &-item{
                &-selected{
                  @{antm}{
                    &-item-icon{
                      background: @blue01;
                      path{
                        color: @white01;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &-submenu{
          @{antm}{
            &-submenu{
              &-title{
                text-align: center;
                height: auto;
                padding: 10px 0;
              }
            }
            &-item-icon{
              color: @clear-black05;
            }
          }
          &@{antm}-submenu{
            &-open{
              @{antm}{
                &-item-icon{
                  background: @blue02;
                  path{
                    color: @clear-black05;
                  }
                }
              }
            }
            &-selected,
            &.is-active{
              background: @blue02;
              @{antm}{
                &-item-icon{
                  background: @blue01;
                  path{
                    color: @white01;
                  }
                }
              }
            }
          }
        }
        &-title-content{
          display: none;
        }
      }
    }
  }
}
</style>

<style lang="less">
@import "../styles/variable.less";
@antm: .ant-menu;

.popup {
  @{antm} {
    &-item {
      &-active {
        //color: @blue01;
        @{antm} {
          &-title-content {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
}
.ant-menu-submenu-popup{
  @{antm}{
    &@{antm}-vertical{
      background: @blue01;
      @{antm}{
        &-item{
          background: none;
          height: auto;
          @{antm}-title-content{
            padding-right: 20px !important;
            position: relative;
            .anticon-right{
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
            }
          }
          a{
            color: @white01;
          }
          &[data-menu-id="index-link"]{
            margin-bottom: 20px;
            @{antm}-title-content{
              padding: 0;
              font-weight: bold;
              border-bottom: solid 1px @clear-white01;
            }
          }
          &.sub-link{
            margin: 0;
            @{antm}-title-content{
              padding-left: 20px;
              position: relative;
              &:before, &:after{
                content: "";
                position: absolute;
                display: block;
                border-left: solid 1px rgba(@white01, .3);
                width: 1px;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
              }
              &:before{
                height: 100%;
              }
              &:after{
                width: 12px;
                height: 10px;
                border-radius:  0 0 0 10px;
                border-bottom: solid 1px rgba(@white01, .3);
                transform: translateY(-90%);
              }
            }
            &:last-of-type{
              @{antm}-title-content{
                &:before{
                  height: 30%;
                  top: 0;
                  transform: translateY(0);
                }
              }
            }
          }
        }
        &-title-content{
          display: block;
          a{
            display: block;
          }
        }
      }
    }
  }
}
.ant-tooltip{
  .ant-tooltip{
    &-arrow-content,
    &-inner{
      background: @blue01;
    }
    &-inner{
      a{
        color: @white01;
      }
    }
  }
}
</style>
<template>
  <div>
    <div class="menu-logo">
      <Logo :size="logoSize" />
    </div>
    <div class="menu-toggle">
      <a-button
        class="menu-toggle-btn"
        type="link"
        @click="toggleCollapsed"
        v-bind:class="collapsed ? 'fold' : ''"
      >
        <MenuUnfoldOutlined v-if="collapsed" />
        <MenuFoldOutlined v-else />
      </a-button>
    </div>
    <a-menu
      mode="inline"
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      class="menu-mainnav"
    >
      <a-menu-item key="home" :inlineCollapsed="collapsed" class="top-level">
        <template #icon>
          <router-link to="/top/" @click="onSelect" data-select="home"
            ><HomeOutlined
          /></router-link>
        </template>
        <router-link to="/top/" @click="onSelect" data-select="home"
          >Home</router-link
        >
      </a-menu-item>
      <a-sub-menu
        key="products"
        v-if="isStaff"
        popupClassName="popup"
        v-bind:class="menuAddClass('products')"
      >
        <template #icon>
          <router-link
            :to="{ name: 'ProductList' }"
            @click="onSelect"
            data-select="products"
            ><ItemListIcon
          /></router-link>
        </template>
        <template #title>商品一覧</template>
        <a-menu-item key="index-link">
          <router-link :to="{ name: 'ProductList' }">
            <template v-if="collapsed">商品一覧</template>
            <template v-else>すべて</template>
            <RightOutlined />
          </router-link>
        </a-menu-item>
        <template
          v-for="productCategory in productCategories"
          :key="productCategory.id"
        >
          <a-menu-item class="sub-link">
            <router-link
              :to="{
                name: 'ProductListByCategory',
                params: { categoryId: productCategory.id },
              }"
              >{{ productCategory.name }}
              <RightOutlined />
            </router-link>
          </a-menu-item>
        </template>
      </a-sub-menu>
      <a-menu-item key="storageFiles" class="top-level">
        <template #icon>
          <router-link
            to="/storageFiles"
            @click="onSelect"
            data-select="storageFiles"
          >
            <DatabaseOutlined />
          </router-link>
        </template>
        <router-link
          to="/storageFiles"
          @click="onSelect"
          data-select="storageFiles"
          >ストレージ一覧</router-link
        >
      </a-menu-item>
      <a-menu-item key="orders" class="top-level">
        <template #icon>
          <router-link to="/orders" @click="onSelect" data-select="orders"
            ><OrderListIcon
          /></router-link>
        </template>
        <router-link to="/orders" @click="onSelect" data-select="orders"
          >注文一覧</router-link
        >
      </a-menu-item>
      <a-menu-item key="drafts" class="top-level">
        <template #icon>
          <router-link to="/drafts" @click="onSelect" data-select="drafts"
            ><FormOutlined
          /></router-link>
        </template>
        <router-link to="/drafts" @click="onSelect" data-select="drafts"
          >下書き一覧</router-link
        >
      </a-menu-item>
      <a-menu-item key="approvals" class="top-level">
        <template #icon>
          <router-link to="/approvals" @click="onSelect" data-select="approvals"
            ><CheckCircleOutlined
          /></router-link>
        </template>
        <router-link to="/approvals" @click="onSelect" data-select="approvals"
          >承認一覧</router-link
        >
      </a-menu-item>
      <!-- <a-menu-item key="notifications" class="top-level">
        <router-link to="/notifications">お知らせ一覧</router-link>
      </a-menu-item> -->
      <a-sub-menu
        key="settings"
        v-if="isManager"
        popupClassName="popup"
        v-bind:class="menuAddClass('settings')"
      >
        <template #icon>
          <router-link
            :to="{ name: 'SettingsClientUserList' }"
            @click="onSelect"
            data-select="settings"
            ><SettingIcon
          /></router-link>
        </template>
        <template #title>管理メニュー</template>
        <a-menu-item key="clientUser">
          <router-link :to="{ name: 'SettingsClientUserList' }">{{
            $tr("clientUser")
          }}</router-link>
        </a-menu-item>
        <a-menu-item key="clientUserGroup">
          <router-link :to="{ name: 'SettingsClientUserGroupList' }">
            {{ $tr("clientUserGroup") }}
          </router-link>
        </a-menu-item>
        <a-menu-item key="storage">
          <router-link :to="{ name: 'SettingsClientStorageFileList' }"
            >ストレージ</router-link
          >
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
    <a-menu
      mode="inline"
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      class="menu-logout"
      v-if="currentUser"
    >
      <a-sub-menu
        key="user"
        popupClassName="popup"
        v-bind:class="menuAddClass('user')"
      >
        <template #icon>
          <router-link to="" @click="onSelect" data-select="user"
            ><UserIcon
          /></router-link>
        </template>
        <template #title>{{ currentUser.name }}</template>
        <!-- <a-menu-item key="profile">
          <router-link to="">ユーザー情報</router-link>
        </a-menu-item> -->
        <a-menu-item key="logout">
          <router-link to="/logout">ログアウト</router-link>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  SetupContext,
  toRefs,
  watch,
  computed,
} from "vue";
import { useGetSession } from "@/client/modules/session";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  CheckCircleOutlined,
  RightOutlined,
  DatabaseOutlined,
  FormOutlined,
} from "@ant-design/icons-vue";
import ItemListIcon from "@/shared/components/svg-icon/ItemList.vue";
import OrderListIcon from "@/shared/components/svg-icon/OrderList.vue";
import UserIcon from "@/shared/components/svg-icon/User.vue";
import SettingIcon from "@/shared/components/svg-icon/Setting.vue";
import Logo from "@/shared/components/Logo.vue";
import { useGetManyProductCategoryQuery } from "@/client/modules/api";
import { GetManyProductCategoryQuery } from "@/client/schema";
import { useResult } from "@vue/apollo-composable";

type Props = {
  collapsed: boolean;
};

export default defineComponent({
  components: {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    HomeOutlined,
    CheckCircleOutlined,
    RightOutlined,
    ItemListIcon,
    DatabaseOutlined,
    FormOutlined,
    OrderListIcon,
    UserIcon,
    SettingIcon,
    Logo,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:collapsed"],
  setup(props: Props, { emit }: SetupContext) {
    const { result } = useGetManyProductCategoryQuery({});

    const logoSize = computed(() => (props.collapsed ? "s" : "l"));

    const productCategories =
      useResult<GetManyProductCategoryQuery, "productCategories">(result);

    const { currentUser, isManager, isStaff } = useGetSession();
    const state = reactive({
      collapsed: props.collapsed,
      openKeys: [],
      preOpenKeys: [],
      selectedKeys: [] as string[],
    });
    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      }
    );
    const toggleCollapsed = () => {
      state.collapsed = !state.collapsed;
      // サイドナビの開閉状態管理のBoolを保存
      localStorage["toggleCollapsed"] = state.collapsed;
      state.openKeys = state.collapsed ? [] : state.preOpenKeys;
      emit("update:collapsed", state.collapsed);
    };
    const onSelect = (obj: any) => {
      const selected = obj.currentTarget.getAttribute("data-select");
      state.selectedKeys = [];
      state.selectedKeys = [selected];
    };
    const menuAddClass = (key: string) => {
      return state.selectedKeys.indexOf(key) !== -1 ? "is-active" : "";
    };

    return {
      ...toRefs(state),
      productCategories,
      currentUser,
      isManager,
      isStaff,
      toggleCollapsed,
      onSelect,
      menuAddClass,
      logoSize,
    };
  },
});
</script>
