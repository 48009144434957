<style lang="less" scoped></style>
<template>
  <div>
    <Content
      class="content-head"
      :style="{
        margin: 0,
        padding: '30px 0 30px 30px',
        height: 'auto',
      }"
    >
      <h1 class="content-head-title">下書き一覧</h1>
      <UserName class="content-head-username"></UserName>
    </Content>
    <div class="content-layout">
      <OrderList :draftOnly="true"></OrderList>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Content } from "@/generic";
import OrderList from "@/client/components/order/List.vue";
import UserName from "@/shared/components/CurrentUserName.vue";

export default defineComponent({
  components: {
    Content,
    OrderList,
    UserName,
  },
});
</script>
