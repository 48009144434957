
import { defineComponent, computed, unref, SetupContext } from "vue";
import { Prefecture } from "@/client/schema";

import { InputZipCode } from "@/generic";
import { usePostalCode } from "@/shared/modules/postalCode";

type Props = {
  model: any;
  disabled: boolean;
  fax: boolean;
};

export default defineComponent({
  components: {
    InputZipCode,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    fax: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["change"],
  setup(props: Props, context: SetupContext) {
    const { searchAddress } = usePostalCode();

    const prefectures = Object.values(Prefecture);
    const inputDisabled = computed(() => {
      return props.disabled;
    });

    const handleSearch = async () => {
      const _addressModel = unref(props.model);
      if (_addressModel.zipCode == null || _addressModel.zipCode.length === 0)
        return;

      const result = await searchAddress(unref(props.model).zipCode);

      if (result.length > 0) {
        const address = result[0];
        _addressModel.prefecture = prefectures[Number(address.prefCode) - 1];
        _addressModel.city = address.city;
        _addressModel.block = address.town;
        _addressModel.building = address.office || "";
      }

      handleBlur();
    };

    const handleBlur = () => {
      context.emit("change", unref(props.model));
    };

    return {
      prefectures,
      inputDisabled,
      handleSearch,
      handleBlur,
    };
  },
});
