
import { computed, defineComponent, unref, SetupContext } from "vue";
import { useResult } from "@vue/apollo-composable";
import { useGetManyProductCategoryQuery } from "@/client/modules/api";
import { GetManyProductCategoryQuery } from "@/client/schema";
import { DownOutlined } from "@ant-design/icons-vue";

type Props = {
  value: string;
};

export default defineComponent({
  components: {
    DownOutlined,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  emits: ["update:value", "change"],
  setup(props: Props, { emit }: SetupContext) {
    const { result } = useGetManyProductCategoryQuery({});

    const productCategories =
      useResult<GetManyProductCategoryQuery, "productCategories">(result);

    const current = computed((): string => {
      if (!props.value) return "すべて";
      return (
        unref(productCategories)?.find(
          (productCategory) => productCategory.id === props.value
        )?.name || "すべて"
      );
    });

    const handleClick = (key: string) => {
      //emit("update:value", key);
      emit("change", key);
    };

    return {
      productCategories,
      handleClick,
      current,
    };
  },
});
