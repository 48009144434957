
import { defineComponent } from "vue";
import { useResult } from "@vue/apollo-composable";
import { useGetOneClientStorageSummaryQuery } from "@/client/modules/api";

export default defineComponent({
  components: {},
  setup() {
    const { result, loading } = useGetOneClientStorageSummaryQuery({});
    const storageSummary = useResult(result);

    const percentStyle = (storageRatio: number) => {
      if (storageRatio >= 90) {
        return "storage-summary-percent-warning";
      } else if (storageRatio >= 70) {
        return "storage-summary-percent-caution";
      } else {
        return "storage-summary-percent-normal";
      }
    };

    return {
      loading,
      storageSummary,
      percentStyle,
    };
  },
});
