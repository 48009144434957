<style lang="less" scoped>
@import "../../styles/variable.less";
@antt: .ant-tabs;
@{antt}{
  /deep/ @{antt}{
    &-bar{
      margin-bottom: 20px;
      .searchtoggle-btn{
        &.tool-active{
          color: @gray01;
        }
        .anticon{
          margin-right: 5px;
        }
      }
    }
    &-tabpane{
      .search-tag{
        background: @white03;
        display: flex;
        padding: 20px;
        margin-bottom: 20px;
        border: solid 1px @clear-black02;
        & > *{
          white-space: nowrap;
          flex: 0 1 auto;
          margin: 5px 0 0 0;
        }
        &-title{
          font-weight: bold;
          margin-right: 10px;
        }
        &-list{
          font-size: 12px;
          white-space: normal;
          padding: 0;
          margin: -10px 0 0 -10px;
        }
        &-item{
          display: inline-block;
          color: @blue01;
          border: solid 1px @blue01;
          margin: 10px 0 0 10px;
          padding: 5px 10px;
          border-radius: 3em;
        }
        &-hit, &-cancel{
          padding-left: 10px;
        }
        &-hit{
          color: @clear-black01;
        }
        &-cancel{
          color: @gray01;
          margin-left: auto;
        }
      }
    }
  }
}
</style>
<template>
  <div>
    <Content
      class="content-head"
      :style="{
        margin: 0,
        padding: '30px 0 30px 30px',
        height: 'auto',
      }"
    >
      <h1 class="content-head-title">ストレージ一覧</h1>
      <UserName class="content-head-username"></UserName>
    </Content>
    <div class="content-layout">
      <Content
        :style="{
          margin: 0,
          padding: '20px',
          height: 'auto',
        }"
      >
        <a-tabs
          v-model:activeKey="currentUserGroupId"
          :defaultActiveKey="defaultUserGroupId"
        >
          <a-tab-pane tab="全体">
            <StorageFileList></StorageFileList>
          </a-tab-pane>
          <template v-for="userGroup in currentUserGroups" :key="userGroup.id">
            <a-tab-pane :tab="userGroup.name" :forceRender="true">
              <StorageFileList
                :key="userGroup.id"
                :clientUserGroupId="userGroup.id"
              ></StorageFileList>
            </a-tab-pane>
          </template>
        </a-tabs>
      </Content>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, unref } from "vue";
import { Content } from "@/generic";
import UserName from "@/shared/components/CurrentUserName.vue";
import StorageFileList from "@/client/components/storageFile/List.vue";
import { useGetSession } from "@/client/modules/session";

export default defineComponent({
  components: {
    Content,
    UserName,
    StorageFileList,
  },
  setup() {
    const { currentUser } = useGetSession();

    const currentUserGroups = computed(
      () => unref(currentUser)?.userGroups || []
    );
    const defaultUserGroupId = computed(
      () => unref(currentUser)?.userGroups?.[0]?.id || null
    );
    const currentUserGroupId = ref(undefined as string | null | undefined);

    return {
      currentUserGroups,
      currentUserGroupId,
      defaultUserGroupId,
    };
  },
});
</script>
