<style lang="less" scoped>
@import "../../styles/variable.less";
@antt: .ant-tabs;
@{antt}{
  /deep/ @{antt}{
    &-bar{
      margin-bottom: 20px;
      .searchtoggle-btn{
        &.tool-active{
          color: @gray01;
        }
        .anticon{
          margin-right: 5px;
        }
      }
    }
    &-tabpane{
      .search-tag{
        background: @white03;
        display: flex;
        padding: 20px;
        margin-bottom: 20px;
        border: solid 1px @clear-black02;
        & > *{
          white-space: nowrap;
          flex: 0 1 auto;
          margin: 5px 0 0 0;
        }
        &-title{
          font-weight: bold;
          margin-right: 10px;
        }
        &-list{
          font-size: 12px;
          white-space: normal;
          padding: 0;
          margin: -10px 0 0 -10px;
        }
        &-item{
          display: inline-block;
          color: @blue01;
          border: solid 1px @blue01;
          margin: 10px 0 0 10px;
          padding: 5px 10px;
          border-radius: 3em;
        }
        &-hit, &-cancel{
          padding-left: 10px;
        }
        &-hit{
          color: @clear-black01;
        }
        &-cancel{
          color: @gray01;
          margin-left: auto;
        }
      }
    }
  }
}
</style>
<template>
  <div>
    <Content
      class="content-head"
      :style="{
        margin: 0,
        padding: '30px 0 30px 30px',
        height: 'auto',
      }"
    >
      <h1 class="content-head-title">商品一覧</h1>
      <ProductCategorySelect
        :value="categoryId"
        @change="handleChange"
      ></ProductCategorySelect>
      <UserName class="content-head-username"></UserName>
    </Content>
    <div class="content-layout">
      <Content
        :style="{
          margin: 0,
          padding: '20px',
          height: 'auto',
        }"
      >
        <a-tabs
          v-model:activeKey="currentUserGroupId"
          :defaultActiveKey="defaultUserGroupId"
        >
          <template v-for="userGroup in currentUserGroups" :key="userGroup.id">
            <a-tab-pane :tab="userGroup.name" :forceRender="true">
              <!-- <SearchTool
                v-model:selectedTags="selectedTags"
                v-if="searchTool"
              />
              <div class="search-tag" v-if="selectedTags.length">
                <p class="search-tag-title">こちらで検索中 :</p>
                <ul class="search-tag-list">
                  <li
                    class="search-tag-item"
                    v-for="tags in selectedTags"
                    :key="tags"
                  >
                    {{ tags }}
                  </li>
                </ul>
                <p class="search-tag-hit">100件ヒット</p>
                <a-typography-link
                  class="search-tag-cancel"
                  @click="searchTagClear"
                  @update="selectedTags"
                  >検索キャンセル</a-typography-link
                >
              </div> -->
              <template v-if="categoryId === 'all'">
                <ProductList
                  :key="userGroup.id + categoryId"
                  :clientUserGroupId="userGroup.id"
                ></ProductList>
              </template>
              <template v-else>
                <ProductList
                  :key="userGroup.id + categoryId"
                  :clientUserGroupId="userGroup.id"
                  :productCategoryId="categoryId"
                ></ProductList>
              </template>
            </a-tab-pane>
          </template>
          <!-- <template #tabBarExtraContent>
            <a-typography-link
              class="searchtoggle-btn"
              @click="toggleSearch"
              v-bind:class="{ 'tool-active': searchTool }"
            >
              <SearchOutlined /><span v-if="searchTool">検索ツールを閉じる</span
              ><span v-else>検索ツールを表示</span>
            </a-typography-link>
          </template> -->
        </a-tabs>
      </Content>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, toRefs, unref } from "vue";
import { Content } from "@/generic";
import ProductList from "@/client/components/product/List.vue";
import UserName from "@/shared/components/CurrentUserName.vue";
import ProductCategorySelect from "@/client/components/productCategory/Select.vue";
import { useGetSession } from "@/client/modules/session";
import { useRouter } from "vue-router";
// import SearchTool from "@/client/components/product/SearchTool.vue";
// import { SearchOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    Content,
    ProductList,
    UserName,
    ProductCategorySelect,
    // SearchTool,
    // SearchOutlined,
  },
  props: {
    categoryId: {
      type: String,
      required: true,
    },
    searchTool: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();
    const { currentUser } = useGetSession();

    const currentUserGroups = computed(
      () => unref(currentUser)?.userGroups || []
    );
    const defaultUserGroupId = computed(
      () => unref(currentUser)?.userGroups?.[0]?.id || null
    );
    const currentUserGroupId = ref(undefined as string | null | undefined);
    const handleChange = (categoryId: string) => {
      if (categoryId != null) {
        router.push({
          name: "ProductListByCategory",
          params: { categoryId: categoryId },
        });
      } else {
        router.push({
          name: "ProductList",
        });
      }
    };

    const state = reactive({
      searchTool: false,
    });
    const toggleSearch = () => {
      state.searchTool = !state.searchTool;
    };
    const selectedTags = ref<string[]>([]);
    const searchTagClear = () => {
      selectedTags.value = [];
      if (state.searchTool) {
        toggleSearch();
      }
    };

    return {
      ...toRefs(state),
      currentUserGroups,
      currentUserGroupId,
      defaultUserGroupId,
      handleChange,
      toggleSearch,
      selectedTags,
      searchTagClear,
    };
  },
});
</script>
