<style lang="less" scoped></style>
<template>
  <div>
    <a-list
      :loading="loading"
      item-layout="horizontal"
      :data-source="notifications"
    >
      <template #renderItem="{ item }">
        <router-link
          :to="{
            name: 'NotificationDetail',
            params: {
              id: item.id,
            },
          }"
        >
          <a-list-item>
            <a-list-item-meta>
              <template #title>
                <div>
                  ［{{ formatDate(item.publishedAt) }}］{{ item.title }}
                </div>
              </template>
              <template #description>
                <div>{{ item.content }}</div>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </router-link>
      </template>
    </a-list>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useResult } from "@vue/apollo-composable";
import { useGetManyNotificationQuery } from "@/client/modules/api";
import { formatDate } from "@/generic";

export default defineComponent({
  components: {},
  setup() {
    const { result, loading } = useGetManyNotificationQuery({
      fetchPolicy: "network-only",
    });

    const notifications = useResult(result);

    return {
      notifications,
      loading,
      formatDate,
    };
  },
});
</script>
