<style lang="less" scoped>
.logo {
  width: 100%;
}
</style>
<template>
  <img v-if="size == 'l'" class="logo" src="@/assets/logo-large.png" alt="" />
  <img v-else class="logo" src="@/assets/logo.png" alt="" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    size: {
      type: String as PropType<"s" | "l">,
      required: false,
      default: "l",
    },
  },
});
</script>
