
import { defineComponent, reactive, toRefs } from "vue";
import { Content } from "@/generic";
import UserName from "@/shared/components/CurrentUserName.vue";

import StorageSummary from "@/client/components/settings/storageFile/Summary.vue";
import StorageFileList from "@/client/components/settings/storageFile/List.vue";
import { useGetSession } from "@/client/modules/session";

export default defineComponent({
  components: {
    Content,
    StorageSummary,
    StorageFileList,
    UserName,
  },
  setup() {
    const { currentUser } = useGetSession();
    const state = reactive({
      visibleSearchTool: false,
    });
    const toggleSearch = () => {
      state.visibleSearchTool = !state.visibleSearchTool;
    };
    return {
      ...toRefs(state),
      currentUser,
      toggleSearch,
    };
  },
});
