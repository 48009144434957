<style lang="less" scoped>
@import "../../styles/variable.less";
.full {
  background: @white02;
  align-items: center;
  min-height: 550px;
  height: 100vh;
}
</style>

<template>
  <a-row class="full" justify="center">
    <a-col>
      <SessionCreate></SessionCreate>
    </a-col>
  </a-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SessionCreate from "@/client/components/session/Create.vue";

export default defineComponent({
  components: {
    SessionCreate,
  },
});
</script>
