<style lang="less" scoped>
@import "../../styles/variable.less";
.orderedit {
  .detail-head {
    display: flex;
    align-items: center;
    & > * {
      flex: 0 1 auto;
    }

    &-title {
      width: 650px;
    }
    &-btn {
      width: calc(100% - 710px);
      display: flex;
      justify-content: right;
    }
  }

  &-info {
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    & > * {
      flex: 1 1 auto;
    }
    .info {
      &-row {
        & > * {
          flex: 0 1 auto;
        }
      }
      &-col {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        border-left: solid 1px @clear-black02;
      }
      &-label {
        color: @clear-black06;
        font-size: 12px;
      }
      &-data {
        margin: 0;
        &.bold {
          font-weight: bold;
        }
      }
    }
  }
  &-name {
    margin-top: 20px;
    padding-top: 35px;
    border-top: solid 1px @clear-black02;
    /deep/ .ant-form-item-label {
      & > label {
        font-weight: bold;
        margin-right: 20px;
        &:after {
          content: none;
        }
      }
    }
    /deep/ .ant-input {
      max-width: 600px;
    }
  }
  &-address {
    background: @white03;
    padding: 10px 20px;
    margin-top: 30px;
  }
  &-submits {
    margin-top: 30px;
    padding-top: 20px;
    border-top: solid 1px @clear-black02;

    .btn-blue {
      color: @blue01;
      border-color: @blue01;
      &:hover {
        color: @white01;
        background: @blue01;
      }
    }
  }
  .summary {
    /deep/ .ant-card {
      margin-top: 30px;
      padding: 0 20px 20px;
      &-head {
        padding: 0;
        &-title {
          font-size: 14px;
          font-weight: bold;
        }
      }
      &-extra {
        & > * {
          margin-left: 10px;
        }
      }
      &-body {
        padding: 20px 0 0;
      }
    }

    &-table {
      width: 100%;
    }
    &-head,
    &-data {
      vertical-align: baseline;
      padding: 16px 0;
    }
    &-head {
      width: 1px;
      padding-right: 30px;
      white-space: nowrap;
      &-data {
        font-weight: normal;
      }
    }
    &-data {
      padding: 16px 0;
      border-bottom: solid 1px @clear-black02;
      & > * {
        margin-bottom: 10px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
.create-child-modal-content {
  margin: 10px;
}
</style>
<template>
  <div class="orderedit" v-if="!loading && order != null">
    <div class="detail-head">
      <a-button class="detail-head-back">
        <router-link :to="{ name: 'ProductList' }">
          <LeftOutlined />
        </router-link>
      </a-button>
      <div class="detail-head-title">
        <div v-if="order.parent">
          <router-link
            :to="{ name: 'OrderEdit', params: { id: order.parent.id } }"
            >{{ order.parent.name }}</router-link
          >
          >
        </div>
        <h2 class="detail-head-name">{{ order.product.name }}</h2>
      </div>
      <div class="detail-head-btn">
        <router-link :to="{ name: 'DraftList' }"
          ><a-button size="large">見積もりを保存</a-button></router-link
        >
      </div>
    </div>
    <div class="orderedit-info">
      <template v-if="order.product.thumbnailPath">
        <ImagePreview :preview="order.product" />
      </template>
      <template v-else>
        <ImagePreview :fallback="true" />
      </template>
      <div class="orderedit-info">
        <a-row class="info-row" :gutter="[40, 20]">
          <a-col class="info-col">
            <p class="info-label">本日注文・承認完了で</p>
            <p class="info-data bold">
              {{
                moment(new Date())
                  .add(order.orderProduct.basicDueDate, "d")
                  .format("MM月DD日（dd）")
              }}到着
            </p>
          </a-col>
          <a-col class="info-col">
            <p class="info-label">部数</p>
            <p class="info-data bold">
              <Number :value="order.orderProduct.quantity"></Number>部
            </p>
          </a-col>
          <a-col class="info-col">
            <p class="info-label">金額</p>
            <p class="info-data bold">
              <Currency :value="order.orderProduct.price"></Currency>
            </p>
          </a-col>
          <a-col class="info-col">
            <p class="info-label">送料</p>
            <p class="info-data bold">
              <Currency :value="order.shippingFee"></Currency>
            </p>
          </a-col>
          <a-col class="info-col">
            <p class="info-label">小計</p>
            <p class="info-data bold">
              <Currency :value="order.amount"></Currency>
            </p>
          </a-col>
          <a-col class="info-col">
            <p class="info-label">消費税</p>
            <p class="info-data bold">
              <Currency :value="order.tax"></Currency>
            </p>
          </a-col>
          <a-col class="info-col">
            <p class="info-label">合計金額</p>
            <p class="info-data bold">
              <Currency :value="order.totalAmount"></Currency>
            </p>
          </a-col>
          <a-col class="info-col">
            <p class="info-data">
              <TypesettingButton
                v-if="isTypesettingTag"
                :typesettingParamId="order.orderProduct.typesettingParam.id"
                :typesettingPreviewFlag="typesettingPreviewFlag"
                :forTypesetting="true"
                @change="handleChange"
              ></TypesettingButton>
              <OuterCartonTypesettingButton
                v-if="isOuterCartonLabel"
                :outerCartonParamId="order.orderProduct.outerCartonParam.id"
                :typesettingPreviewFlag="typesettingPreviewFlag"
                :forTypesetting="true"
                @change="handleChange"
              ></OuterCartonTypesettingButton>
              <InnerCartonParamSettingButton
                v-if="isInnerCartonLabel"
                :innerCartonParamId="order.orderProduct.innerCartonParam.id"
                @change="handleChange"
              ></InnerCartonParamSettingButton>
              <PriceLabelParamSettingButton
                v-if="isPriceLabel"
                :priceLabelParamId="order.orderProduct.priceLabelParam.id"
                @change="handleChange"
              ></PriceLabelParamSettingButton>
              <UploadButton
                v-if="isDraft"
                :order="order"
                @change="handleChange"
              ></UploadButton>
            </p>
          </a-col>
          <a-col class="info-col" v-if="order.orderProduct.files.length">
            <p class="info-data">
              <TypesettingButton
                v-if="isTypesettingTag"
                :typesettingParamId="order.orderProduct.typesettingParam.id"
              ></TypesettingButton>
              <OuterCartonTypesettingButton
                v-if="isOuterCartonLabel"
                :outerCartonParamId="order.orderProduct.outerCartonParam.id"
              ></OuterCartonTypesettingButton>
            </p>
          </a-col>
        </a-row>
      </div>
    </div>
    <a-form class="orderedit-name">
      <a-form-item label="製品名設定">
        <a-input
          v-model:value="order.orderProduct.name"
          @change="handleDebounceUpdate"
          placeholder="入力してください"
          :disabled="!(isNormal || isDraft)"
        ></a-input>
      </a-form-item>
      <a-form-item label="製品数量">
        <div v-if="order.product.priceType == 'table'">
          <a-select
            v-model:value="productPriceId"
            style="width: 240px"
            @change="handleUpdate"
          >
            <a-select-option v-for="record in product.prices" :key="record.id">
              <a-row type="flex" justify="space-between">
                <a-col> {{ record.quantity }} 部 </a-col>
                <a-col> {{ record.price.toLocaleString() }} 円 </a-col>
              </a-row>
            </a-select-option>
          </a-select>
        </div>
        <div v-if="order.product.priceType == 'unit'">
          <InputNumber v-model:value="quantity" @change="handleDebounceUpdate">
          </InputNumber>
          部
        </div>
      </a-form-item>
    </a-form>
    <div class="orderedit-address">
      <EditDeliveryList
        :order="order"
        @change="handleChange"
      ></EditDeliveryList>
      <EditContactList :order="order" @change="handleChange"></EditContactList>
    </div>
    <div class="summary" v-if="order.children.length">
      <a-card title="注文合計" bordered>
        <table class="summary-table">
          <tr class="summary-row">
            <th class="summary-head">
              <p class="summary-head-title">この注文</p>
            </th>
            <td class="summary-data">
              <p class="summary-person">
                {{ order.orderProduct.name }}
              </p>
            </td>
            <td class="summary-data">
              <p class="summary-person">
                <Currency :value="order.totalAmount"></Currency>
              </p>
            </td>
          </tr>
          <tr
            class="summary-row"
            v-for="childOrder in order.children"
            :key="childOrder.id"
          >
            <th class="summary-head">
              <p class="summary-head-title">子注文</p>
            </th>
            <td class="summary-data">
              <p class="summary-person">
                <router-link
                  :to="{ name: 'OrderEdit', params: { id: childOrder.id } }"
                >
                  {{ childOrder.orderProduct.name }}
                </router-link>
              </p>
            </td>
            <td class="summary-data">
              <p class="summary-person">
                <Currency :value="childOrder.totalAmount"></Currency>
              </p>
            </td>
          </tr>
          <tr class="summary-row">
            <th class="summary-head">
              <p class="summary-head-title">合計</p>
            </th>
            <td class="summary-data"></td>
            <td class="summary-data">
              <p class="summary-person">
                <Currency :value="order.setAmount"></Currency>
              </p>
            </td>
          </tr>
        </table>
      </a-card>
    </div>

    <OrderCommentList :orderId="orderId"></OrderCommentList>

    <div class="orderedit-submits">
      <a-row type="flex" justify="end" :gutter="10">
        <a-col v-if="order.applicationStatus === 'draft'">
          <a-button size="large" @click="handleRemoveConfirm"
            >削除する</a-button
          >
        </a-col>
        <a-col>
          <router-link :to="{ name: 'DraftList' }"
            ><a-button size="large">見積もりを保存</a-button></router-link
          >
        </a-col>
        <a-col
          v-if="
            !order.parent &&
            order.orderProduct.productType !== 'normal' &&
            order.orderProduct.productType !== 'draft'
          "
        >
          <a-button
            size="large"
            @click="handleVisibleProductList"
            class="btn-blue"
            >紐づく注文を作成</a-button
          >
        </a-col>
        <a-col>
          <router-link :to="{ name: 'OrderConfirm', params: { id: order.id } }"
            ><a-button size="large" type="primary"
              >注文内容を確認する</a-button
            ></router-link
          >
        </a-col>
      </a-row>
    </div>

    <a-modal
      v-model:visible="visibleRemoveModal"
      ok-text="削除"
      @ok="handleRemove"
      width="350px"
      :closable="false"
    >
      <div v-if="order.children.length">
        <p>{{ order.children.length }}個の紐づく注文が同時に削除されます。</p>
      </div>
      <p>削除してよろしいですか？</p>
    </a-modal>
    <a-modal
      v-model:visible="visibleCreateChildModal"
      width="1100px"
      :title="modalTitle"
    >
      <template #footer>
        <a-button
          size="large"
          key="back"
          @click="handleCloseModal"
          v-if="visibleProductList"
          >キャンセル</a-button
        >
        <a-button
          size="large"
          key="back"
          @click="handleVisibleProductList"
          v-if="visibleProductDetail"
          >商品を選び直す</a-button
        >
      </template>
      <div class="create-child-modal-content">
        <ProductList
          v-if="visibleProductList"
          :clientUserGroupId="order.clientUserGroup.id"
          :fromParent="true"
          @select="handleSelectProduct"
        ></ProductList>

        <ProductDetail
          v-if="visibleProductDetail"
          :productId="currentProductId"
          :clientUserGroupId="order.clientUserGroup.id"
          :parentId="order.id"
          @closeModal="handleCloseModal"
        ></ProductDetail>
      </div>
    </a-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed } from "vue";
import { useRouter } from "vue-router";
import {
  assert,
  clone,
  parseError,
  Currency,
  Number,
  debounce,
} from "@/generic";
import {
  useGetOneOrderQuery,
  useUpdateOneOrderMutation,
  useRemoveOneOrderMutation,
} from "@/client/modules/api";
import { userSelectClientContacts, userSelectClientDelivery } from "./module";
import { Order, ProductType } from "@/client/schema";
import EditDeliveryList from "./EditDeliveryList.vue";
import EditContactList from "./EditContactList.vue";
import OrderCommentList from "@/client/components/orderComment/List.vue";
import UploadButton from "./UploadButton.vue";
import TypesettingButton from "./TypesettingButton.vue";
import OuterCartonTypesettingButton from "./OuterCartonTypesettingButton.vue";
import InnerCartonParamSettingButton from "./InnerCartonParamSettingButton.vue";
import PriceLabelParamSettingButton from "./PriceLabelParamSettingButton.vue";
import ProductList from "@/client/components/product/List.vue";
import ProductDetail from "@/client/components/product/Detail.vue";
import { LeftOutlined } from "@ant-design/icons-vue";
import ImagePreview from "@/shared/components/ImagePreview.vue";
import { InputNumber } from "@/generic/components/entry";

import moment from "moment";
import "moment/locale/ja";

moment.locale("ja");

export type Props = {
  orderId: string;
  typesettingPreviewFlag: boolean;
};

export default defineComponent({
  components: {
    Currency,
    Number,
    EditDeliveryList,
    EditContactList,
    OrderCommentList,
    UploadButton,
    TypesettingButton,
    OuterCartonTypesettingButton,
    InnerCartonParamSettingButton,
    PriceLabelParamSettingButton,
    ProductList,
    ProductDetail,
    LeftOutlined,
    ImagePreview,
    InputNumber,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    typesettingPreviewFlag: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props: Props) {
    const router = useRouter();

    const { loading, onResult, refetch } = useGetOneOrderQuery(
      {
        id: props.orderId,
      },
      {
        fetchPolicy: "network-only",
      }
    );

    const state = reactive({
      order: null as null | Order,
      quantity: null as null | number,
      errorMessages: [] as string[],
      productPriceId: null as null | string,
    });

    onResult((result) => {
      state.order = clone(result.data.order) as Order;
      state.productPriceId = state.order.orderProduct.productPrice?.id || null;
      state.quantity = state.order.orderProduct.quantity || null;
    });

    const isNormal = computed(
      () => state.order?.orderProduct?.productType === ProductType.Normal
    );

    const isDraft = computed(
      () => state.order?.orderProduct?.productType === ProductType.Draft
    );

    const isTypesettingTag = computed(
      () =>
        state.order?.orderProduct?.productType === ProductType.TypesettingTag
    );

    const isOuterCartonLabel = computed(
      () =>
        state.order?.orderProduct?.productType === ProductType.OuterCartonLabel
    );

    const isInnerCartonLabel = computed(
      () =>
        state.order?.orderProduct?.productType === ProductType.InnerCartonLabel
    );

    const isPriceLabel = computed(
      () => state.order?.orderProduct?.productType === ProductType.PriceLabel
    );

    const clientUserGroupId = computed(() => state.order?.clientUserGroup?.id);

    const product = computed(() => state.order?.product);

    const useUpdateOrder = () => {
      const { mutate: updateOneOrder, error } = useUpdateOneOrderMutation({});
      const handleUpdate = async () => {
        try {
          const orderSafe = state.order;
          assert(orderSafe != null);
          await updateOneOrder({
            input: {
              id: orderSafe.id,
              name: orderSafe.orderProduct.name as string,
              note: orderSafe.orderProduct.note as string,
              draft: true,
              productPriceId: state.productPriceId,
              quantity: state.quantity,
            },
          });

          await refetch();
        } catch (e) {
          if (error.value == null) throw e;
          state.errorMessages = parseError(error.value);
        }
      };
      const handleDebounceUpdate = debounce(() => {
        handleUpdate();
      });
      return {
        handleUpdate,
        handleDebounceUpdate,
      };
    };

    const useRemoveOrder = () => {
      const { mutate: removeOne } = useRemoveOneOrderMutation({});

      const state = reactive({
        visibleRemoveModal: false,
      });

      const handleRemoveConfirm = async () => {
        state.visibleRemoveModal = true;
      };

      const handleRemove = async () => {
        await removeOne({
          input: {
            id: props.orderId,
          },
        });
        router.push({
          name: "DraftList",
        });
      };

      return {
        ...toRefs(state),
        handleRemoveConfirm,
        handleRemove,
      };
    };

    const useCreateChildOrder = () => {
      const state = reactive({
        visibleCreateChildModal: false,
        modalTitle: null as null | string,
        visibleProductList: false,
        visibleProductDetail: false,
        currentProductId: null as null | string,
      });

      const handleVisibleProductList = () => {
        state.modalTitle = "紐づく注文の商品を選択";
        state.visibleCreateChildModal = true;
        state.visibleProductList = true;
        state.visibleProductDetail = false;
      };

      const handleSelectProduct = (productId: string) => {
        state.modalTitle = "紐づく注文の数量と価格を選択";
        state.currentProductId = productId;
        state.visibleProductList = false;
        state.visibleProductDetail = true;
      };

      const handleCloseModal = () => {
        state.visibleProductList = false;
        state.visibleProductDetail = false;
        state.visibleCreateChildModal = false;
      };

      return {
        ...toRefs(state),
        handleVisibleProductList,
        handleSelectProduct,
        handleCloseModal,
      };
    };

    const handleChange = async () => {
      await refetch();
    };

    return {
      ...toRefs(state),
      loading,
      isNormal,
      isDraft,
      isTypesettingTag,
      isOuterCartonLabel,
      isInnerCartonLabel,
      isPriceLabel,
      product,
      ...userSelectClientContacts(clientUserGroupId),
      ...userSelectClientDelivery(clientUserGroupId),
      ...useUpdateOrder(),
      ...useRemoveOrder(),
      ...useCreateChildOrder(),
      handleChange,
      moment,
    };
  },
});
</script>

<style>
.error-message {
  color: red;
}
</style>
