<style lang="less">
@import "../../styles/variable.less";
.order-upload-modal {
  .ant-list-item {
    margin-bottom: 10px;
    padding: 10px;
    border: solid 1px @clear-black02 !important;
  }
  .ant-image {
    margin-right: 10px;
  }
  .ant-list-item-action {
    margin-left: auto;
  }
  .ant-upload-text {
    font-weight: bold;
  }
  .modal {
    &-inner {
      overflow: hidden;
    }
    &-row {
      margin-bottom: 30px;
      &:nth-child(n + 2) {
        padding-top: 30px;
        border-top: solid 1px @clear-black02;
      }
      &-disabled {
        color: @clear-black03;
        .ant-btn {
          color: @clear-black03;
          &:hover,
          &:focus {
            color: @blue01;
            border-color: @blue01;
          }
        }
      }
    }
    &-col {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-left: solid 1px @clear-black02;
      & > * {
        flex: 0 1 auto;
      }
      .ant-btn {
        &:nth-child(n + 2) {
          margin-left: 20px;
        }
      }
    }
    &-label {
      color: @clear-black03;
      font-size: 10px;
    }
    &-data {
      margin-bottom: 0;
      .anticon {
        margin-right: 10px;
      }
    }
  }
}
</style>
<template>
  <div v-if="order">
    <a-button size="large" type="primary" @click="handleVisibleUpload"
      >入稿データのアップロード</a-button
    >
    <a-modal
      class="order-upload-modal"
      width="600px"
      title="入稿データのアップロード"
      v-model:visible="visibleUpload"
      cancelText="閉じる"
      :okButtonProps="{ style: 'display: none' }"
    >
      <div class="modal-inner">
        <a-row
          :gutter="[40, 20]"
          v-for="file in files"
          :key="file"
          :class="getCurrentClassObject(file)"
        >
          <a-col class="modal-col" :span="24">
            <div class="modal-col-inner">
              <p class="modal-data">
                <PaperClipOutlined />{{
                  file.fileName ||
                  file.thumbnailPath.split("/")[
                    file.thumbnailPath.split("/").length - 1
                  ]
                }}
              </p>
            </div>
          </a-col>
          <a-col class="modal-col">
            <div class="modal-col-inner">
              <p class="modal-label">アップロード日</p>
              <p class="modal-data">{{ formatTimestamp(file.createdAt) }}</p>
            </div>
          </a-col>
          <a-col class="modal-col">
            <div class="modal-col-inner">
              <p class="modal-label">アップロード者</p>
              <p class="modal-data">{{ file.createdBy.name }}</p>
            </div>
          </a-col>
          <a-col class="modal-col">
            <div class="modal-col-inner">
              <a-button
                class="modal-download"
                size="large"
                :href="file.originalPath"
                target="_blank"
                download
                >ダウンロード</a-button
              >
              <a-button
                class="modal-delete"
                size="large"
                danger
                @click="handleRemove(file)"
                v-if="order.orderNo == null"
                >入稿データ削除</a-button
              >
            </div>
          </a-col>
        </a-row>
        <a-upload-dragger
          name="file"
          :multiple="false"
          :before-upload="handleUpload"
          :showUploadList="false"
          v-if="order.orderNo == null"
        >
          <p class="ant-upload-text">
            ここをクリックするか、このエリアにファイルをドラッグで、<br />アップロード
          </p>
        </a-upload-dragger>
      </div>
    </a-modal>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  PropType,
  SetupContext,
} from "vue";
import { Order } from "@/client/schema";
import { message } from "ant-design-vue";
import { useRemoveOneOrderProductFileMutation } from "@/client/modules/api";
export type Props = {
  order: Order;
};

import { useUploadResource } from "@/client/components/orderProductFile/modules/uploadResource";
import { OrderProductFile } from "../../../admin/schema";
import { PaperClipOutlined } from "@ant-design/icons-vue";
import { formatTimestamp } from "@/generic";

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
  preview?: string;
  originFileObj?: any;
  file: string | Blob;
}

export default defineComponent({
  components: {
    PaperClipOutlined,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  emits: ["change"],
  setup(props: Props, { emit }: SetupContext) {
    const { uploadData } = useUploadResource();
    // const isDraft = computed(
    //   () => props.order.orderProduct?.productType === ProductType.Draft
    // );
    const files = computed(() => props.order.orderProduct.files);

    const getCurrentClassObject = (file: OrderProductFile) => {
      const classObject = {
        "modal-row": true,
        "modal-row-disabled": file.disabled,
      } as { [key: string]: boolean };

      return classObject;
    };

    const state = reactive({
      visibleUpload: false,
    });

    const handleVisibleUpload = () => {
      state.visibleUpload = true;
    };

    const handleUpload = (file: FileItem) => {
      const orderProduct = props.order.orderProduct;

      const fileExt = file.name?.split(".").pop() || "uploadFile";

      uploadData(orderProduct.id, file, fileExt).then(
        async (result: boolean) => {
          // 非同期に呼び出したいのでawaitにしないこと
          if (result) {
            emit("change");
          } else {
            message.error("ファイルアップロードに失敗しました");
          }
        }
      );
      return false;
    };

    const useRemoveOneOrderProductFile = () => {
      const { mutate } = useRemoveOneOrderProductFileMutation({});

      const handleRemove = async (file: OrderProductFile) => {
        await mutate({
          input: {
            id: file.id,
          },
        });
        emit("change");
      };

      return {
        handleRemove,
      };
    };

    return {
      ...toRefs(state),
      getCurrentClassObject,
      handleVisibleUpload,
      handleUpload,
      // isDraft,
      files,
      ...useRemoveOneOrderProductFile(),
      formatTimestamp,
    };
  },
});
</script>
