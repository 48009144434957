import { RouteRecordRaw } from "vue-router";
// import Top from "@/client/views/top/List.vue"; // Topのデザインが決まるまではproduct/List.vueを使用
import ProductList from "@/client/views/product/List.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/top",
    name: "Top",
    // component: Top,
    component: ProductList,
  },
];

export default routes;
