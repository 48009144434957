<template>
  <span role="img" class="anticon">
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.875 7.49979C0.875 3.8421 3.84015 0.876953 7.49784 0.876953C11.1555 0.876953 14.1207 3.8421 14.1207 7.49979C14.1207 11.1574 11.1555 14.1226 7.49784 14.1226C3.84015 14.1226 0.875 11.1574 0.875 7.49979ZM7.49784 1.82695C4.36482 1.82695 1.825 4.36677 1.825 7.49979C1.825 8.97187 2.38573 10.313 3.30526 11.3212C4.18873 9.9411 5.73617 9.0249 7.49822 9.0249C9.26005 9.0249 10.8073 9.94088 11.6909 11.3207C12.6101 10.3126 13.1707 8.97163 13.1707 7.49979C13.1707 4.36677 10.6308 1.82695 7.49784 1.82695ZM10.9798 11.9786C10.2819 10.7794 8.98369 9.9749 7.49822 9.9749C6.01257 9.9749 4.71423 10.7796 4.01644 11.979C4.97751 12.7271 6.18564 13.1726 7.49784 13.1726C8.81026 13.1726 10.0186 12.7269 10.9798 11.9786ZM5.14798 6.50478C5.14798 5.20691 6.20011 4.15478 7.49798 4.15478C8.79585 4.15478 9.84798 5.20691 9.84798 6.50478C9.84798 7.80265 8.79585 8.85478 7.49798 8.85478C6.20011 8.85478 5.14798 7.80265 5.14798 6.50478ZM7.49798 5.10478C6.72478 5.10478 6.09798 5.73158 6.09798 6.50478C6.09798 7.27798 6.72478 7.90478 7.49798 7.90478C8.27118 7.90478 8.89798 7.27798 8.89798 6.50478C8.89798 5.73158 8.27118 5.10478 7.49798 5.10478Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>
