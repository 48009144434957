
import { defineComponent, SetupContext, unref, PropType, ref } from "vue";
import { message } from "ant-design-vue";
import { useUploadResource } from "@/client/components/orderComment/modules/uploadResource";
import { formatTimestamp } from "@/generic";
import { OrderComment } from "@/client/schema";

export type Props = {
  comment: OrderComment;
};

export default defineComponent({
  props: {
    comment: {
      type: Object as PropType<OrderComment>,
      required: true,
    },
  },
  emits: ["refetch"],
  setup(props: Props, context: SetupContext) {
    const commentData = ref(props.comment);
    const { uploadData, errorMessages: importErrorMessages } =
      useUploadResource();

    const appendFile = async (file: File) => {
      const fileExt = file.name.split(".").pop();

      uploadData(commentData.value.id, file, fileExt).then(
        async (result: boolean) => {
          // 非同期に呼び出したいのでawaitにしないこと
          if (result) {
            context.emit("refetch");
            message.success("アップロードしました。");
          } else {
            const errorMessages = unref(importErrorMessages);
            Object.keys(errorMessages).forEach((key) => {
              message.error(errorMessages[key]);
            });
          }
        }
      );
      return false;
    };

    const commentColor = (commentType: string) => {
      if (commentType === "reject") {
        return "reject-comment";
      } else {
        return "";
      }
    };

    return {
      commentData,
      formatTimestamp,
      appendFile,
      commentColor,
    };
  },
});
