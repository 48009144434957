
import { defineComponent, inject, reactive, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";
import Logo from "@/shared/components/Logo.vue";
import {
  UseSessionKey,
  UseSessionStore,
  CreateSessionInput,
} from "@/client/modules/session";

export default defineComponent({
  components: {
    Logo,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const state = reactive({
      input: {
        username: null,
        password: null,
      } as CreateSessionInput,
      errorMessage: null as string | null,
    });

    const { create, loading } = inject(UseSessionKey) as UseSessionStore;

    const handleCreateSession = async () => {
      if (!(await create(state.input))) {
        state.errorMessage = "ログインに失敗しました。";
      } else {
        const redirect = route.query["redirect"] as string | null;
        if (redirect == null) {
          router.push({ name: "Home" });
        } else {
          router.push({ path: redirect });
        }
      }
    };

    return {
      ...toRefs(state),
      loading,
      handleCreateSession,
    };
  },
});
