
import { defineComponent, SetupContext, toRefs, reactive, PropType } from "vue";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons-vue";
import { OrderProductFile } from "../../schema";

export type Props = {
  orderProductFile: OrderProductFile;
};

export default defineComponent({
  components: {
    DeleteOutlined,
    EyeOutlined,
  },
  props: {
    orderProductFile: {
      type: Object as PropType<OrderProductFile>,
      required: true,
    },
  },
  emits: ["preview", "remove"],
  setup(props: Props, context: SetupContext) {
    console.log(props.orderProductFile);
    const state = reactive({
      preview: false,
    });
    const handlePreview = () => {
      state.preview = true;
      //context.emit("preview");
    };

    const handleRemove = () => {
      context.emit("remove");
    };

    return {
      ...toRefs(state),
      handlePreview,
      handleRemove,
    };
  },
});
