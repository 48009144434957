<template>
  <div>
    <Content
      class="content-head"
      :style="{
        margin: 0,
        padding: '30px 0 30px 30px',
        height: 'auto',
      }"
    >
      <h1 class="content-head-title">商品注文</h1>
      <UserName class="content-head-username"></UserName>
    </Content>
    <div class="content-layout">
      <Content
        :style="{
          margin: 0,
          padding: '20px',
          height: 'auto',
        }"
      >
        <OrderEdit :orderId="id" :typesettingPreviewFlag="true"></OrderEdit>
      </Content>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Content } from "@/generic";
import OrderEdit from "@/client/components/order/Edit.vue";
import UserName from "@/shared/components/CurrentUserName.vue";

export default defineComponent({
  components: {
    Content,
    OrderEdit,
    UserName,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
});
</script>
