<style lang="less" scoped>
@import "../../styles/variable.less";
.comment-block {
  margin-top: 40px;
  &-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .ant-list {
    overflow: auto;
    height: 200px;
    border-top: solid 1px @clear-black02;
    /deep/ .ant-list-items {
      .ant-comment {
        border-bottom: solid 1px @clear-black02;
      }
    }
  }
  .ant-comment {
    .errors {
      margin: 0;
    }
    .error-message {
      margin-top: 10px;
    }
    .comment-send {
      background: @white03;
      border: solid 1px @clear-black02;
      border-top: none;
    }
    .filebtn {
      background: none;
      border: none;
      /deep/ path {
        fill: @clear-black03;
      }
    }
  }
}
/deep/ .ant-upload.ant-upload-select-picture {
  order: 2;
  background: @white03;
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding: 20px;
  border: 1px dashed @clear-black02;
  border-radius: 2px;
  &:hover {
    cursor: pointer;
    border-color: @blue01;
  }
}
/deep/ .ant-upload-list-picture .ant-upload-list-item,
/deep/ .ant-upload-list-picture-card .ant-upload-list-item {
  margin: 0 0 10px 0;
}
.comment {
  white-space: pre-wrap;
}
</style>
<style lang="less">
.comment-upload-modal .ant-modal-body > * {
  display: flex;
  flex-wrap: wrap;
  & > * {
    flex: 1 1 100%;
  }
}
</style>
<template>
  <div class="comment-block" v-if="!loading && !commentLoading">
    <h2 class="comment-block-title">コメント</h2>
    <a-list
      v-if="comments && comments.length > 0"
      class="comment-list"
      :data-source="comments"
      item-layout="horizontal"
      :row-key="(record) => record.id"
    >
      <template #renderItem="{ item }">
        <CommentDetail
          :comment="item"
          @refetch="commentRefetch"
        ></CommentDetail>
      </template>
    </a-list>
    <a-comment>
      <template #content>
        <a-textarea :rows="4" v-model:value="comment" />
        <ul class="errors">
          <li
            v-for="message in errorMessages"
            class="error-message"
            :key="message"
          >
            {{ message }}
          </li>
        </ul>
        <a-row type="flex" class="comment-send">
          <a-col flex="auto">
            <!-- <a-upload
              :file-list="fileList"
              :remove="handleRemoveFile"
              :before-upload="beforeUpload"
            >
              <a-button>
                <PaperClipOutlined />
              </a-button>
            </a-upload> -->
            <a-button class="filebtn" @click="showModal">
              <PaperClipOutlined />
              {{ fileList.map((file) => file.name).join(", ") }}
            </a-button>
          </a-col>
          <a-col>
            <a-button
              html-type="submit"
              :loading="addLoading"
              type="primary"
              @click="handleCreate"
              :disabled="!comment"
            >
              送信<SubmitIcon />
            </a-button>
          </a-col>
        </a-row>
      </template>
    </a-comment>
    <a-modal
      class="comment-upload-modal"
      title="画像やファイルをアップロード"
      cancelText="キャンセル"
      okText="変更して保存"
      v-model:visible="visible"
      @ok="handleOk"
    >
      <a-upload
        list-type="picture"
        :file-list="fileList"
        :remove="handleRemoveFile"
        :before-upload="beforeUpload"
      >
        <p>
          ここをクリックするか、このエリアにファイルをドラッグで、<br />アップロード
        </p>
      </a-upload>
    </a-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, unref, ref } from "vue";
import { useResult } from "@vue/apollo-composable";
import { message } from "ant-design-vue";
import { useUploadResource } from "@/client/components/orderComment/modules/uploadResource";
import {
  useGetOneOrderQuery,
  useCreateOneOrderCommentMutation,
  useGetManyOrderCommentQuery,
} from "@/client/modules/api";
import CommentDetail from "@/client/components/orderComment/Detail.vue";
import { assert } from "@/generic";
import { PaperClipOutlined } from "@ant-design/icons-vue";
import SubmitIcon from "@/shared/components/svg-icon/Submit.vue";

export type Props = {
  orderId: string;
};

interface FileItem {
  uid: string;
  name: string;
  status?: string;
  response?: string;
  url?: string;
  thumbUrl?: string;
  preview?: string;
  originFileObj?: any;
  file: string | Blob;
}

export default defineComponent({
  components: {
    CommentDetail,
    PaperClipOutlined,
    SubmitIcon,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const useGetOneOrder = () => {
      const { result, loading } = useGetOneOrderQuery({
        id: props.orderId,
      });

      const order = useResult(result);

      return {
        order,
        loading,
      };
    };

    const useGetManyOrderComment = () => {
      const {
        result: commentResult,
        loading: commentLoading,
        refetch: commentRefetch,
      } = useGetManyOrderCommentQuery({
        orderId: props.orderId,
      });
      const comments = useResult(commentResult);
      return {
        comments,
        commentLoading,
        commentRefetch,
      };
    };
    const useGetManyOrderCommentResult = useGetManyOrderComment();

    const useCreateComment = () => {
      const state = reactive({
        fileList: [] as FileItem[],
        comment: null as string | null,
        errorMessages: [] as string[],
      });

      const {
        mutate,
        loading: addLoading,
        error,
        onError,
        onDone,
      } = useCreateOneOrderCommentMutation({});

      const { uploadData, errorMessages: importErrorMessages } =
        useUploadResource();

      const beforeUpload = (file: FileItem) => {
        state.fileList = [...state.fileList, file];
        return false;
      };

      const fileUpload = (id: string) => {
        state.fileList.forEach((file) => {
          const fileExt = file.name.split(".").pop();
          uploadData(id, file, fileExt).then(async (result: boolean) => {
            // 非同期に呼び出したいのでawaitにしないこと
            if (result) {
              useGetManyOrderCommentResult.commentRefetch();
              message.success("アップロードしました。");
            } else {
              const errorMessages = unref(importErrorMessages);
              Object.keys(errorMessages).forEach((key) => {
                message.error(errorMessages[key]);
              });
            }
          });
        });
      };

      onDone((result) => {
        useGetManyOrderCommentResult.commentRefetch();
        assert(result.data != null);
        assert(result.data.createOneOrderComment != null);
        assert(result.data.createOneOrderComment.orderComment != null);
        fileUpload(result.data.createOneOrderComment.orderComment.id);
        state.comment = "";
        state.fileList = [];
      });

      onError(() => {
        state.errorMessages = error.value.message.split(",");
      });

      const handleCreate = async () => {
        assert(state.comment != null);
        state.errorMessages = [];
        await mutate({
          input: {
            comment: state.comment,
            orderId: props.orderId,
          },
        });
      };

      const handleRemoveFile = (file: FileItem) => {
        const index = state.fileList.indexOf(file);
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);
        state.fileList = newFileList;
      };

      return {
        ...toRefs(state),
        addLoading,
        beforeUpload,
        handleCreate,
        handleRemoveFile,
      };
    };

    const visible = ref<boolean>(false);
    const showModal = () => {
      visible.value = true;
    };
    const handleOk = () => {
      visible.value = false;
    };

    return {
      ...useGetManyOrderCommentResult,
      ...useGetOneOrder(),
      ...useCreateComment(),
      visible,
      showModal,
      handleOk,
    };
  },
});
</script>
