<style lang="less" scoped></style>
<template>
  <div>
    <Content
      class="content-head"
      :style="{
        margin: 0,
        padding: '30px 0 30px 30px',
        height: 'auto',
      }"
    >
      <h1 class="content-head-title">スタッフリスト</h1>
      <ProductCategorySelect
        :value="categoryId"
        @change="handleChange"
      ></ProductCategorySelect>
      <UserName class="content-head-username"></UserName>
    </Content>
    <div class="content-layout">
      <Content
        :style="{
          margin: 0,
          padding: '20px',
          height: 'auto',
        }"
      >
        <ClientUserList></ClientUserList>
      </Content>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Content } from "@/generic";
import UserName from "@/shared/components/CurrentUserName.vue";
import ProductCategorySelect from "@/client/components/productCategory/Select.vue";
import ClientUserList from "@/client/components/settings/clientUser/List.vue";

export default defineComponent({
  components: {
    Content,
    ClientUserList,
    UserName,
    ProductCategorySelect,
  },
});
</script>
