
import { defineComponent, unref, reactive, toRefs } from "vue";
import {
  useGetOneOrderQuery,
  useCancelOrderMutation,
  useReorderOrderMutation,
  useCopyOneOrderMutation,
  useUpdateOneOrderMutation,
} from "@/client/modules/api";
import { GetOneOrderQuery, Order } from "@/client/schema";
import { useResult } from "@vue/apollo-composable";
import {
  formatTimestamp,
  formatDate,
  Currency,
  Number,
  parseError,
} from "@/generic";
import { useRouter } from "vue-router";
import { LeftOutlined } from "@ant-design/icons-vue";
import UploadButton from "./UploadButton.vue";
import TypesettingButton from "./TypesettingButton.vue";
import OuterCartonTypesettingButton from "./OuterCartonTypesettingButton.vue";
import InnerCartonParamPreviewButton from "./InnerCartonParamPreviewButton.vue";
import PriceLabelParamPreviewButton from "./PriceLabelParamPreviewButton.vue";
import ImagePreview from "@/shared/components/ImagePreview.vue";
import UploadFileList from "@/shared/components/UploadFileList.vue";

import { message } from "ant-design-vue";
import { getAccessToken } from "@/shared/providors/session";

export type Props = {
  orderId: string;
};

export default defineComponent({
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  components: {
    UploadButton,
    TypesettingButton,
    OuterCartonTypesettingButton,
    InnerCartonParamPreviewButton,
    PriceLabelParamPreviewButton,
    Currency,
    Number,
    LeftOutlined,
    UploadFileList,
    ImagePreview,
  },
  setup(props: Props) {
    const router = useRouter();

    const { result, loading, refetch } = useGetOneOrderQuery(
      {
        id: props.orderId,
      },
      {
        fetchPolicy: "network-only",
      }
    );

    const order = useResult<GetOneOrderQuery, "order">(result);

    const useCancelOrder = () => {
      const { mutate: cancel } = useCancelOrderMutation({});
      const handleCancel = async () => {
        await cancel({
          input: {
            id: props.orderId,
          },
        });
      };
      return {
        handleCancel,
      };
    };

    const useReorderOrder = () => {
      const state = reactive({
        errorMessages: null as string[] | null,
      });

      const { mutate: reorder, error } = useReorderOrderMutation({});

      const handleReorder = async () => {
        try {
          state.errorMessages = null;
          await reorder({
            input: {
              id: props.orderId,
            },
          });

          router.push({
            name: "OrderEdit",
            params: {
              id: props.orderId,
            },
          });
        } catch (e) {
          if (error.value == null) throw e;
          state.errorMessages = parseError(error.value);
        }
      };
      return {
        handleReorder,
      };
    };

    const useCopyOrder = () => {
      const state = reactive({
        visibleCopyModal: false,
        errorMessages: null as string[] | null,
      });

      const handleCopyOrder = () => {
        state.visibleCopyModal = true;
        state.errorMessages = null;
      };

      const { mutate: copyOne, error } = useCopyOneOrderMutation({});

      const handleCopy = async () => {
        try {
          const { data } = await copyOne({
            input: {
              id: props.orderId,
            },
          });
          const newOrderId = data?.copyOneOrder?.order?.id as string;

          state.visibleCopyModal = false;
          router.push({
            name: "OrderEdit",
            params: {
              id: newOrderId,
            },
          });
        } catch (e) {
          if (error.value == null) throw e;
          state.errorMessages = parseError(error.value);
        }
      };

      return {
        ...toRefs(state),
        handleCopyOrder,
        handleCopy,
      };
    };

    const handleChange = async () => {
      await refetch();
    };

    const useUpdateOrder = () => {
      const { mutate: updateOneOrder } = useUpdateOneOrderMutation({});

      const handleUploaded = async () => {
        await updateOneOrder({
          input: {
            id: props.orderId,
            draft: false,
          },
        });
        await refetch();
      };

      return {
        handleUploaded,
      };
    };

    // const adjustedColumnName = computed(() => {
    //   const safeOrder = unref(order);
    //   if (safeOrder == null) return null;

    //   if (safeOrder.orderProduct.price !== safeOrder.orderProduct.adjustedPrice && safeOrder.shippingFee !== safeOrder.adjustedShippingFee) {
    //     return "価格と送料";
    //   } else if (safeOrder.orderProduct.price !== safeOrder.orderProduct.adjustedPrice) {
    //     return "価格";
    //   } else if (safeOrder.shippingFee !== safeOrder.adjustedShippingFee) {
    //     return "送料";
    //   } else {
    //     if (safeOrder.children.some((child) => child.orderProduct.price !== child.orderProduct.adjustedPrice || child.shippingFee !== child.adjustedShippingFee)){
    //       return "子注文の金額"
    //     } else {
    //       return null;
    //     }
    //   };
    // });

    const currencyStyle = (price: number, adjustedPrice: number) => {
      if (adjustedPrice !== price) {
        return "currency-attention";
      }
    };

    const currencyStyleByObject = (order: Order) => {
      if (
        order.orderProduct.price !== order.orderProduct.adjustedPrice ||
        order.shippingFee !== order.adjustedShippingFee
      ) {
        return "currency-attention";
      }
    };

    const handleExportQuotation = async () => {
      try {
        const exportEndpoint = `/api/app/reports/quotation/${props.orderId}.pdf`;
        const accessToken = getAccessToken("client");
        await fetch(exportEndpoint, {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const anchor = document.createElement("a");
            anchor.href = window.URL.createObjectURL(blob);
            const orderSafe = unref(order) as Order;
            const orderName = orderSafe?.name;
            const date = new Date();
            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            const hour = ("0" + date.getHours()).slice(-2);
            const minute = ("0" + date.getMinutes()).slice(-2);
            const dateTime = `${year}${month}${day}_${hour}${minute}`;
            const fileName = `見積書_${orderName}_${dateTime}.pdf`;
            anchor.download = fileName;
            anchor.click();
          });
        message.success("ダウンロードしました。");
      } catch (err) {
        console.log("err", err);
        message.error("エラーが発生し、ダウンロードできませんでした。");
        Object.keys(err).forEach((key) => {
          message.error(err[key]);
        });
      }
    };

    return {
      order,
      loading,
      ...useCancelOrder(),
      ...useReorderOrder(),
      ...useCopyOrder(),
      ...useUpdateOrder(),
      formatTimestamp,
      formatDate,
      handleChange,
      // adjustedColumnName,
      currencyStyle,
      currencyStyleByObject,
      handleExportQuotation,
    };
  },
});
