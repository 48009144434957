<style lang="less" scoped>
.today {
  border: #2169f5 1px solid;
}
.sunday {
  color: #ff0000;
}
.saturday {
  color: #0000ff;
}
.national {
  color: #ff0000;
}
.company {
  color: #ff0000;
}
.other {
  color: #ff0000;
}
</style>
<template>
  <a-range-picker
    class="ant-picker-cell-inner"
    :value="value"
    @change="onChange"
    :allowClear="true"
    format="YYYY.MM.DD(dd)"
  >
    <template #dateRender="{ current }">
      <div :class="getCurrentClassObject(current)">
        {{ current.date() }}
      </div>
    </template>
    <template #suffixIcon>
      <CalendarOutlined />
    </template>
  </a-range-picker>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, SetupContext } from "vue";
import moment from "moment";
import { CalendarOutlined } from "@ant-design/icons-vue";
import { useGetManyHolidayQuery } from "@/admin/modules/api";
import { useResult } from "@vue/apollo-composable";
import { GetManyHolidayQuery, HolidayFragmentFragment } from "@/admin/schema";

export type Props = {
  value: moment.Moment;
};

export default defineComponent({
  components: {
    CalendarOutlined,
  },
  props: {
    value: {
      type: Object as PropType<moment.Moment>,
      required: true,
    },
  },
  setup(props: Props, context: SetupContext) {
    const today = moment();

    const { result } = useGetManyHolidayQuery({});

    const holidays = useResult<GetManyHolidayQuery, "holidays">(result);

    const holidaysMap = computed(() => {
      const _holidaysMap = {} as { [key: string]: HolidayFragmentFragment };
      if (holidays.value == null) {
        return {} as { [key: string]: HolidayFragmentFragment };
      } else {
        holidays.value.forEach((holiday) => {
          const key = moment(holiday.date).format("YYYY-MM-DD");
          _holidaysMap[key] = holiday;
        });
      }
      return _holidaysMap;
    });

    const getCurrentClassObject = (current: moment.Moment) => {
      const dayOfWeek = current.day();
      const classObject = {
        day: true,
        today: current.isSame(today, "day"),
        sunday: dayOfWeek === 0,
        saturday: dayOfWeek === 6,
        "ant-calendar-date": true,
      } as { [key: string]: boolean };

      if (holidaysMap.value != null) {
        const holiday = holidaysMap.value[current.format("YYYY-MM-DD")];
        if (holiday != null) {
          const className = holiday.holidayType as string;
          classObject[className] = true;
        }
      }
      return classObject;
    };

    const onChange = (current: moment.Moment) => {
      context.emit("update:value", current);
      context.emit("change", current);
    };

    return {
      onChange,
      getCurrentClassObject,
    };
  },
});
</script>
