<style lang="less" scoped>
@import "../../styles/variable.less";
.orderconfirm {
  .detail-head {
    display: flex;
    align-items: center;
    & > * {
      flex: 0 1 auto;
    }

    &-title {
      width: 650px;
    }
    &-btn {
      width: calc(100% - 710px);
      display: flex;
      justify-content: right;
    }
  }

  &-info {
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    & > * {
      flex: 1 1 auto;
    }
    .info {
      &-row {
        & > * {
          flex: 0 1 auto;
        }
      }
      &-col {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        border-left: solid 1px @clear-black02;
        &.nogrow {
          flex-grow: 0 !important;
        }
      }
      &-label {
        color: @clear-black06;
        font-size: 12px;
      }
      &-data {
        margin: 0;
        &.bold {
          font-weight: bold;
        }
        &.empty {
          color: @red01;
        }
      }
    }
  }
  &-property {
    /deep/ .ant-card {
      margin-top: 30px;
      padding: 0 20px 20px;
      &-head {
        padding: 0;
        &-title {
          font-size: 14px;
          font-weight: bold;
        }
      }
      &-extra {
        & > * {
          margin-left: 10px;
        }
      }
      &-body {
        padding: 20px 0 0;
      }
    }
    .property {
      &-table {
        width: 100%;
      }
      &-head,
      &-data {
        vertical-align: baseline;
        padding: 16px 0;
      }
      &-head {
        width: 1px;
        padding-right: 30px;
        white-space: nowrap;
        &-data {
          font-weight: normal;
          margin-bottom: 0;
        }
      }
      &-data {
        border-bottom: solid 1px @clear-black02;
        & > * {
          margin-bottom: 10px;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
  &-submits {
    margin-top: 20px;
    padding-top: 20px;
    border-top: solid 1px @clear-black02;
  }
  &-alert {
    max-width: 525px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    /deep/ .ant-alert {
      &-message {
        font-size: 14px;
        font-weight: bold;
      }
      &-description {
        font-size: 12px;
      }
    }
  }
}
.error-message {
  color: red;
}
</style>
<template>
  <div class="orderconfirm" v-if="!loading && order != null">
    <div class="detail-head">
      <a-button class="detail-head-back">
        <router-link :to="{ name: 'OrderEdit', params: { id: order.id } }">
          <LeftOutlined />
        </router-link>
      </a-button>
      <div class="detail-head-title">
        <div v-if="order.parent">
          <router-link
            :to="{ name: 'OrderConfirm', params: { id: order.parent.id } }"
            >{{ order.parent.name }}</router-link
          >
          >
        </div>
        <h2 class="detail-head-name">{{ order.product.name }}</h2>
      </div>
      <div class="detail-head-btn">
        <a-space>
          <router-link :to="{ name: 'OrderEdit', params: { id: order.id } }"
            ><a-button size="large">修正する</a-button></router-link
          >
          <router-link :to="{ name: 'OrderList' }"
            ><a-button size="large">見積もりを保存</a-button></router-link
          >
          <a-button size="large" @click="handleExportQuotation"
            >見積書を発行</a-button
          >
        </a-space>
      </div>
    </div>
    <div class="orderconfirm-info">
      <template v-if="order.product.thumbnailPath">
        <ImagePreview :preview="order.product" />
      </template>
      <template v-else>
        <ImagePreview :fallback="true" />
      </template>
      <a-row type="flex" class="info-row" :gutter="[40, 20]">
        <a-col
          flex="auto"
          class="info-col"
          v-if="order.orderProduct.productType == 'draft'"
        >
          <p class="info-label">データ入稿商品</p>
          <template v-if="!order.uploaded">
            <p class="info-data bold empty">データ入稿がされていません。</p>
          </template>
          <template v-else>
            <a-row :gutter="[10, 10]">
              <a-col v-for="file in order.orderProduct.files" :key="file">
                <a-button size="large"
                  ><PaperClipOutlined />{{ file.fileName }}</a-button
                >
              </a-col>
            </a-row>
          </template>
        </a-col>
        <a-col
          flex="auto"
          class="info-col nogrow"
          v-if="order.orderProduct.productType !== 'normal'"
        >
          <p class="info-data">
            <UploadButton
              v-if="order.orderProduct.productType == 'draft'"
              :order="order"
              @change="handleChange"
            ></UploadButton>
            <TypesettingButton
              v-if="order.orderProduct.productType == 'typesetting_tag'"
              :typesettingParamId="order.orderProduct.typesettingParam.id"
              :previewOnly="true"
              @change="handleChange"
            ></TypesettingButton>
            <OuterCartonTypesettingButton
              v-if="order.orderProduct.productType == 'outer_carton_label'"
              :outerCartonParamId="order.orderProduct.outerCartonParam.id"
              :previewOnly="true"
              @change="handleChange"
            ></OuterCartonTypesettingButton>
            <InnerCartonParamPreviewButton
              v-if="order.orderProduct.productType == 'inner_carton_label'"
              :innerCartonParam="order.orderProduct.innerCartonParam"
            ></InnerCartonParamPreviewButton>
            <PriceLabelParamPreviewButton
              v-if="order.orderProduct.productType == 'price_label'"
              :priceLabelParam="order.orderProduct.priceLabelParam"
            ></PriceLabelParamPreviewButton>
          </p>
        </a-col>
        <a-col flex="auto" class="info-col">
          <p class="info-label">本日注文・承認完了で</p>
          <p class="info-data bold">
            {{
              moment(new Date())
                .add(order.orderProduct.basicDueDate, "d")
                .format("MM月DD日（dd）")
            }}到着
          </p>
        </a-col>
        <a-col flex="auto" class="info-col">
          <p class="info-label">部数</p>
          <p class="info-data bold">
            <Number :value="order.orderProduct.quantity"></Number>部
          </p>
        </a-col>
        <a-col flex="auto" class="info-col">
          <p class="info-label">金額</p>
          <p class="info-data bold">
            <Currency :value="order.orderProduct.price"></Currency>
          </p>
        </a-col>
        <a-col flex="auto" class="info-col">
          <p class="info-label">送料</p>
          <p class="info-data bold">
            <Currency :value="order.shippingFee"></Currency>
          </p>
        </a-col>
        <a-col flex="auto" class="info-col">
          <p class="info-label">小計</p>
          <p class="info-data bold">
            <Currency :value="order.amount"></Currency>
          </p>
        </a-col>
        <a-col flex="auto" class="info-col">
          <p class="info-label">消費税</p>
          <p class="info-data bold">
            <Currency :value="order.tax"></Currency>
          </p>
        </a-col>
        <a-col flex="auto" class="info-col">
          <p class="info-label">合計金額</p>
          <p class="info-data bold">
            <Currency :value="order.totalAmount"></Currency>
          </p>
        </a-col>
      </a-row>
    </div>
    <div class="orderconfirm-property">
      <a-card title="注文内容" bordered>
        <template #extra>
          <!-- <a-button size="large">配送先をマスターに保存</a-button>
          <a-button size="large">連絡先をマスターに保存</a-button> -->
        </template>
        <table class="property-table">
          <tr class="property-row">
            <th class="property-head">製品名設定</th>
            <td class="property-data">{{ order.orderProduct.name }}</td>
          </tr>
          <tr
            class="property-row"
            v-for="delivery in order.orderDeliveries"
            :key="delivery"
          >
            <th class="property-head">
              <p class="property-head-title">注文配送先</p>
              <p class="property-head-data">{{ delivery.quantity }}部</p>
            </th>
            <td class="property-data">
              <p class="property-address">
                {{ delivery.name }}
                {{
                  `${delivery.zipCode.slice(0, 3)}-${delivery.zipCode.slice(
                    3
                  )}` +
                  " " +
                  $t(`enums.prefecture.${delivery.prefecture}`) +
                  " " +
                  " " +
                  delivery.city +
                  " " +
                  delivery.block
                }}{{ delivery.building ? " " + delivery.building : "" }} TEL：{{
                  delivery.tel
                }}
              </p>
              <p class="property-person" v-if="delivery.shipper">
                荷主:
                {{ delivery.shipper.name }}
                {{
                  `${delivery.shipper.zipCode.slice(
                    0,
                    3
                  )}-${delivery.shipper.zipCode.slice(3)}` +
                  " " +
                  $t(`enums.prefecture.${delivery.shipper.prefecture}`) +
                  " " +
                  " " +
                  delivery.shipper.city +
                  " " +
                  delivery.shipper.block
                }}
                {{
                  delivery.shipper.building
                    ? " " + delivery.shipper.building
                    : ""
                }}
                {{
                  delivery.shipper.tel ? " TEL：" + delivery.shipper.tel : ""
                }}
              </p>
              <p class="property-person" v-else>荷主：なし</p>
            </td>
          </tr>
          <tr
            class="property-row"
            v-for="(contact, index) in order.orderContacts"
            :key="contact"
          >
            <th class="property-head">注文連絡先{{ index + 1 }}</th>
            <td class="property-data">
              <p class="property-address">
                {{ contact.name }}
                {{
                  `${contact.zipCode.slice(0, 3)}-${contact.zipCode.slice(3)}` +
                  " " +
                  $t(`enums.prefecture.${contact.prefecture}`) +
                  " " +
                  " " +
                  contact.city +
                  " " +
                  contact.block
                }}{{ contact.building ? " " + contact.building : "" }}
              </p>
              <p class="property-person">
                TEL：{{ contact.tel
                }}{{ contact.fax ? " FAX：" + contact.fax : "" }}
              </p>
            </td>
          </tr>
        </table>
      </a-card>
    </div>

    <div class="orderconfirm-property" v-if="order.children.length">
      <a-card title="注文合計" bordered>
        <table class="property-table">
          <tr class="property-row">
            <th class="property-head">
              <p class="property-head-title">この注文</p>
            </th>
            <td class="property-data">
              <p class="property-person">
                {{ order.orderProduct.name }}
              </p>
            </td>
            <td class="property-data">
              <p class="property-person">
                <Currency :value="order.totalAmount"></Currency>
              </p>
            </td>
          </tr>
          <tr
            class="property-row"
            v-for="childOrder in order.children"
            :key="childOrder.id"
          >
            <th class="property-head">
              <p class="property-head-title">子注文</p>
            </th>
            <td class="property-data">
              <p class="property-person">
                <router-link
                  :to="{ name: 'OrderConfirm', params: { id: childOrder.id } }"
                >
                  {{ childOrder.orderProduct.name }}
                </router-link>
              </p>
            </td>
            <td class="property-data">
              <p class="property-person">
                <Currency :value="childOrder.totalAmount"></Currency>
              </p>
            </td>
          </tr>
          <tr class="property-row">
            <th class="property-head">
              <p class="property-head-title">合計</p>
            </th>
            <td class="property-data"></td>
            <td class="property-data">
              <p class="property-person">
                <Currency :value="order.setAmount"></Currency>
              </p>
            </td>
          </tr>
        </table>
      </a-card>
    </div>

    <ul>
      <li v-for="message in errorMessages" class="error-message" :key="message">
        {{ message }}
      </li>
    </ul>

    <div class="orderconfirm-submits">
      <a-row type="flex" justify="end" :gutter="10">
        <a-col v-if="order.applicationStatus === 'draft'">
          <router-link :to="{ name: 'OrderEdit', params: { id: order.id } }"
            ><a-button size="large">修正する</a-button></router-link
          >
        </a-col>
        <a-col>
          <router-link :to="{ name: 'OrderList' }"
            ><a-button size="large">見積もりを保存</a-button></router-link
          >
        </a-col>
        <a-col>
          <a-button
            size="large"
            type="primary"
            @click="handleOrder"
            :disabled="
              !orderble(order) || (order.children?.length && !orderbleParent())
            "
            v-if="!order.parent"
          >
            購入する
          </a-button>
          <router-link
            v-else
            :to="{ name: 'OrderConfirm', params: { id: order.parent.id } }"
          >
            <a-button
              size="large"
              type="primary"
              :disabled="!orderble(order)"
            >
              親注文に戻って注文
            </a-button>
          </router-link>
        </a-col>
      </a-row>
    </div>

    <template v-if="alertVisible">
      <a-alert
        class="orderconfirm-alert"
        v-if="
          !orderble(order) ||
          (order.children?.length && !orderbleParent())
        "
        type="error"
        show-icon
        closable
        :after-close="handleClose"
      >
        <template #message> 注文できません </template>
        <template #description> {{ alertDescription }} </template>
      </a-alert>
      <a-alert
        class="orderconfirm-alert"
        v-else-if="
          order.orderProduct.productType == 'draft' &&
          !order.uploaded
        "
        type="warning"
        show-icon
        closable
        :after-close="handleClose"
      >
        <template #message> 必ずご確認ください </template>
        <template #description>
          このご注文は、データ入稿商品のため、入稿データアップロードが確認できない場合は、発注完了となりません。
        </template>
      </a-alert>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, toRefs, unref } from "vue";
import { parseError, Currency, Number } from "@/generic";
// import AddressDisplay from "@/shared/components/AddressDisplay.vue";
import {
  useGetOneOrderQuery,
  useUpdateOneOrderMutation,
} from "@/client/modules/api";
import { useRouter } from "vue-router";
import { useResult } from "@vue/apollo-composable";
import { GetOneOrderQuery, Order } from "@/client/schema";
import { LeftOutlined } from "@ant-design/icons-vue";
import UploadButton from "./UploadButton.vue";
import TypesettingButton from "./TypesettingButton.vue";
import OuterCartonTypesettingButton from "./OuterCartonTypesettingButton.vue";
import InnerCartonParamPreviewButton from "./InnerCartonParamPreviewButton.vue";
import PriceLabelParamPreviewButton from "./PriceLabelParamPreviewButton.vue";
import ImagePreview from "@/shared/components/ImagePreview.vue";
import moment from "moment";
import "moment/locale/ja";
moment.locale("ja");

import { message } from "ant-design-vue";
import { getAccessToken } from "@/shared/providors/session";

export type Props = {
  orderId: string;
};

export default defineComponent({
  components: {
    Currency,
    Number,
    // AddressDisplay,
    LeftOutlined,
    TypesettingButton,
    OuterCartonTypesettingButton,
    InnerCartonParamPreviewButton,
    PriceLabelParamPreviewButton,
    UploadButton,
    ImagePreview,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const router = useRouter();

    const { loading, result, refetch } = useGetOneOrderQuery(
      {
        id: props.orderId,
      },
      {
        fetchPolicy: "network-only",
      }
    );

    const state = reactive({
      errorMessages: [] as string[],
      alertDescription: "" as string,
    });

    const order = useResult<GetOneOrderQuery, "order">(result);

    const { mutate: updateOneOrder, error } = useUpdateOneOrderMutation({});

    const handleOrder = async () => {
      try {
        const orderSafe = unref(order) as Order;
        await updateOneOrder({
          input: {
            id: orderSafe.id,
            draft: false,
          },
        });

        router.push({
          name: "OrderDetail",
          params: {
            id: orderSafe.id,
          },
        });
      } catch (e) {
        if (error.value == null) throw e;
        state.errorMessages = parseError(error.value);
      }
    };

    const handleChange = async () => {
      await refetch();
    };

    const alertVisible = ref(true);
    const handleClose = () => {
      alertVisible.value = false;
    };

    const orderble = (order: Order) => {
      if (order.orderDeliveries?.reduce((sum, orderDelivery) => sum + orderDelivery.quantity, 0) != order.orderProduct.quantity) {
        state.alertDescription = "配送先の数量の合計と商品数量が異なっています。正しい数量を設定するまで、発注することができません。";
        return false;
      }

      switch (order.orderProduct.productType) {
        case "typesetting_tag":
        case "outer_carton_label":
          if (!order.uploaded) {
            state.alertDescription = "このご注文は、組版商品のため、組版を実行しPDFを作成するまで、発注することができません。";
            return false;
          } else return true;
        case "inner_carton_label": {
          const innerCartonParam = order.orderProduct.innerCartonParam;
          if (
            innerCartonParam == null ||
            innerCartonParam.janCode == null ||
            innerCartonParam.productName == null ||
            innerCartonParam.quantityPerInnerCartonText == null ||
            innerCartonParam.taxIncludedPrice == null
          ) {
            state.alertDescription = "このご注文は、情報入力商品のため、ラベル情報をすべて入力するまで、発注することができません。";
            return false;
          } else return true;
        }
        case "price_label": {
          const priceLabelParam = order.orderProduct.priceLabelParam;
          if (
            priceLabelParam == null ||
            priceLabelParam.janCode == null ||
            priceLabelParam.taxIncludedPrice == null
          ) {
            state.alertDescription = "このご注文は、情報入力商品のため、ラベル情報をすべて入力するまで、発注することができません。";
            return false;
          } else return true;
        }
        default:
          return true;
      }
    };

    const orderbleParent = () => {
      const orderSafe = unref(order) as Order;
      if (!orderSafe.children.every(orderble)) {
        state.alertDescription = "このご注文は、子注文が発注できない状態のため、発注することができません。";
        return false;
      } else return true;
    };

    const handleExportQuotation = async () => {
      try {
        const exportEndpoint = `/api/app/reports/quotation/${props.orderId}.pdf`;
        const accessToken = getAccessToken("client");
        await fetch(exportEndpoint, {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const anchor = document.createElement("a");
            anchor.href = window.URL.createObjectURL(blob);
            const orderSafe = unref(order) as Order;
            const orderName = orderSafe?.name;
            const date = new Date();
            const year = date.getFullYear();
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            const hour = ("0" + date.getHours()).slice(-2);
            const minute = ("0" + date.getMinutes()).slice(-2);
            const dateTime = `${year}${month}${day}_${hour}${minute}`;
            const fileName = `見積書_${orderName}_${dateTime}.pdf`;
            anchor.download = fileName;
            anchor.click();
          });
        message.success("ダウンロードしました。");
      } catch (err) {
        console.log("err", err);
        message.error("エラーが発生し、ダウンロードできませんでした。");
        Object.keys(err).forEach((key) => {
          message.error(err[key]);
        });
      }
    };

    return {
      ...toRefs(state),
      order,
      loading,
      handleOrder,
      handleChange,
      moment,
      alertVisible,
      handleClose,
      orderble,
      orderbleParent,
      handleExportQuotation,
    };
  },
});
</script>
