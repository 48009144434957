<style lang="less" scoped>
@import "../../styles/variable.less";
.order-param-preview-modal {
  .content {
    .main-content {
      padding: 10px 10px 0 10px;
    }
  }
}
</style>
<template>
  <div v-if="priceLabelParam">
    <a-button
      size="large"
      type="primary"
      v-if="priceLabelParam.janCode"
      @click="handleVisibleParamPreview"
    >
      ラベル情報確認
    </a-button>

    <a-modal
      title="ラベル情報確認"
      v-model:visible="visibleParamPreview"
      class="order-param-preview-modal"
    >
      <template #footer>
        <a-button size="large" key="back" @click="handleClose">閉じる</a-button>
      </template>
      <div class="content">
        <a-form
          :model="priceLabelParam"
          layout="horizontal"
          :labelCol="{ span: 8 }"
        >
          <div class="main-content">
            <a-form-item :label="$t('attributes.jan_code')">
              {{ priceLabelParam.janCode }}
            </a-form-item>
            <a-form-item :label="$t('attributes.tax_included_price')">
              <Currency :value="priceLabelParam.taxIncludedPrice" />
            </a-form-item>
          </div>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, PropType } from "vue";
import { PriceLabelParam } from "@/client/schema";
import { Currency } from "@/generic/components/display";

export type Props = {
  priceLabelParam: PriceLabelParam;
};

export default defineComponent({
  components: {
    Currency,
  },
  props: {
    priceLabelParam: {
      type: Object as PropType<PriceLabelParam>,
      required: true,
    },
  },
  setup() {
    const state = reactive({
      visibleParamPreview: false,
    });

    const handleVisibleParamPreview = () => {
      state.visibleParamPreview = true;
    };

    const handleClose = () => {
      state.visibleParamPreview = false;
    };

    return {
      ...toRefs(state),
      handleVisibleParamPreview,
      handleClose,
    };
  },
});
</script>
