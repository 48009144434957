import { RouteRecordRaw } from "vue-router";
import SessionCreate from "@/client/views/session/Create.vue";
import SessionUpdate from "@/client/views/session/Update.vue";
import SessionRemove from "@/client/views/session/Remove.vue";
import PasswordCreate from "@/client/views/password/Create.vue";
import PasswordUpdate from "@/client/views/password/Update.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/init",
    name: "SessionUpdate",
    component: SessionUpdate,
    meta: { noAuth: true },
  },
  {
    path: "/login",
    name: "SessionCreate",
    component: SessionCreate,
    meta: { noAuth: true },
  },
  {
    path: "/logout",
    name: "SessionRemove",
    component: SessionRemove,
    meta: { noAuth: true },
  },
  {
    path: "/password/new",
    name: "PasswordCreate",
    component: PasswordCreate,
    meta: { noAuth: true },
  },
  {
    path: "/password/update",
    name: "PasswordUpdate",
    component: PasswordUpdate,
    meta: { noAuth: true },
  },
];

export default routes;
