
import { defineComponent } from "vue";
import { Content } from "@/generic";
import NotificationList from "@/client/components/notification/List.vue";

export default defineComponent({
  components: {
    Content,
    NotificationList,
  },
});
