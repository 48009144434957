
import { defineComponent, unref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGetSession } from "@/client/modules/session";
import { assert } from "@/generic";
import { ClientUserGroup } from "@/client/schema";

export default defineComponent({
  props: {
    clientUserGroupId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const { currentUser } = useGetSession();

    const currentUserGroups = computed(
      () => unref(currentUser)?.rootUserGroups || ([] as ClientUserGroup[])
    );

    const treeData = computed(() => {
      return unref(currentUserGroups)?.map(
        (clientUserGroup: ClientUserGroup) => {
          return {
            title: clientUserGroup?.name || "",
            value: clientUserGroup.id,
            key: clientUserGroup.id,
            children: clientUserGroup.children?.map((childrenUserGroup) => ({
              title: childrenUserGroup.name,
              value: childrenUserGroup.id,
              key: childrenUserGroup.id,
            })),
          };
        }
      );
    });

    const handleChange = (clientUserGroupId: string) => {
      const name = unref(route.name);
      const params = unref(route.params);
      assert(name != null);
      router.push({
        name,
        params: {
          ...params,
          id: clientUserGroupId,
        },
      });
    };

    return {
      currentUserGroups,
      handleChange,
      treeData,
    };
  },
});
