
import { defineComponent } from "vue";
import { Content } from "@/generic";
import ApprovalList from "@/client/components/approval/List.vue";
import UserName from "@/shared/components/CurrentUserName.vue";

export default defineComponent({
  components: {
    Content,
    ApprovalList,
    UserName,
  },
});
