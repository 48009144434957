
import { computed, defineComponent, reactive, ref, toRefs, unref } from "vue";
import { Content } from "@/generic";
import ProductList from "@/client/components/product/List.vue";
import UserName from "@/shared/components/CurrentUserName.vue";
import ProductCategorySelect from "@/client/components/productCategory/Select.vue";
import { useGetSession } from "@/client/modules/session";
import { useRouter } from "vue-router";
// import SearchTool from "@/client/components/product/SearchTool.vue";
// import { SearchOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    Content,
    ProductList,
    UserName,
    ProductCategorySelect,
    // SearchTool,
    // SearchOutlined,
  },
  props: {
    categoryId: {
      type: String,
      required: true,
    },
    searchTool: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();
    const { currentUser } = useGetSession();

    const currentUserGroups = computed(
      () => unref(currentUser)?.userGroups || []
    );
    const defaultUserGroupId = computed(
      () => unref(currentUser)?.userGroups?.[0]?.id || null
    );
    const currentUserGroupId = ref(undefined as string | null | undefined);
    const handleChange = (categoryId: string) => {
      if (categoryId != null) {
        router.push({
          name: "ProductListByCategory",
          params: { categoryId: categoryId },
        });
      } else {
        router.push({
          name: "ProductList",
        });
      }
    };

    const state = reactive({
      searchTool: false,
    });
    const toggleSearch = () => {
      state.searchTool = !state.searchTool;
    };
    const selectedTags = ref<string[]>([]);
    const searchTagClear = () => {
      selectedTags.value = [];
      if (state.searchTool) {
        toggleSearch();
      }
    };

    return {
      ...toRefs(state),
      currentUserGroups,
      currentUserGroupId,
      defaultUserGroupId,
      handleChange,
      toggleSearch,
      selectedTags,
      searchTagClear,
    };
  },
});
