<template>
  <span role="img" class="anticon"
    ><svg
      width="1em"
      height=".8em"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9333 8.88889V1.11111H1.06667V8.88889H14.9333ZM16 1.11111V8.88889C16 9.50245 15.5225 10 14.9333 10H1.06667C0.477563 10 0 9.50245 0 8.88889V1.11111C0 0.497464 0.477563 0 1.06667 0H14.9333C15.5225 0 16 0.497464 16 1.11111ZM2.13333 2.49992C2.13333 2.34651 2.25273 2.22215 2.4 2.22215H6.13333C6.28061 2.22215 6.4 2.34651 6.4 2.49992V7.4999C6.4 7.65331 6.28061 7.77768 6.13333 7.77768H2.4C2.25273 7.77768 2.13333 7.65331 2.13333 7.4999V2.49992ZM8 4.44436C7.70545 4.44436 7.46667 4.69309 7.46667 4.99991C7.46667 5.30673 7.70545 5.55546 8 5.55546H11.2C11.4945 5.55546 11.7333 5.30673 11.7333 4.99991C11.7333 4.69309 11.4945 4.44436 11.2 4.44436H8ZM7.46667 7.22212C7.46667 6.9153 7.70545 6.66657 8 6.66657H13.3333C13.6278 6.66657 13.8667 6.9153 13.8667 7.22212C13.8667 7.52894 13.6278 7.77768 13.3333 7.77768H8C7.70545 7.77768 7.46667 7.52894 7.46667 7.22212ZM8 2.22215C7.70545 2.22215 7.46667 2.47088 7.46667 2.7777C7.46667 3.08452 7.70545 3.33325 8 3.33325H12.2667C12.5612 3.33325 12.8 3.08452 12.8 2.7777C12.8 2.47088 12.5612 2.22215 12.2667 2.22215H8Z"
        fill="currentColor"
      /></svg
  ></span>
</template>
