<style lang="less" scoped>
@import "../../client/styles/variable.less";
.current-username {
  color: @clear-black01;
  margin: 0;
}
</style>
<template>
  <p class="current-username" v-if="currentUser">
    {{ currentUser.name }}さん お疲れ様です。
  </p>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useGetSession } from "@/client/modules/session";

export default defineComponent({
  setup() {
    const { currentUser } = useGetSession();
    return {
      currentUser,
    };
  },
});
</script>
