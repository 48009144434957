
import { defineComponent, reactive, toRefs, PropType, SetupContext } from "vue";

import { parseError } from "@/generic";
import {
  useUpdateOneOrderContactMutation,
  useRemoveOneOrderContactMutation,
} from "@/client/modules/api";
import { userSelectClientContacts } from "./module";
import { Order, OrderContact, ClientContact } from "@/client/schema";
import AddressForm from "@/shared/components/AddressForm.vue";

export type Props = {
  order: Order;
  orderContact: OrderContact;
  deletable: boolean;
};

export default defineComponent({
  components: {
    AddressForm,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
    orderContact: {
      type: Object as PropType<OrderContact>,
      required: true,
    },
    deletable: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["change"],
  setup(props: Props, context: SetupContext) {
    const state = reactive({
      errorMessages: [] as string[],
    });

    const { mutate: update, error: updateError } =
      useUpdateOneOrderContactMutation({});
    const { mutate: remove, error: removeError } =
      useRemoveOneOrderContactMutation({});

    const handleUpdate = async (orderContact: any) => {
      try {
        state.errorMessages = [];
        await update({
          input: {
            id: props.orderContact.id,
            name: orderContact.name,
            zipCode: orderContact.zipCode,
            prefecture: orderContact.prefecture,
            city: orderContact.city,
            block: orderContact.block,
            building: orderContact.building,
            tel: orderContact.tel,
            fax: orderContact.fax,
          },
        });
        context.emit("change");
      } catch (e) {
        if (updateError.value == null) throw e;
        state.errorMessages = parseError(updateError.value);
      }
    };

    const handleRemove = async () => {
      try {
        state.errorMessages = [];
        await remove({
          input: {
            id: props.orderContact.id,
          },
        });

        context.emit("change");
      } catch (e) {
        if (removeError.value == null) throw e;
        state.errorMessages = parseError(removeError.value);
      }
    };

    const { clientContactOptions, handleSelectContact } =
      userSelectClientContacts(props.order.clientUserGroup.id);
    const handleSelect = async (
      orderContact: OrderContact,
      clientContact: ClientContact
    ) => {
      await handleSelectContact(orderContact, clientContact);
      handleUpdate(orderContact);
    };

    return {
      ...toRefs(state),
      clientContactOptions,
      handleSelect,
      handleUpdate,
      handleRemove,
    };
  },
});
