
import { defineComponent, reactive, toRefs, unref, ref } from "vue";
import { useResult } from "@vue/apollo-composable";
import { message } from "ant-design-vue";
import { useUploadResource } from "@/client/components/orderComment/modules/uploadResource";
import {
  useGetOneOrderQuery,
  useCreateOneOrderCommentMutation,
  useGetManyOrderCommentQuery,
} from "@/client/modules/api";
import CommentDetail from "@/client/components/orderComment/Detail.vue";
import { assert } from "@/generic";
import { PaperClipOutlined } from "@ant-design/icons-vue";
import SubmitIcon from "@/shared/components/svg-icon/Submit.vue";

export type Props = {
  orderId: string;
};

interface FileItem {
  uid: string;
  name: string;
  status?: string;
  response?: string;
  url?: string;
  thumbUrl?: string;
  preview?: string;
  originFileObj?: any;
  file: string | Blob;
}

export default defineComponent({
  components: {
    CommentDetail,
    PaperClipOutlined,
    SubmitIcon,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  setup(props: Props) {
    const useGetOneOrder = () => {
      const { result, loading } = useGetOneOrderQuery({
        id: props.orderId,
      });

      const order = useResult(result);

      return {
        order,
        loading,
      };
    };

    const useGetManyOrderComment = () => {
      const {
        result: commentResult,
        loading: commentLoading,
        refetch: commentRefetch,
      } = useGetManyOrderCommentQuery({
        orderId: props.orderId,
      });
      const comments = useResult(commentResult);
      return {
        comments,
        commentLoading,
        commentRefetch,
      };
    };
    const useGetManyOrderCommentResult = useGetManyOrderComment();

    const useCreateComment = () => {
      const state = reactive({
        fileList: [] as FileItem[],
        comment: null as string | null,
        errorMessages: [] as string[],
      });

      const {
        mutate,
        loading: addLoading,
        error,
        onError,
        onDone,
      } = useCreateOneOrderCommentMutation({});

      const { uploadData, errorMessages: importErrorMessages } =
        useUploadResource();

      const beforeUpload = (file: FileItem) => {
        state.fileList = [...state.fileList, file];
        return false;
      };

      const fileUpload = (id: string) => {
        state.fileList.forEach((file) => {
          const fileExt = file.name.split(".").pop();
          uploadData(id, file, fileExt).then(async (result: boolean) => {
            // 非同期に呼び出したいのでawaitにしないこと
            if (result) {
              useGetManyOrderCommentResult.commentRefetch();
              message.success("アップロードしました。");
            } else {
              const errorMessages = unref(importErrorMessages);
              Object.keys(errorMessages).forEach((key) => {
                message.error(errorMessages[key]);
              });
            }
          });
        });
      };

      onDone((result) => {
        useGetManyOrderCommentResult.commentRefetch();
        assert(result.data != null);
        assert(result.data.createOneOrderComment != null);
        assert(result.data.createOneOrderComment.orderComment != null);
        fileUpload(result.data.createOneOrderComment.orderComment.id);
        state.comment = "";
        state.fileList = [];
      });

      onError(() => {
        state.errorMessages = error.value.message.split(",");
      });

      const handleCreate = async () => {
        assert(state.comment != null);
        state.errorMessages = [];
        await mutate({
          input: {
            comment: state.comment,
            orderId: props.orderId,
          },
        });
      };

      const handleRemoveFile = (file: FileItem) => {
        const index = state.fileList.indexOf(file);
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);
        state.fileList = newFileList;
      };

      return {
        ...toRefs(state),
        addLoading,
        beforeUpload,
        handleCreate,
        handleRemoveFile,
      };
    };

    const visible = ref<boolean>(false);
    const showModal = () => {
      visible.value = true;
    };
    const handleOk = () => {
      visible.value = false;
    };

    return {
      ...useGetManyOrderCommentResult,
      ...useGetOneOrder(),
      ...useCreateComment(),
      visible,
      showModal,
      handleOk,
    };
  },
});
