<template>
  <div>
    <Content>
      <a-breadcrumb>
        <a-breadcrumb-item>
          <router-link to="/notifications">お知らせ一覧</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>お知らせ</a-breadcrumb-item>
      </a-breadcrumb>
    </Content>
    <Content>
      <NotificationDatail :notificationId="notificationId"></NotificationDatail>
    </Content>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Content } from "@/generic";
import { useRoute } from "vue-router";
import NotificationDatail from "@/client/components/notification/Detail.vue";

export default defineComponent({
  components: {
    Content,
    NotificationDatail,
  },
  setup() {
    const route = useRoute();
    const notificationId = route.params["id"];

    return {
      notificationId,
    };
  },
});
</script>
