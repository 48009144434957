<style lang="less" scoped>
@import "../../styles/variable.less";
.delivery-input {
  margin: 10px 0;

  /deep/ .ant-card {
    padding: 0 20px 20px;
    margin-bottom: 10px;
    &-head {
      padding: 0;
      &-title {
        font-size: 14px;
        font-weight: bold;
        padding: 0;
      }
    }
    &-body {
      padding: 20px 0;
    }
    .btn-blue {
      color: @blue01;
      border-color: @blue01;
      &:hover {
        color: @white01;
        background: @blue01;
      }
    }
  }
  /deep/ .ant-form {
    .ant-form {
      &-item {
        margin-bottom: 0;
        &-label {
          font-size: 12px;
          color: @clear-black06;
        }
      }
    }
  }
  .address-addbtn {
    color: @blue01;
    width: 100%;
    height: auto;
    border: dashed 1px @blue01;
    padding: 14px;
    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
<template>
  <div class="delivery-input">
    <template
      v-for="(delivery, index) in order.orderDeliveries"
      :key="delivery.id"
    >
      <EditDelivery
        :order="order"
        :orderDelivery="delivery"
        :deletable="index > 0"
        @change="handleChange"
      ></EditDelivery>
    </template>
    <a-button
      size="large"
      class="address-addbtn"
      @click="handleCreate"
      v-if="!order.parent"
      >配送先を追加</a-button
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, PropType, SetupContext } from "vue";
import EditDelivery from "./EditDelivery.vue";
import { Order } from "@/client/schema";
import { useCreateOneOrderDeliveryMutation } from "@/client/modules/api";
import { parseError } from "@/generic/core";
import { userSelectClientDelivery } from "./module";

export type Props = {
  order: Order;
};

export default defineComponent({
  components: {
    EditDelivery,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  emits: ["change"],
  setup(props: Props, context: SetupContext) {
    const { mutate: create, error } = useCreateOneOrderDeliveryMutation({});

    const state = reactive({
      errorMessages: [] as string[],
      creating: false,
    });

    const handleCreate = async () => {
      if (state.creating) return;
      try {
        state.creating = true;
        await create({
          input: {
            orderId: props.order.id,
          },
        });
        context.emit("change");
      } catch (e) {
        if (error.value == null) throw e;
        state.errorMessages = parseError(error);
      } finally {
        state.creating = false;
      }
    };

    const handleChange = async () => {
      context.emit("change");
    };

    return {
      ...toRefs(state),
      ...userSelectClientDelivery(props.order.clientUserGroup.id),
      handleCreate,
      handleChange,
    };
  },
});
</script>
